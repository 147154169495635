import { Learner } from "./types";

export const PROGRESS_MINIMUM = 0.7;
export const MOBILE_NAV_BAR_HEIGHT = 61;
export const MIN_POINTS_TO_UNLOCK = 31;
export const MIN_ROWS_TO_UNLOCK = 5;
export const WEEKLY_GOAL_POINTS = 50;
export const LOCKED_PRIZE_TOOLTIP = "You have not yet unlocked this prize";
export const ACTIVE_LEARNER_STALE_TIME = 1000 * 60 * 30; // 30 minutes

// Timer localStorage keys
export const TIMER_LOCALSTORAGE_KEY_PREFIX = "dm_t";
export const TIMER_LOCALSTORAGE_KEY_VERSION = 2;
export const TIMER_LOCALSTORAGE_ROOT_KEY = `${TIMER_LOCALSTORAGE_KEY_PREFIX}:v${TIMER_LOCALSTORAGE_KEY_VERSION}`;
export const TIMER_LOCALSTORAGE_APP_KEY = "app:total";
export const TIMER_LOCALSTORAGE_BASE_COURSE_KEY = "app:course";

export const TMP_HOMEPAGE_PATH = "d9ed1a33";
export const TMP_HOMEPAGE_DISCOUNT_PATH = "f7721343";
export const TMP_HOMEPAGE_ALLOW_PROMO_CODE_PATH = "9105b4b1";

export const MOCK_LEARNER: Learner = {
  _id: "abc123",
  createdAt: new Date().toISOString(),
  last_login: new Date(),
  pointsEarned: 0,
  pointsThisWeek: 0,
  email: "",
  first: "",
  last: "",
  level: "easy",
  penalty: false,
  login_count: 0,
  solved: 0,
  solved_correct: 0,
  pointsUsed: 0,
  pascalsRow: undefined,
  streaks: 0,
  account_type: "learner",
  entitlements: ["learner"],
  longest_streak: 0,
  total_app_time: 0,
  timezone: "America/New_York",
  viewedOnboarding: true,
  onboardingCompleted: [],
  viewedTestHint: true,
  subscriptionExpiration: new Date(
    Date.now() + 1000 * 60 * 60 * 24 * 365
  ).toISOString(),
  enableSoftLaunch: true,
};
