import clsx from "clsx";
import { debounce } from "lodash";
import { ChangeEventHandler } from "react";

type Props = {
  label: string;
  value: string;
  error: string | undefined | React.ReactElement;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: "text" | "email";
};

const DebounceTextField = (props: Props) => {
  const id = props.label.toLowerCase().replace(" ", "-");

  const debounceOnChange = debounce((e) => {
    props.onChange(e);
  }, 500);

  return (
    <div className="flex flex-col gap-1">
      <label
        className={clsx(
          "text-sm font-bold leading-6",
          props.error ? "text-dm-error-500" : "text-dm-charcoal-600"
        )}
        htmlFor={id}
      >
        {props.label}
      </label>
      <input
        className={clsx(
          "rounded-md",
          props.error ? "border-dm-error-500" : "border-dm-gray-200"
        )}
        id={id}
        type={props.type || "text"}
        defaultValue={props.value}
        onChange={debounceOnChange}
        required
      />
      {props.error && (
        <p className="text-xs text-dm-error-500" role="alert">
          {props.error}
        </p>
      )}
    </div>
  );
};

export default DebounceTextField;
