import clsx from "clsx";
import { MAX_CONTAINER_WIDTH } from "../constants";
import { SectionTitle } from "../SectionTitle";
import { useEffect, useRef, useState } from "react";
import {
  ASSETS,
  DEFAULT_OPTIONS,
  DeltieAsset,
  DeltieAssetOffset,
  DeltieOptions,
  loadAsset,
} from "./utils";
import { DeltieSidebar } from "./DeltieSidebar";
import { useMediaQuery } from "usehooks-ts";
import { getFilePath } from "../../../../utils";

const CANVAS_WIDTH = 863;
const CANVAS_HEIGHT = 653;

function accessoriesSortFn(
  a: keyof typeof ASSETS.accessories,
  b: keyof typeof ASSETS.accessories
): number {
  // Render order. Items later in the list will render on top of earlier items
  const order: DeltieAsset["accessoryCategory"][] = [
    "shoes",
    "pants",
    "accessories",
    "hats",
  ];

  const assetA = ASSETS.accessories[a];
  const assetB = ASSETS.accessories[b];
  const categoryA = assetA.accessoryCategory;
  const categoryB = assetB.accessoryCategory;

  if (!categoryA || !categoryB) {
    throw new Error(`Accessory '${!categoryA ? a : b}' must have a category`);
  }

  // Some accessories have an `order` property that should bump them ahead of
  // other accessories (e.g. the coffee cup should render above the sling bag,
  // but the space helmet should be on top of the coffee cup)
  return (
    order.indexOf(categoryA) +
    (assetA.accessoryOrder ?? 0) -
    (order.indexOf(categoryB) + (assetB.accessoryOrder ?? 0))
  );
}

/** Draw the image on the canvas at 0.5x with an optional offset */
export function drawImage(
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  [offsetX, offsetY]: DeltieAssetOffset = [0, 0],
  scaleFactor: number
) {
  // Images are 2x resolution, so we need to scale them down by half, plus any
  // additional scaling
  const sizeScale = 0.5 * scaleFactor;
  const scaledWidth = img.width * sizeScale;
  const scaledHeight = img.height * sizeScale;

  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.height,
    // Offsets are defined at 1x, so just use the scale factor here
    (ctx.canvas.width - scaledWidth) / 2 + offsetX * scaleFactor,
    (ctx.canvas.height - scaledHeight) / 2 + offsetY * scaleFactor,
    scaledWidth,
    scaledHeight
  );
}

export const DeltieGenerator: React.FC = () => {
  const canvas = useRef<HTMLCanvasElement>(null);

  const [options, setOptions] = useState<DeltieOptions>(DEFAULT_OPTIONS);
  const [isLoading, setIsLoading] = useState(false);

  const isMobileScreen = useMediaQuery("(max-width: 640px)");
  const scaleFactor = isMobileScreen ? 0.6 : 1;
  const canvasWidth = CANVAS_WIDTH * scaleFactor;
  const canvasHeight = CANVAS_HEIGHT * scaleFactor;

  useEffect(() => {
    const ctx = canvas.current?.getContext("2d");
    if (!ctx) {
      return;
    }

    setIsLoading(true);

    const scene = ASSETS.scenes[options.scene];
    const expression = ASSETS.expressions[options.expression];

    Promise.all([
      loadAsset(getFilePath("/images/learner/deltie/utils/body.png")),
      loadAsset(getFilePath("/images/learner/deltie/utils/arm-left.png")),
      loadAsset(getFilePath("/images/learner/deltie/utils/arm-right.png")),
      loadAsset(getFilePath("/images/learner/deltie/utils/legs.png")),
      loadAsset(scene.src),
      loadAsset(expression.src),
      ...options.accessories
        .sort(accessoriesSortFn)
        .map((a) => loadAsset(ASSETS.accessories[a].src)),
    ]).then(
      ([
        bodyImg,
        armLeftImg,
        armRightImg,
        legsImg,
        sceneImg,
        expressionImg,
        ...accessoryImgs
      ]) => {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        setIsLoading(false);

        // Scene
        ctx.drawImage(sceneImg, 0, 0, canvasWidth, canvasHeight);

        // Body, arms, legs
        drawImage(ctx, armLeftImg, [126, 70], scaleFactor);
        if (
          !Object.values(options.accessories).some(
            (a) => ASSETS.accessories[a].replaces === "arm-right"
          )
        ) {
          drawImage(ctx, armRightImg, [-125, 45], scaleFactor);
        }
        drawImage(ctx, legsImg, [-10, 130], scaleFactor);
        drawImage(ctx, bodyImg, [0, -20], scaleFactor);

        // Expression
        drawImage(ctx, expressionImg, expression.offset, scaleFactor);

        // Accessories
        accessoryImgs.forEach((img) => {
          const accessory = Object.values(ASSETS.accessories).find((a) =>
            img.src.endsWith(a.src)
          );
          drawImage(ctx, img, accessory?.offset, scaleFactor);
        });
      }
    );
  }, [canvasHeight, canvasWidth, options, scaleFactor]);

  return (
    <section
      className={clsx(
        "relative flex w-full flex-col items-center px-4 md:px-6 xl:px-0",
        MAX_CONTAINER_WIDTH
      )}
    >
      <SectionTitle
        title="Deltie, Your Personal Math Sidekick"
        subtitle="Build and customize Deltie to make math more fun"
      />

      <div className="flex w-full max-w-[863px] flex-col overflow-hidden rounded-lg border-2 border-dm-brand-blue-500 xl:max-w-full xl:flex-row">
        <figure className="relative flex justify-center">
          <canvas
            ref={canvas}
            className={clsx(
              "opacity-100 transition-opacity",
              isLoading && "opacity-50"
            )}
            width={canvasWidth}
            height={canvasHeight}
          />
        </figure>

        {canvas.current && (
          <DeltieSidebar
            options={options}
            setOptions={setOptions}
            canvas={canvas.current}
          />
        )}
      </div>
    </section>
  );
};
