import { useEffect, useState } from "react";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";
import Cookies from "js-cookie";

const BackToDeltamathBanner = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    const bannerDismissed = Cookies.get("DMHomeBannerDismissed");
    if (!bannerDismissed) {
      setShowBanner(true);
    }
  }, []);

  const onClick = () => {
    Cookies.set("DMHomeBannerDismissed", "true", { expires: 1000 });
    setShowBanner(false);
  };

  if (!showBanner) {
    return <></>;
  }

  return (
    <div className="flex min-h-10 w-full items-center justify-between gap-4 bg-dm-brand-blue-800 px-4 py-2 text-white md:px-14 md:py-0">
      <div className="flex gap-2">
        <ResponsiveImage
          srcs={[getFilePath("/images/deltamath-logo.svg")]}
          alt="DeltaMath"
        />
        <p className="text-white">
          Looking for DeltaMath for Teachers or Students?{" "}
          <a href="/" className="underline">
            Click here.
          </a>
        </p>
      </div>
      <button
        className="far fa-times cursor-pointer text-2xl !text-white sm:text-2xl"
        onClick={onClick}
      />
    </div>
  );
};

export default BackToDeltamathBanner;
