import clsx from "clsx";
import { getFilePath, REACT_APP_HOMEPAGE_LINK } from "../../../utils";
import { MAX_CONTAINER_WIDTH } from "./constants";

export const Footer: React.FC = () => {
  const links = [
    ["Terms & Policies", `${REACT_APP_HOMEPAGE_LINK}/terms-policies`],
  ];
  return (
    <footer
      className={clsx(
        "mb-16 flex w-full px-4 md:mb-32 md:px-6 xl:px-0",
        MAX_CONTAINER_WIDTH
      )}
    >
      <div className="flex w-full flex-col items-center justify-between gap-4 border-t border-[#D2D5DA] pt-6 text-xs md:flex-row md:gap-0 md:pl-2 lg:pr-28">
        <img
          src={getFilePath("/images/DeltaMath-Logo_Home.svg")}
          alt="DeltaMath for Home"
        />
        <ul className="flex gap-4 md:gap-6">
          {links.map(([text, href]) => (
            <li key={text}>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-dm-charcoal-800 underline-offset-4 hover:underline"
                href={href}
              >
                {text}
              </a>
            </li>
          ))}
        </ul>
        <span className="text-dm-gray-200">
          &copy; {new Date().getFullYear()} DeltaMath. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};
