import { ContinueLearningCard } from "./ContinueLearningCard";
import { TotalPointsCard } from "./TotalPointsCard";
import { SectionsCompletedCard } from "./SectionsCompletedCard";
import { QuestionsAnsweredCorrectlyCard } from "./QuestionsAnsweredCorrectlyCard";
import { UnitsCompletedCard } from "./UnitsCompletedCard";
import { LearningStreakCard } from "./LearningStreakCard";
import { NextPrizeCard } from "./NextPrizeCard";
import { WeeklyGoalCard } from "./WeeklyGoalCard";
import { PascalsTriangleCard } from "./PascalsTriangleCard";
import { LearnerPage } from "../Layouts/LearnerPage";
import { useLearnerContext } from "../../contexts/LearnerContext";
import Button from "../../../student/components/generic/button";
import { SkeletonText } from "../Skeleton";
import { ResponsiveImage } from "../ResponsiveImage";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import TourCardWrapper from "../Tour/TourCardWrapper";
import FlagIcon from "../../../shared/icons/FlagIcon";

export const Dashboard: React.FC = () => {
  const { learner, state } = useLearnerContext();
  const learnerContext = useLearnerContext();

  const isSmallDevice = useMediaQuery("(max-width : 767px)");

  const isEmpty = !state.isLoadingProgress && state.progresses.length === 0;

  const ptsUnlocked = (learner?.pascalsRow?.length ?? 0) > 0;

  // to make sure that we have an updated learner object we want to make sure to refetch the learner here
  // this will help with the updated correct answered questions and other properties used by the dashboard
  useEffect(() => {
    learnerContext.refetchLearner();
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LearnerPage title="Dashboard">
      {isEmpty ? (
        <div className="flex h-full items-center justify-center self-stretch md:pb-28">
          <div className="flex w-[550px] max-w-full flex-col items-center gap-4">
            <ResponsiveImage
              className="w-[300px]"
              srcs={[
                getFilePath(
                  "/images/learner/dashboard/welcome-empty-state-dashboard.png"
                ),
                getFilePath(
                  "/images/learner/dashboard/welcome-empty-state-dashboard@2x.png"
                ),
              ]}
              alt=""
              aria-hidden="true"
            />
            <div className="flex flex-col items-center gap-1 text-center">
              <h1 className="font-serif text-lg font-bold">
                <SkeletonText loaded={!!learner}>
                  {learner?.first ? `Welcome ${learner.first}!` : "Welcome!"}
                </SkeletonText>
              </h1>
              <p>Ready to sharpen your math skills?</p>
            </div>
            <Button className="w-48" href={`${REACT_APP_LEARNER_LINK}/courses`}>
              Browse Courses
            </Button>
          </div>
        </div>
      ) : (
        <>
          <header className="relative flex w-full flex-col gap-2 p-6 sm:flex-row sm:items-end">
            <div className="z-[7] flex-grow">
              <h1 className="font-serif text-2xl font-bold">
                {learner?.first ? `Hi ${learner.first}!` : "Welcome!"}
              </h1>
              <p className="text-lg">Check out your progress so far.</p>
            </div>
            {!isSmallDevice && learner && learner.level !== "hard" && (
              <ResponsiveImage
                className="absolute -top-4 right-0 w-40 object-contain"
                srcs={[
                  getFilePath("/images/learner/dashboard/dashboard-deltie.png"),
                  getFilePath(
                    "/images/learner/dashboard/dashboard-deltie@2x.png"
                  ),
                ]}
                alt=""
                aria-hidden="true"
              />
            )}
            {learner?.level === "hard" && (
              <Button
                size="small"
                type="outline"
                className="svg-hover flex h-fit w-min items-center gap-1 text-nowrap rounded px-8 py-1 font-bold leading-6"
                href={`${REACT_APP_LEARNER_LINK}/profile`}
              >
                <FlagIcon />
                Challenge Mode On
              </Button>
            )}
          </header>
          <div className="z-[7] flex grid-cols-3 flex-col gap-4 md:grid">
            <TourCardWrapper
              step={1}
              tourType="dashboard"
              classNames={clsx("col-start-1 col-end-3 row-start-1 row-end-4")}
            >
              <ContinueLearningCard />
            </TourCardWrapper>
            <TourCardWrapper
              step={2}
              tourType="dashboard"
              classNames={clsx("col-start-1 col-end-3 row-start-4 row-end-7")}
            >
              <PascalsTriangleCard />
            </TourCardWrapper>

            <TourCardWrapper
              step={3}
              tourType="dashboard"
              classNames={clsx("col-start-3 row-span-6")}
            >
              <div className="flex h-full flex-grow flex-col gap-4">
                <TotalPointsCard />
                <WeeklyGoalCard />
                <LearningStreakCard
                  streaks={learner?.streaks}
                  longestStreak={learner?.longest_streak}
                />
              </div>
            </TourCardWrapper>
            <TourCardWrapper
              step={4}
              tourType="dashboard"
              classNames={clsx(
                "col-span-3 flex flex-grow grid-cols-3 flex-col gap-4 md:grid"
              )}
            >
              {ptsUnlocked ? <NextPrizeCard /> : <UnitsCompletedCard />}
              <SectionsCompletedCard />
              <QuestionsAnsweredCorrectlyCard />
            </TourCardWrapper>
          </div>
        </>
      )}
    </LearnerPage>
  );
};
