import { DmLoadingSpinner } from "../../../../manager/utils/functions";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import { AddLearnersHeader } from "./AddLearnersHeader";
import { AddLearnersForm } from "./AddLearnersForm";
import { EmptyLearnerPage } from "../../Layouts/EmptyLearnerPage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParentContext } from "../../../contexts/ParentContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { getOpenSeats } from "../../../utils/getOpenSeats";

export const AddLearnersPage: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { learner: parent } = useLearnerContext();
  const { learnersLoadingState, learners, getAddedLearners } =
    useParentContext();
  const numLearners =
    getOpenSeats(parent?.subscriptionQuantity || 0, learners) +
    getAddedLearners().length;

  const next = () => {
    if (params.get("from") === "start") {
      navigate(`${REACT_APP_LEARNER_LINK}/parent/learners/done`);
    } else {
      navigate(`${REACT_APP_LEARNER_LINK}/parent/profile`);
    }
  };

  if (learnersLoadingState !== "done") {
    return (
      <main className="flex h-dvh w-dvw items-center justify-center">
        <DmLoadingSpinner message="" />
      </main>
    );
  }

  return (
    <EmptyLearnerPage title="Add Learners" dontTrackTime>
      <main className="flex h-dvh w-dvw flex-col items-center">
        <AddLearnersHeader
          title={numLearners > 1 ? "Add Learners" : undefined}
          showSkipLink
        />

        <AddLearnersForm numLearners={numLearners} next={next} />
      </main>
    </EmptyLearnerPage>
  );
};
