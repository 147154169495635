import { useProblemSolvingContext } from "../../contexts/ProblemSolvingContext";
import { CheckAnswerResponse, Problem } from "../../types";
import YourSolution from "./YourSolution";
import YourSolutionGraph from "./YourSolutionGraph";
import { useParams } from "react-router-dom";

type Props = {
  problem: Problem;
  checkAnswerResponse?: CheckAnswerResponse;
  unsubmitAllowed: boolean;
  userSolution?: object | string[];
  noSolutionText: string;
  noAnswerSubmitted: boolean;
  unsubmit: () => void;
  customMessage?: string;
  externalFileData?: any[];
};

const Solution = (props: Props) => {
  const { assignmentType } = useParams();
  const { logData } = useProblemSolvingContext();
  const showStudentGraph =
    props.problem.answerLines &&
    props.problem.answerLines.length > 0 &&
    logData?.data !== undefined;

  const multipleAnswers =
    logData?.attempts_data &&
    logData?.attempts_data.length > 0 &&
    assignmentType === "practice"
      ? true
      : false;

  return (
    <div className="yoursolution overflow-hidden">
      <h2 className={"font-serif text-lg font-bold"}>
        Your Answer{multipleAnswers ? "s" : ""}
      </h2>

      {showStudentGraph ? (
        <YourSolutionGraph
          problem={props.problem}
          checkAnswerResponse={props.checkAnswerResponse}
          unsubmitAllowed={props.unsubmitAllowed}
          userSolution={props.userSolution}
          noSolutionText={props.noSolutionText}
          noAnswerSubmitted={props.noAnswerSubmitted}
          unsubmit={props.unsubmit}
          customMessage={props.customMessage}
          externalFileData={props.externalFileData}
          showMultipleAnswers={multipleAnswers}
        />
      ) : (
        <YourSolution
          problem={props.problem}
          checkAnswerResponse={props.checkAnswerResponse}
          unsubmitAllowed={props.unsubmitAllowed}
          userSolution={props.userSolution}
          noSolutionText={props.noSolutionText}
          noAnswerSubmitted={props.noAnswerSubmitted}
          unsubmit={props.unsubmit}
          customMessage={props.customMessage}
          showMultipleAnswers={multipleAnswers}
        />
      )}
    </div>
  );
};

export default Solution;
