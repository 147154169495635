import Modal from "../../../student/components/generic/Modal";
import { formatPrice } from "../../utils/formatPrice";
import { useEffect, useState } from "react";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useParentContext } from "../../contexts/ParentContext";
import { oxfordCommaJoin } from "../../utils/oxfordCommaJoin";
import { format } from "date-fns";
import { capitalize, noop } from "lodash";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { MutateOptions, useMutation, useQuery } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import { executeQuery, REACT_APP_LEARNER_LINK } from "../../../utils";
import { useReactivationContext } from "./ReactivationContext";
import {
  FormattedLearnerSubscriptionPlan,
  QuantityChangePreviewResponse,
} from "../../types";
import clsx from "clsx";
import { getOpenSeats } from "../../utils/getOpenSeats";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { useSubscription } from "../../utils/useSubscription";

export const ConfirmReactivationModal: React.FC = () => {
  const {
    isReactivateModalVisible,
    learnersToReactivate,
    subscription,
    plans,
    closeReactivationModal,
  } = useReactivationContext();

  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();
  const {
    learners: allLearners,
    fetchChildLearners,
    resetInitialLearners,
  } = useParentContext();
  const { refetchSubscription } = useSubscription();
  const [isConfirming, setIsConfirming] = useState(false);
  const [selectedPlan, setSelectedPlan] =
    useState<FormattedLearnerSubscriptionPlan | null>(null);

  const parent = learnerContext.learner;
  const subscriptionQuantity = parent?.subscriptionQuantity ?? 0;

  const openSeats = getOpenSeats(subscriptionQuantity ?? 0, allLearners);
  const newQuantity = !isSubscriptionActive(parent)
    ? learnersToReactivate.length
    : openSeats >= learnersToReactivate.length
    ? subscriptionQuantity
    : learnersToReactivate.length === allLearners.length
    ? allLearners.length
    : subscriptionQuantity + learnersToReactivate.filter.length;

  const { mutate: reactivateParentMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/reactivate/parent`,
      JSON.stringify({
        reactivatingChildren: learnersToReactivate.map((l) => l._id),
      }),
      withJsonHeader()
    );
  });

  const { mutate: reactivateChildMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/reactivate/children`,
      JSON.stringify({
        learnerIds: learnersToReactivate.map((l) => l._id),
      }),
      withJsonHeader()
    );
  });

  const { mutate: startSubscriptionSessionMutation } = useMutation<
    { data: { url: string } },
    unknown,
    void
  >(() => {
    const body = {
      planId: selectedPlan?._id ?? "",
      quantity: learnersToReactivate.length,
      successUrl: `${window.location.origin}${REACT_APP_LEARNER_LINK}/common/check-status`,
      cancelUrl: window.location.href,
      isRenewal: true,
      reactivatingChildren: learnersToReactivate.map((l) => l._id),
    };
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/session/create`,
      JSON.stringify(body),
      withJsonHeader()
    );
  });

  const { data: previewData } = useQuery<QuantityChangePreviewResponse>(
    ["quantity_preview", newQuantity],
    () =>
      executeQuery({
        path: "/payments/subscriptions/update/quantity/preview",
        params: { quantity: newQuantity },
      }),
    {
      enabled: isReactivateModalVisible && isSubscriptionActive(parent),
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: changeQuantityMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/update/quantity`,
      JSON.stringify({
        quantity: newQuantity,
        reactivatingChildren: learnersToReactivate.map((l) => l._id),
      }),
      withJsonHeader()
    );
  });

  const mutateOptions: MutateOptions = {
    onSuccess: async () => {
      resetInitialLearners();
      refetchSubscription();
      learnerContext.refetchLearner();
      fetchChildLearners();
      closeReactivationModal();
    },
    onError: (e: any) => {
      toastContext.addToast({
        message: e.response.data.message || "Failed to reactivate subscription",
        status: "Error",
      });
    },
    onSettled: () => setIsConfirming(false),
  };

  const handleConfirm = async () => {
    if (!parent) {
      return;
    }
    setIsConfirming(true);
    if (isSubscriptionActive(parent)) {
      if (getLearnerAccountStatus(parent) === "canceled") {
        reactivateParentMutation(undefined, {
          ...mutateOptions,
          onSettled: noop,
          onSuccess(a, b, c) {
            if (subscriptionQuantity !== newQuantity) {
              changeQuantityMutation(undefined, mutateOptions);
            } else if (mutateOptions.onSuccess) {
              mutateOptions.onSuccess(a, b, c);
            }
          },
        });
      } else if (subscriptionQuantity !== newQuantity) {
        changeQuantityMutation(undefined, mutateOptions);
      } else if (openSeats >= learnersToReactivate.length) {
        reactivateChildMutation(undefined, mutateOptions);
      } else {
        changeQuantityMutation(undefined, mutateOptions);
      }
    } else {
      startSubscriptionSessionMutation(undefined, {
        ...mutateOptions,
        onSuccess: ({ data }) => {
          if (data.url) {
            window.location.href = data.url;
          }
        },
      });
    }
  };

  const learnerNames = oxfordCommaJoin(
    learnersToReactivate.map((l) => `${l.first} ${l.last}`)
  );
  const formattedProratedAmount = formatPrice(
    previewData ? previewData.proratedAmount : { value: 0, currency: "USD" }
  );
  const formattedFullAmount = (plan: FormattedLearnerSubscriptionPlan) =>
    formatPrice({
      value:
        (plan?.learnerPrice.value ?? 0) +
        (plan?.additionalLearnerPrice?.value ?? 0) * (newQuantity - 1),
      currency: plan?.learnerPrice?.currency ?? "USD",
    });
  const formattedBillingDate = format(
    subscription ? new Date(subscription.periodEnd) : new Date(),
    "MMMM d, yyyy"
  );
  const formattedToday = format(new Date(), "MMMM d, yyyy");

  useEffect(() => {
    if (!selectedPlan && plans.length) {
      setSelectedPlan(plans[0]);
    }
  }, [plans, selectedPlan]);

  return (
    <Modal
      visible={isReactivateModalVisible}
      onClose={closeReactivationModal}
      closeX
      body={
        <div className="flex flex-col gap-10">
          <h2 className="text-center font-serif text-2xl font-bold text-dm-brand-blue-600">
            {allLearners.length === learnersToReactivate.length
              ? "Reactivate All Learner Subscriptions"
              : learnersToReactivate.length === 1
              ? "Reactivate Learner Subscription"
              : "Reactivate Learner Subscriptions"}
          </h2>
          <div className="flex flex-col gap-6">
            {parent &&
            getLearnerAccountStatus(parent) === "canceled" &&
            selectedPlan ? (
              <p>
                You&apos;re reactivating {learnersToReactivate.length}{" "}
                {learnersToReactivate.length === 1 ? "learner" : "learners"},{" "}
                {learnerNames}. You will resume your subscription at{" "}
                <strong>
                  {formattedFullAmount(selectedPlan)} per{" "}
                  {selectedPlan?.interval === "monthly" ? "month" : "year"}
                </strong>
                , and your next payment will be on {formattedBillingDate}. Are
                you sure you want to continue?
              </p>
            ) : isSubscriptionActive(parent) &&
              selectedPlan &&
              getOpenSeats(subscriptionQuantity, allLearners) ? (
              <p>
                You&apos;re reactivating {learnersToReactivate.length}{" "}
                {learnersToReactivate.length === 1 ? "learner" : "learners"},{" "}
                {learnerNames}. This will not affect your current subscription.
                Are you sure you want to continue?
              </p>
            ) : isSubscriptionActive(parent) && selectedPlan ? (
              <p>
                You&apos;re reactivating {learnersToReactivate.length}{" "}
                {learnersToReactivate.length === 1 ? "learner" : "learners"},{" "}
                {learnerNames}. A prorated charge of {formattedProratedAmount}{" "}
                will be applied today. From {formattedBillingDate}, your
                subscription will renew at {formattedFullAmount(selectedPlan)}{" "}
                per month. Are you sure you want to continue?
              </p>
            ) : selectedPlan ? (
              <p>
                You&apos;re reactivating {learnersToReactivate.length}{" "}
                {learnersToReactivate.length === 1 ? "learner" : "learners"},{" "}
                {learnerNames}. From today, {formattedToday}, your subscription
                will be{" "}
                <strong>
                  {formattedFullAmount(selectedPlan)} per{" "}
                  {selectedPlan?.interval === "monthly" ? "month" : "year"}
                </strong>
                . Are you sure you want to continue?
              </p>
            ) : (
              <p>...</p>
            )}

            <div className="flex gap-4">
              {plans.map((plan) => (
                <button
                  key={plan._id}
                  className={clsx(
                    "relative w-full rounded-2xl border-[3px] bg-white p-6 text-left text-dm-charcoal-800",
                    selectedPlan?._id === plan._id && "border-dm-brand-blue-500"
                  )}
                  onClick={() => setSelectedPlan(plan)}
                >
                  <h5 className="mb-4 text-base font-bold">
                    {capitalize(plan.interval)} Plan
                  </h5>
                  <div className="flex items-baseline">
                    <span className="font-serif text-3xl font-bold !leading-10">
                      {formattedFullAmount(plan)}
                    </span>
                    <span>
                      /{plan?.interval === "monthly" ? "month" : "year"}
                    </span>
                  </div>
                  <p className="text-sm !leading-[26px] text-dm-charcoal-500">
                    billed {plan?.interval === "monthly" ? "monthly" : "yearly"}{" "}
                    for {newQuantity}{" "}
                    {newQuantity === 1 ? "learner" : "learners"}
                  </p>
                </button>
              ))}
            </div>
          </div>
        </div>
      }
      secondaryButtonText="Cancel"
      secondaryOnClick={closeReactivationModal}
      confirmButtonText={
        allLearners.length === learnersToReactivate.length
          ? "Reactivate All Learners"
          : learnersToReactivate.length === 1
          ? "Reactivate Learner"
          : "Reactivate Learner"
      }
      confirmDisabled={isConfirming}
      onConfirm={handleConfirm}
    />
  );
};
