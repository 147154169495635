import React from "react";
import DragHandle from "./DragHandle";
import { useSortable } from "@dnd-kit/sortable";
import { Row } from "react-table";

type Props = {
  row: Row;
  shadow?: boolean;
  dragTop?: boolean;
  lessPadding?: boolean;
  tallRow?: boolean;
};

const DraggableRow = (props: Props) => {
  const { attributes, listeners, transition, setNodeRef, isDragging } =
    useSortable({
      id: (props.row.original as any)._id,
    });

  const style = {
    // transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <tr
      ref={setNodeRef}
      style={style}
      {...props.row.getRowProps()}
      className={`${
        props.shadow
          ? "flex w-full items-center justify-between bg-white shadow-xl outline outline-1"
          : `w-full items-center border-t py-2 text-center`
      }`}
      key={`row-${props.row.id}`}
    >
      {isDragging ? (
        <td
          className={`h-16 w-full bg-dm-alt-blue ${
            props.tallRow ? "h-32" : ""
          }`}
          colSpan={props.row.cells.length + 1}
        />
      ) : props.dragTop ? (
        <div
          className={`flex w-full flex-col items-center justify-center ${
            props.dragTop ? "mb-4 pt-4" : ""
          }`}
        >
          <DragHandle
            isDragging={props.shadow || isDragging}
            {...attributes}
            onKeyDown={listeners?.onKeydown}
            onMouseDown={listeners?.onMouseDown}
            onTouchStart={listeners?.onTouchStart}
            lessPadding={props.lessPadding}
          />
          <div className="mt-2 w-full">
            {props.row.cells.map((cell, i) => (
              <>{cell.render("Cell")}</>
            ))}
          </div>
        </div>
      ) : (
        <>
          <td
            className={`${
              props.lessPadding ? "w-2" : "w-10"
            } bg-white text-center`}
          >
            <DragHandle
              isDragging={props.shadow || isDragging}
              {...attributes}
              onKeyDown={listeners?.onKeydown}
              onMouseDown={listeners?.onMouseDown}
              onTouchStart={listeners?.onTouchStart}
              lessPadding={props.lessPadding}
            />
          </td>
          {props.row.cells.map((cell, i) => (
            <td
              className={`bg-white ${props.lessPadding ? "py-4" : "p-4"}`}
              {...cell.getCellProps()}
              key={`cell-${i}`}
            >
              {cell.render("Cell")}
            </td>
          ))}
        </>
      )}
    </tr>
  );
};

export default DraggableRow;
