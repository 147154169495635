import React, { useMemo } from "react";
import { ILtiLog } from "./LTILogs";
import DeltaMathTable from "../../../shared/DeltaMathTable";

interface LaunchLogsProps {
  logs: ILtiLog[];
}

const LaunchLogs: React.FC<LaunchLogsProps> = ({ logs }) => {
  const showSectionNames = logs.some(
    (log: ILtiLog) => typeof log.custom_section_names === "string"
  );

  const columns = useMemo(
    () => [
      {
        Header: "Timestamp",
        accessor: "timestamp",
        align: "left",
        Cell: ({ value }: { value: number }) =>
          new Date(value * 1000).toLocaleString(),
      },
      {
        Header: "Product Code",
        accessor: "tool_consumer_info_product_family_code",
        align: "left",
      },
      {
        Header: "LIS Person",
        accessor: "lis_person_name_full",
        align: "left",
      },
      {
        Header: "LIS Email",
        accessor: "lis_person_contact_email_primary",
        align: "left",
      },
      ...(showSectionNames
        ? [
            {
              Header: "Section Names",
              accessor: (row: ILtiLog) => {
                let parsed: string[] = [];
                try {
                  parsed = JSON.parse(row.custom_section_names || '["N/A"]');
                  return parsed?.length === 0
                    ? "N/A"
                    : JSON.parse(row.custom_section_names || "[N/A]").join(
                        ", "
                      );
                } catch {
                  return "N/A";
                }
              },
              align: "left",
            },
          ]
        : []),
      {
        Header: "Context ID",
        accessor: "context_id",
        align: "left",
      },
      {
        Header: "Context Type",
        accessor: "context_type",
        align: "left",
      },
      {
        Header: "Context Title",
        accessor: "context_title",
        align: "left",
      },
      {
        Header: "Resource Link ID",
        accessor: "resource_link_id",
        align: "left",
      },
      {
        Header: "Resource Link Title",
        accessor: "resource_link_title",
        align: "left",
      },
      {
        Header: "OAuth Consumer Key",
        accessor: "oauth_consumer_key",
        align: "left",
      },
    ],
    [showSectionNames]
  );

  return (
    <div className="m-2">
      <h1 className="mx-4 py-4 text-2xl font-semibold text-gray-900">
        Launch Logs
      </h1>
      <DeltaMathTable columns={columns} data={logs} key="launch-logs" />
    </div>
  );
};

export default LaunchLogs;
