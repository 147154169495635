import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Courses from "./components/Courses/Courses";
import "./index.css";
import CourseLanding from "./components/CourseLanding";
import UnitLanding from "./components/UnitLanding";
import SubunitLanding from "./components/Subunit/SubunitsLanding";
import UnitTest from "./components/Unit/UnitTest";
import CourseTest from "./components/Course/CourseTest";
import { renderA11yString } from "../student/utils/render-a11y-string";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { ParentDashboardSwitcher } from "./components/Parent/ParentDashboardSwitcher";
import { PointsPage } from "./components/Points/PointsPage";
import { PrizesPage } from "./components/Points/Prizes/PrizesPage";
import { ParentContextProvider } from "./contexts/ParentContext";
import { RouteWithProtectedContext } from "./RouteWithProtectedContext";
import BetaSignUp from "./components/BetaSignUp/BetaSignUp";
import Login from "./components/Login/Login";
import {
  HOTJAR_VERSION,
  isRequestFromProd,
  REACT_APP_HOTJAR_ID,
  REACT_APP_LEARNER_HOME_LINK,
  REACT_APP_LEARNER_LINK,
} from "../utils";
import Hotjar from "@hotjar/browser";
import Favicon from "./components/favicon";
import { Learner, LearnerEntitlement } from "./types";
import { AddLearnersPage } from "./components/Parent/AddLearners/AddLearnersPage";
import { ParentProfileSwitcher } from "./components/Profile/ParentProfileSwitcher";
import { LearnerProfileSwitcher } from "./components/Profile/LearnerProfileSwitcher";
import { CheckStatusPage } from "./components/CheckStatusPage";
import { NotFoundPage } from "./NotFoundPage";
import { InvitePage } from "./components/Invite/InvitePage";
import { HomePage } from "./components/Home/HomePage";
import {
  TMP_HOMEPAGE_ALLOW_PROMO_CODE_PATH,
  TMP_HOMEPAGE_DISCOUNT_PATH,
  TMP_HOMEPAGE_PATH,
} from "./constants";
import { AddLearnersIntroPage } from "./components/Parent/AddLearners/AddLearnersIntroPage";
import { AddLearnersOutroPage } from "./components/Parent/AddLearners/AddLearnersOutroPage";
import { ProfileRedirect } from "./components/ProfileRedirect";

declare global {
  interface Window {
    hj: (command: string, ...args: any[]) => void;
  }
}

const LearnerApplication = () => {
  if (REACT_APP_HOTJAR_ID > 0) {
    Hotjar.init(REACT_APP_HOTJAR_ID, HOTJAR_VERSION);
  }
  const user: Learner = JSON.parse(localStorage.getItem("user") || "{}");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(false);
  const [wasLoggedIn, setWasLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // const logout = () => {
  //   queryClient.invalidateQueries();
  //   queryClient.removeQueries();
  //   userContext.clearJWT();
  //   Cookies.remove("refresh_token_javascript");
  //   localStorage.removeItem("admin");
  //   localStorage.removeItem("user");
  //   Sentry.setUser(null);
  //   setLoggedIn(false);
  // };

  // On login, navigate to the appropriate route. Only do this once!
  useEffect(() => {
    if (isLoggedIn && !wasLoggedIn) {
      setWasLoggedIn(true);
      const entitlements: LearnerEntitlement[] = Array.isArray(
        user.entitlements
      )
        ? user.entitlements
        : [];

      if (
        isRequestFromProd(window.location.origin) &&
        window.hj &&
        typeof window.hj === "function"
      ) {
        Hotjar.identify(user._id || null, {
          account_type: user.account_type,
          entitlements: entitlements.join(","),
          created_at: user.createdAt,
          first: user.first,
          last: user.last?.charAt(0),
          login_count: user.login_count,
          level: user.level,
          total_app_time: user.total_app_time,
          viewed_onboarding: user.viewedOnboarding,
          solved: user.solved,
          solved_correct: user.solved_correct,
          points_earned: user.pointsEarned,
          points_used: user.pointsUsed,
          dmEmployee: user.email.includes("@deltamath.com"),
        });
      }
    }
    // Only these dependencies appear to be necessary here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate, wasLoggedIn]);

  useEffect(() => {
    const user_to_login = JSON.parse(
      localStorage.getItem("user_to_login") || "{}"
    );
    if (user_to_login.user) {
      localStorage.setItem("user_to_login", "");
      localStorage.setItem("user", JSON.stringify(user_to_login.user));
      if (user_to_login.admin) {
        localStorage.setItem("admin", JSON.stringify(user_to_login.admin));
      }
      localStorage.setItem(
        "preferences",
        JSON.stringify(user_to_login.user.preferences)
      );
    }
  }, []);

  useEffect(() => {
    document.body.classList.add("h-full");
    const html = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
    html?.setAttribute("class", "h-full bg-gray-100");
    const root = document.getElementById("root");
    root?.classList.add("h-full");
  }, []);

  // Not ideal but is used in dmGraph so we need it on the window
  window.renderA11yString = renderA11yString;

  /* Semi-secret homepage for soft launch - ensures that people using the temporary link are navigated to the correct homepage */
  if (location.pathname === `${REACT_APP_LEARNER_LINK}/${TMP_HOMEPAGE_PATH}`) {
    return <Navigate to={`${REACT_APP_LEARNER_HOME_LINK}`} replace />;
  }

  return (
    <div className="relative flex h-fit flex-col bg-dm-background-blue-100 max-lg:!min-h-dvh lg:flex-row">
      <Favicon />
      <>
        <div id="modal-container"></div>
        <Routes>
          <Route
            path="common/check-status"
            element={
              <RouteWithProtectedContext>
                <CheckStatusPage />
              </RouteWithProtectedContext>
            }
          />
          {/*
            This is a link to use in emails to allow the user to easily update
            payment info or other account actions
          */}
          <Route
            path="common/profile"
            element={
              <RouteWithProtectedContext>
                <ProfileRedirect />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path={TMP_HOMEPAGE_DISCOUNT_PATH}
            element={<HomePage showPromoPrice />}
          />
          <Route
            path={TMP_HOMEPAGE_ALLOW_PROMO_CODE_PATH}
            element={<HomePage allowPromoCode />}
          />
          <Route
            path="courses"
            element={
              <RouteWithProtectedContext>
                <Courses />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="parent"
            element={
              <RouteWithProtectedContext>
                <ParentContextProvider>
                  <ParentDashboardSwitcher />
                </ParentContextProvider>
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="parent/learners/add"
            element={
              <RouteWithProtectedContext>
                <ParentContextProvider>
                  <AddLearnersPage />
                </ParentContextProvider>
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="parent/learners/start"
            element={
              <RouteWithProtectedContext>
                <ParentContextProvider>
                  <AddLearnersIntroPage />
                </ParentContextProvider>
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="parent/learners/done"
            element={
              <RouteWithProtectedContext>
                <ParentContextProvider>
                  <AddLearnersOutroPage />
                </ParentContextProvider>
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="parent/profile"
            element={
              <RouteWithProtectedContext>
                <ParentContextProvider>
                  <ParentProfileSwitcher />
                </ParentContextProvider>
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="dashboard"
            element={
              <RouteWithProtectedContext>
                <Dashboard />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="profile"
            element={
              <RouteWithProtectedContext>
                <LearnerProfileSwitcher />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="points"
            element={
              <RouteWithProtectedContext>
                <PointsPage />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="points/prizes"
            element={
              <RouteWithProtectedContext>
                <PrizesPage />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="course/:coursePath"
            element={
              <RouteWithProtectedContext>
                <CourseLanding />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path=":coursePath/coursetest/:indexOfSkill?/:submittedTime?"
            element={
              <RouteWithProtectedContext>
                <CourseTest />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path=":coursePath/:unitPath"
            element={
              <RouteWithProtectedContext>
                <UnitLanding />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path=":coursePath/:unitPath/unittest/:indexOfSkill?/:submittedTime?"
            element={
              <RouteWithProtectedContext>
                <UnitTest />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path=":coursePath/:unitPath/:subunitPath/:assignmentType?/:indexOfSkill?/:submittedTime?"
            element={
              <RouteWithProtectedContext>
                <SubunitLanding />
              </RouteWithProtectedContext>
            }
          />
          <Route
            path="sign-in"
            element={<Login setLoggedIn={setIsLoggedIn} />}
          />
          <Route path="beta_invite" element={<BetaSignUp />} />
          <Route path="invite/:inviteCode" element={<InvitePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </>
    </div>
  );
};

export default LearnerApplication;
