import { useEffect } from "react";
import Modal from "../../../../student/components/generic/Modal";
import { AccountTypeChooser } from "./AccountTypeChooser";
import { SignupLanding } from "./SignupLanding";
import { useUserContext } from "../../../../shared/contexts/UserContext";
import { Learner } from "../../../types";
import { isSubscriptionActive } from "../../../utils/isSubscriptionActive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import { Payment } from "../Payment/Payment";
import { useHomepageSignupContext } from "../HomepageSignupContext";
import LoginFormSwitcher from "../../Login/LoginFormSwitcher";

export const SignupModal: React.FC<{ allowPromoCode?: boolean }> = ({
  allowPromoCode,
}) => {
  const [searchParams] = useSearchParams();
  const {
    showSignup,
    setShowSignup,
    accountType,
    showLogin,
    setShowLogin,
    showPayment,
  } = useHomepageSignupContext();

  const navigate = useNavigate();

  const userContext = useUserContext();
  const user: Learner | null = JSON.parse(
    localStorage.getItem("user") || "null"
  );
  const isLoggedIn =
    (user?.entitlements ?? []).length > 0 || userContext.getJWT().length > 0;

  const isIncompleteGoogleSignup =
    isLoggedIn &&
    (user?.first === undefined ||
      user.first.length === 0 ||
      user?.last === undefined ||
      user?.last.length === 0);

  const showPaymentFinal =
    showPayment ||
    (isLoggedIn &&
      !isIncompleteGoogleSignup &&
      (accountType || searchParams.has("paid")));

  useEffect(() => {
    if (user && isSubscriptionActive(user)) {
      if (user.entitlements.includes("learner")) {
        navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
      } else {
        navigate(`${REACT_APP_LEARNER_LINK}/parent`);
      }
    }
  }, [navigate, user]);

  return (
    <Modal
      visible={showSignup || showLogin}
      onClose={() => {
        setShowSignup(false);
        setShowLogin(false);
      }}
      closeX
      bodyClassName="!p-0"
      widthClassName={
        showPaymentFinal
          ? "max-w-full w-[1041px] transition-none"
          : "max-w-full w-[688px] transition-none"
      }
      body={
        showLogin && !showSignup ? (
          <div className="px-4 pb-8 pt-14 sm:px-8 sm:pb-14 sm:pt-12">
            <LoginFormSwitcher dontHaveAnAccount />
          </div>
        ) : showPaymentFinal ? (
          <Payment allowPromoCode={allowPromoCode} />
        ) : accountType === undefined ? (
          <AccountTypeChooser />
        ) : (
          <SignupLanding isIncompleteGoogleSignup={isIncompleteGoogleSignup} />
        )
      }
    />
  );
};
