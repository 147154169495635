import clsx from "clsx";
import { ASSETS, DeltieAsset, DeltieOptions } from "./utils";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { changedDeltieOptionEvent } from "../../../analytics/events";

export const OptionButton: React.FC<{
  category: keyof typeof ASSETS;
  options: DeltieOptions;
  setOptions: (o: DeltieOptions) => void;
  assetKey: string;
  asset: DeltieAsset;
}> = ({ category, options, setOptions, assetKey, asset }) => {
  const { track } = useLearnerAnalytics();

  const buttonCategory =
    category === "scenes"
      ? "scene"
      : category === "expressions"
      ? "expression"
      : "accessories";

  const isSelected =
    buttonCategory === "accessories"
      ? (options[buttonCategory] as string[]).includes(assetKey)
      : options[buttonCategory] === assetKey;

  const handleClick = () => {
    // Accessories behave differently within sub-categories
    if (category === "accessories") {
      let newAccessories = options.accessories;
      if (isSelected && asset.accessoryCategory !== "shoes") {
        // If removing, just remove it. Nothing special here. (Except you can't
        // remove shoes)
        newAccessories = newAccessories.filter((a) => a !== assetKey);
      } else if (
        asset.accessoryCategory === "shoes" ||
        asset.accessoryCategory === "hats" ||
        asset.accessoryCategory === "pants"
      ) {
        // These categories can only have one item, so remove the existing
        // item from the same category first
        newAccessories = newAccessories.filter(
          (a) =>
            ASSETS.accessories[a].accessoryCategory !== asset.accessoryCategory
        );
        newAccessories.push(assetKey as keyof typeof ASSETS.accessories);
      } else {
        // Other categories can have multiple items, so just add the new item
        newAccessories.push(assetKey as keyof typeof ASSETS.accessories);
      }
      setOptions({ ...options, accessories: newAccessories });
    }

    // Other categories only have one item at a time, so we can just set the
    // new value
    else {
      setOptions({
        ...options,
        [buttonCategory]: assetKey,
      });
    }

    // Track that the button was clicked. Don't really care if clicking if it's
    // removing the item or not, just want to know if people are interacting
    // with it
    track(changedDeltieOptionEvent(assetKey));
  };

  return (
    <button
      className={clsx(
        "group relative h-[72px] w-[72px] transition-opacity duration-75 hover:opacity-100",
        !isSelected && "opacity-50"
      )}
      onClick={handleClick}
      title={asset.name}
    >
      {category === "accessories" &&
      isSelected &&
      asset.accessoryCategory !== "shoes" ? (
        <span className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full border-2 border-dm-brand-blue-500 bg-[#F8EC5F]">
          <span className="far fa-times text-sm !text-dm-brand-blue-500" />
        </span>
      ) : null}
      <span
        className={clsx(
          "block h-full w-full overflow-hidden rounded-md border-2 border-dm-background-blue-100 bg-dm-brand-blue-100 transition-colors duration-150",
          isSelected && "!border-dm-brand-blue-500"
        )}
      >
        <img src={asset.thumbnail} alt={assetKey} />
      </span>
    </button>
  );
};
