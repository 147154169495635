import { useMediaQuery } from "usehooks-ts";
import { getFilePath, REACT_APP_LEARNER_LINK } from "../../../utils";
import Button from "../../../student/components/generic/button";
import clsx from "clsx";
import { ResponsiveImage } from "../ResponsiveImage";
import { useHomepageSignupContext } from "./HomepageSignupContext";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { Learner } from "../../types";
import { PriceCard } from "./Payment/PriceCard";
import { calculateAnnualPlanDiscount } from "./Payment/calculateAnnualPlanDiscount";

export const HeroWithPrices: React.FC = () => {
  const userContext = useUserContext();
  const user: Learner | null = JSON.parse(
    localStorage.getItem("user") || "null"
  );
  const isLoggedIn =
    (user?.entitlements ?? []).length > 0 || userContext.getJWT().length;

  const { startTrialText, scrollToPlans, setShowSignup, setShowLogin, plans } =
    useHomepageSignupContext();
  const isSmallDevice = useMediaQuery("(max-width : 767px)");
  const isHighDpi = useMediaQuery("screen and (min-resolution: 73dpi)");
  const texturedBgTile = getFilePath(
    isHighDpi
      ? "/images/learner/textured-blue-tile@2x.png"
      : "/images/learner/textured-blue-tile.png"
  );
  const texturedBgBorder = getFilePath(
    isSmallDevice
      ? isHighDpi
        ? "/images/learner/textured-blue-border-small@2x.png"
        : "/images/learner/textured-blue-border-small.png"
      : isHighDpi
      ? "/images/learner/textured-blue-border@2x.png"
      : "/images/learner/textured-blue-border.png"
  );
  const texturedBorderSize = isSmallDevice
    ? "bg-[length:375px_16px]"
    : "bg-[length:1440px_41px]";

  const [monthlyPlan, annualPlan] = plans ? plans : [];
  const discount = plans
    ? calculateAnnualPlanDiscount(monthlyPlan, annualPlan)
    : 0;

  return (
    <section className="w-full overflow-hidden">
      <div
        className="relative mb-20 flex w-full flex-col items-center bg-[length:200px_200px] md:mb-32"
        style={{ backgroundImage: `url('${texturedBgTile}')` }}
      >
        <header className="flex w-full justify-between px-4 pb-3 pt-4 md:px-14 md:pb-6 md:pt-6">
          <img
            src={getFilePath("/images/DeltaMath-Logo_Home.svg")}
            alt="DeltaMath for Home"
          />
          <div className="flex gap-6">
            {isLoggedIn ? (
              <Button
                className="h-10 bg-white p-0 text-sm"
                type="outline"
                href={`${REACT_APP_LEARNER_LINK}/dashboard`}
              >
                Go to your Dashboard
              </Button>
            ) : (
              <Button
                className="h-10 bg-white p-0 text-sm"
                type="outline"
                onClick={() => setShowLogin(true)}
              >
                Log In
              </Button>
            )}
            <Button
              className="hidden h-10 w-52 p-0 text-sm md:inline-block"
              onClick={() => scrollToPlans("hero-header")}
            >
              {startTrialText}
            </Button>
          </div>
        </header>

        <div className="mb-40 flex w-[732px] max-w-full flex-col px-4 pt-20 text-center md:px-0 md:pb-10 md:pt-28 lg:mb-36 2xl:mb-20">
          <div className="mb-4 flex flex-col-reverse items-center font-serif font-bold text-dm-brand-blue-600">
            <h1 className="text-[32px] md:text-[64px]">DeltaMath for Home</h1>
            <h2 className="text-lg md:text-2xl">Math Made Clear</h2>
          </div>
          <p className="mb-8 text-lg leading-9 md:text-2xl">
            Transform your math experience with a personalized learning platform
            designed for at-home success.
          </p>
          <div className="mb-8 flex flex-col gap-4 text-left sm:flex-row md:px-10">
            {plans ? (
              <>
                <PriceCard
                  plan={monthlyPlan}
                  title="Monthly Plan"
                  type="month"
                />
                <PriceCard
                  plan={annualPlan}
                  title="Annual Plan"
                  type="year"
                  discount={discount}
                />
              </>
            ) : (
              Array(2)
                .fill(0)
                .map((_, i) => (
                  <div
                    key={i}
                    className="h-40 w-full rounded-2xl border-2 border-dm-brand-blue-200"
                  />
                ))
            )}
          </div>
          <div>
            <Button
              className="h-10 w-full text-sm sm:w-80"
              onClick={() => setShowSignup(true)}
            >
              {startTrialText}
            </Button>
          </div>
        </div>

        <div
          className={clsx(
            "absolute left-0 top-full h-4 w-full md:h-[41px]",
            texturedBorderSize
          )}
          style={{
            backgroundImage: `url('${texturedBgBorder}')`,
          }}
        />

        <ResponsiveImage
          className="absolute -bottom-32 right-1/2 mr-56 hidden lg:mr-72 lg:block 2xl:-bottom-32 2xl:mr-96"
          srcs={[
            getFilePath("/images/learner/home/math-tools-grid.png"),
            getFilePath("/images/learner/home/math-tools-grid@2x.png"),
          ]}
          alt="Calculator, rulers, and a chalkboard on a grid background"
        />
        <ResponsiveImage
          className="absolute -bottom-20 left-1/2 ml-48 hidden lg:ml-56 lg:block 2xl:ml-[350px]"
          srcs={[
            getFilePath("/images/learner/home/deltie-a-plus.png"),
            getFilePath("/images/learner/home/deltie-a-plus@2x.png"),
          ]}
          alt="Deltie holding an A+ sign"
        />
        <ResponsiveImage
          className="absolute -bottom-9 -right-2 lg:hidden"
          srcs={[
            getFilePath("/images/learner/home/deltie-a-plus-small.png"),
            getFilePath("/images/learner/home/deltie-a-plus-small@2x.png"),
          ]}
          alt="Deltie holding an A+ sign"
        />
      </div>
    </section>
  );
};
