import clsx from "clsx";
import { uniqueId } from "lodash";
import {
  FormEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SearchType } from "../../types";

const SEARCH_DEBOUNCE_TIME = 300;

interface SearchInputProps {
  resultsId: string;
  onQuery: (query: string) => void;
  type: SearchType;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  resultsId,
  onQuery,
  type,
}) => {
  const [query, setQuery] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [idStr] = useState(() => `search-input--${uniqueId()}`);
  const inputRef = useRef<HTMLInputElement>(null);

  const performSearch = (force = false) => {
    const q = query.trim();
    if (q.length > 0 || force) {
      onQuery(q);
    }
  };

  // Search on change after debounce
  useEffect(() => {
    const t = setTimeout(() => performSearch(), SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // Search immediately when hitting enter
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    performSearch();
  };

  const clearSearch = useCallback(() => {
    setQuery("");
    setIsActive(false);
    performSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown: KeyboardEventHandler = useCallback((e) => {
    if (e.key === "Escape") {
      clearSearch();
      inputRef.current?.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className="flex" onSubmit={handleSubmit} role="search">
      <div
        className={clsx(
          isActive ? "md:border-dm-charcoal-100" : "border-transparent",
          "relative w-full transition-colors md:border-b-[1px]"
        )}
      >
        <label
          htmlFor={idStr}
          className={clsx(
            isActive
              ? "text-black md:!right-[calc(100%-24px)]"
              : "cursor-pointer text-dm-gray-200 hover:text-black",
            "absolute inset-y-0 left-2 flex items-center transition-all md:left-auto md:right-0"
          )}
          aria-hidden="true"
        >
          <i className="far fa-fw fa-search indent leading-none md:text-xl"></i>
          <span className="sr-only">Search</span>
        </label>
        <input
          ref={inputRef}
          type="text"
          name="query"
          id={idStr}
          className={clsx(
            !isActive && "cursor-default md:placeholder-white",
            "w-full rounded-lg border border-dm-charcoal-100 px-9 py-2 placeholder-dm-charcoal-200 focus:border-dm-charcoal-100 focus:shadow-lg focus:ring-0 md:rounded-none md:border-0 md:border-r-0 md:focus:border-0 md:focus:shadow-none"
          )}
          value={query}
          placeholder={
            type === "course"
              ? "Search Units, Skills, or Tests"
              : "Search courses, units, sections, or skills"
          }
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsActive(true)}
          aria-describedby="search-help"
          aria-controls={resultsId}
        />
        <button
          type="reset"
          className={clsx(
            isActive ? "visible" : "invisible",
            "absolute inset-y-0 right-2 flex items-center opacity-70 hover:opacity-100 md:right-0"
          )}
          onClick={clearSearch}
          aria-label="Clear search query and close search"
        >
          <i
            className="far fa-fw fa-times text-xl leading-none !text-black"
            aria-hidden="true"
          ></i>
        </button>

        <div id="search-help" className="hidden">
          Results will update as you type.
        </div>
      </div>
    </form>
  );
};
