import { useContext, Dispatch, SetStateAction } from "react";
// import { UserCircleIcon } from "@heroicons/react/outline";
import AddNewClass from "./AddNewClass";
import { NavLink, Link } from "react-router-dom";
import clsx from "clsx";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { SectionDataStatus } from "../_constants";
import { getSectionData, findNearestUpcoming, autoArchive } from "../utils";
import {
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_IMPERSONATION_LINK,
  REACT_APP_STUDENT_LINK,
  deltamathLogo,
} from "../../utils";
import CalculatorButton from "./CalculatorButton";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../shared/contexts/UserContext";
import ArchivedCourses from "./ArchivedCourses";
// import { getFilePath } from "../../utils";
import Button from "./generic/button";
import Cookies from "js-cookie";
import FadeInSection from "./FadeInSection";

type Props = {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export default function SideBarMenu({ setSidebarOpen }: Props): JSX.Element {
  const impersonate = window.localStorage.getItem("impersonateStudent");

  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const {
    dmSectionsData,
    dmAssignmentData,
    calcAvailable,
    assignmentsIsFetched,
  } = useContext(StudentSectionsContext);

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userName =
    user?.first === undefined && user?.last === undefined
      ? "signing out..."
      : `${user.first} ${user.last}`;

  const logout = () => {
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    userContext.clearJWT();
    Cookies.remove("refresh_token_javascript");
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
  };

  return (
    <div className="relative flex h-full flex-col">
      <div className="mb-1 shrink-0">
        <Link
          to={`${REACT_APP_STUDENT_LINK}/${
            findNearestUpcoming(dmAssignmentData) ?? dmSectionsData[0]?._id
          }/upcoming`}
          className="block h-6 bg-white focus:outline-none"
        >
          <img
            className="z-2 h-full w-auto"
            alt="DeltaMath Home"
            src={deltamathLogo}
          />
        </Link>
      </div>
      <div className="flex flex-grow flex-col justify-start overflow-hidden">
        <div className="overflow-auto pb-8 pt-14">
          <h4 className="pl-3 text-xs uppercase text-dm-gray-500">Classes</h4>
          {assignmentsIsFetched &&
            dmSectionsData
              ?.filter(
                (item: any) =>
                  item.term === "current" &&
                  !autoArchive(dmAssignmentData, item._id)
              )
              .map((item: any) => {
                const assignmentList = getSectionData(
                  SectionDataStatus.upcoming,
                  item._id,
                  dmAssignmentData
                );
                const numOfAssignments = Object.keys(assignmentList)?.length;
                return (
                  <FadeInSection
                    key={`fade-sb:${item._id}`}
                    additionalClasses="last:mb-0 mb-2"
                  >
                    <NavLink
                      to={`${item._id}`}
                      onClick={() => setSidebarOpen(false)}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? "bg-dm-brand-blue-100 font-bold text-dm-gray-800"
                            : "text-dm-gray-600",
                          "group mb-1 flex items-center rounded-md p-2 text-sm last:mb-0 hover:bg-dm-brand-blue-100 hover:text-dm-gray-800"
                        )
                      }
                      aria-current={item.current ? "page" : undefined}
                    >
                      {({ isActive }) => (
                        <>
                          <i
                            className="far fa-chalkboard mr-2 w-6 flex-shrink-0 text-center text-dm-gray-200 group-hover:text-dm-gray-800"
                            aria-hidden="true"
                          ></i>
                          <span
                            className={`truncate ${
                              isActive ? "text-dm-gray-800" : "text-dm-gray-600"
                            }`}
                          >
                            {item.name}
                          </span>
                          {assignmentList && numOfAssignments > 0 && (
                            <span
                              className={clsx(
                                "bg-dm-error-500 text-white",
                                "ml-auto inline-block rounded-lg px-3 py-0.5 text-xs"
                              )}
                              aria-label={`${numOfAssignments} upcoming assignments.`}
                            >
                              {numOfAssignments}
                            </span>
                          )}
                        </>
                      )}
                    </NavLink>
                  </FadeInSection>
                );
              })}
          <FadeInSection additionalClasses="mb-1">
            <AddNewClass />
          </FadeInSection>
          <FadeInSection>
            <ArchivedCourses />
          </FadeInSection>
          <FadeInSection>
            <NavLink
              to={`${REACT_APP_STUDENT_LINK}/help-video`}
              onClick={() => setSidebarOpen(false)}
              className={({ isActive }) =>
                clsx(
                  isActive
                    ? "bg-dm-brand-blue-100 font-bold text-dm-gray-800"
                    : "text-dm-gray-600",
                  "group mb-1 flex items-center rounded-md p-2 text-sm hover:bg-dm-brand-blue-100 hover:text-dm-gray-800"
                )
              }
            >
              {({ isActive }) => (
                <>
                  <i className="far fa-video mr-2 w-6 flex-shrink-0 text-center text-base text-dm-gray-200 group-hover:text-dm-gray-800"></i>
                  <span
                    className={`truncate ${
                      isActive ? "text-dm-gray-800" : "text-dm-gray-600"
                    }`}
                  >
                    Intro to DeltaMath Video
                  </span>
                </>
              )}
            </NavLink>
          </FadeInSection>
        </div>
      </div>

      <div className="mt-1 flex flex-col border-t bg-white pt-6">
        {calcAvailable && <CalculatorButton setSidebarOpen={setSidebarOpen} />}

        <div
          id="user-buttons"
          className="flex items-center justify-between p-2"
        >
          <NavLink
            to={`${REACT_APP_STUDENT_LINK}/profile`}
            onClick={() => setSidebarOpen(false)}
            className="truncate text-dm-gray-600 hover:text-dm-gray-800"
            aria-label="Profile Settings"
          >
            <div className="group flex items-center text-sm">
              <i className="far fa-user mr-2 h-6 w-6 pl-1 text-xl leading-none text-dm-gray-200 group-hover:text-dm-gray-800"></i>
              <span className="truncate text-dm-gray-600">{userName}</span>
            </div>
          </NavLink>
          <Button
            type="link"
            className="flex-shrink-0 text-sm"
            onClick={logout}
          >
            Log Out
          </Button>
        </div>

        {impersonate === "true" && (
          <Button
            id="decamp"
            onClick={() => {
              window.location.href = `${REACT_APP_IMPERSONATION_LINK}?decamp=true`;
            }}
            className="w-full"
          >
            Back to Teacher Account
          </Button>
        )}
      </div>
    </div>
  );
}
