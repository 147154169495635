import { capitalize } from "lodash";
import Button from "../../../../student/components/generic/button";
import { ASSETS, DEFAULT_OPTIONS, DeltieAsset, DeltieOptions } from "./utils";
import { Fragment, useState } from "react";
import { OptionButton } from "./OptionButton";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import {
  changedDeltieCategoryEvent,
  clickedResetDeltieEvent,
  clickedSaveDeltieEvent,
} from "../../../analytics/events";

interface AssetGroup {
  subcategory: string | undefined;
  // assets: Array<{ key: string; asset: DeltieAsset }>;
  assets: [string, DeltieAsset][];
}

export function groupAssetsForThumbnails(
  assets: Record<string, DeltieAsset>
): AssetGroup[] {
  const groupedAssets: AssetGroup[] = [];
  Object.entries(assets).forEach(([key, asset]) => {
    const groupIndex = groupedAssets.findIndex(
      ({ subcategory }) => subcategory === asset.accessoryCategory
    );
    if (groupIndex === -1) {
      groupedAssets.push({
        subcategory: asset.accessoryCategory,
        assets: [[key, asset]],
      });
    } else {
      groupedAssets[groupIndex].assets.push([key, asset]);
    }
  });
  return groupedAssets;
}

export const DeltieSidebar: React.FC<{
  options: DeltieOptions;
  setOptions: (options: DeltieOptions) => void;
  canvas: HTMLCanvasElement | undefined;
}> = ({ options, setOptions, canvas }) => {
  const [selectedCategory, setSelectedCategory] =
    useState<keyof typeof ASSETS>("scenes");

  const { track } = useLearnerAnalytics();

  return (
    <aside className="z-10 -ml-[2px] -mr-[2px] -mt-2 flex flex-1 flex-col gap-7 rounded-lg border-2 border-b-0 border-dm-brand-blue-500 bg-dm-background-blue-100 p-4 md:p-6 xl:m-0 xl:mt-0 xl:rounded-none xl:border-l-2 xl:border-r-0 xl:border-t-0">
      <header className="flex w-full gap-1">
        {Object.keys(ASSETS).map((category) => (
          <Button
            key={category}
            type={selectedCategory === category ? "primary" : "outline"}
            className="h-[42px] flex-1 rounded-full !p-0 text-sm font-bold"
            onClick={() => {
              setSelectedCategory(category as keyof typeof ASSETS);
              track(changedDeltieCategoryEvent(category));
            }}
          >
            {capitalize(category)}
          </Button>
        ))}
      </header>

      <div className="flex-1">
        <div className="grid grid-cols-4 items-start gap-2">
          {groupAssetsForThumbnails(ASSETS[selectedCategory]).map(
            ({ subcategory, assets }) => (
              <Fragment key={`${selectedCategory}-${subcategory}`}>
                {subcategory && (
                  <h4 className="col-span-4 flex items-center gap-2 text-sm text-dm-charcoal-500">
                    {capitalize(subcategory)}
                    <span className="flex-1 border-t border-dm-charcoal-200" />
                  </h4>
                )}
                {assets.map(([key, asset]) => (
                  <OptionButton
                    key={`${selectedCategory}-${key}`}
                    category={selectedCategory}
                    options={options}
                    setOptions={setOptions}
                    assetKey={key}
                    asset={asset}
                  />
                ))}
              </Fragment>
            )
          )}
        </div>
      </div>

      <footer className="flex items-center justify-between">
        <button
          className="flex items-center gap-2 px-2 py-1 text-dm-brand-blue-500 hover:text-dm-brand-blue-800"
          onClick={() => {
            setOptions(DEFAULT_OPTIONS);
            setSelectedCategory("scenes");
            track(clickedResetDeltieEvent());
          }}
        >
          <i className="far fa-redo" />
          Start Over
        </button>
        <a
          // Switch to these styles if switching back to link
          // className="flex items-center gap-2 px-2 py-1 text-dm-brand-blue-500 hover:text-dm-brand-blue-800"
          className="flex h-8 items-center gap-2 rounded bg-dm-brand-blue-500 px-4 text-white hover:bg-dm-brand-blue-600"
          href={
            canvas
              ? canvas
                  .toDataURL("image/png")
                  .replace("image/png", "image/octet-stream")
              : "#"
          }
          download="deltie.png"
          onClick={() => {
            track(clickedSaveDeltieEvent(options));
          }}
        >
          <i className="far fa-arrow-to-bottom" />
          Save
        </a>

        {/* Will add sharing in the future if this is something that people like and use */}
        {/* <Button
          className="flex h-8 items-center gap-2 !px-4 py-0"
          onClick={() => window.alert("TODO: Share")}
        >
          <i className="far fa-external-link" />
          Share
        </Button> */}
      </footer>
    </aside>
  );
};
