import { getFilePath } from "../../utils";
import { FactorBubbleGame } from "../components/Points/Prizes/FactorBubbleGame";
import { LoadingIconConfig } from "../components/Points/Prizes/LoadingIconConfig";
import ConfettiConfig from "../components/Points/Prizes/ConfettiConfig";
import { SnakeGame } from "../components/Points/Prizes/SnakeGame";
import { Prize } from "../types";
import { ResponsiveImage } from "../components/ResponsiveImage";
import { useLearnerExperiments } from "./experiments/useLearnerExperiments";

/** The set of all available prizes */
const PRIZES: Prize[] = [
  {
    key: "loadingIcon",
    name: "Custom Animation",
    description:
      "Personalize your loading screens with a fun Deltie animation!",
    image: (
      <ResponsiveImage
        srcs={[
          getFilePath("/images/learner/prizes/loadingIcon.png"),
          getFilePath("/images/learner/prizes/loadingIcon@2x.png"),
        ]}
        alt="Loading icon"
      />
    ),
    type: "config",
    configCta: "Select Animation",
    ConfigComponent: LoadingIconConfig,
    unlockTitle: "You've Unlocked Custom Animations!🕺",
    unlockText:
      "Use this prize to personalize your loading screens with a custom Deltie animation!",
  },
  {
    key: "factorBubblesGame",
    name: "Bubble Blast Game",
    description:
      "Test your factoring skills by shooting bubbles and clearing the board in this exciting game!",
    image: (
      <ResponsiveImage
        srcs={[
          getFilePath("/images/learner/prizes/factorBubblesGame.png"),
          getFilePath("/images/learner/prizes/factorBubblesGame@2x.png"),
        ]}
        alt="Bubble Blast Game"
      />
    ),
    type: "content",
    contentCta: "Open Game",
    ContentComponent: FactorBubbleGame,
    unlockTitle: "You've Unlocked the Bubble Blast Game! 🫧",
    unlockText:
      "Aim, shoot, and factor your way to victory in this exciting bubble-popping challenge!",
  },
  {
    key: "confetti",
    name: "Custom Confetti",
    description: "Celebrate your wins with a personalized confetti shower!",
    type: "config",
    image: (
      <ResponsiveImage
        srcs={[
          getFilePath("/images/learner/prizes/confetti.png"),
          getFilePath("/images/learner/prizes/confetti@2x.png"),
        ]}
        alt="Confetti"
      />
    ),
    configCta: "Customize Confetti",
    ConfigComponent: ConfettiConfig,
    unlockTitle: "You've Unlocked Custom Confetti! 🎊",
    unlockText:
      "Use this prize to celebrate your milestones with a personalized confetti shower! Choose your favorite shapes and effects to make every win a party 🎉",
    isLargeModal: true,
  },
  {
    key: "questionSwap",
    name: "Question Swap",
    description:
      "Hit a tough question? Swap it once per attempt on post-quizzes, unit tests, or course tests.",
    image: (
      <ResponsiveImage
        srcs={[
          getFilePath("/images/learner/prizes/questionSwap.png"),
          getFilePath("/images/learner/prizes/questionSwap@2x.png"),
        ]}
        alt="Question swap"
      />
    ),
    type: "standard",
    unlockTitle: "Strategic Question Swap!",
    unlockText:
      "Feel like you can ace another question instead? Swap one out during your post-quiz, unit test, or course test for a new problem in the same skill. This can only be done once per attempt, so choose strategically!",
  },
  {
    key: "testHint",
    name: "Test Hints",
    description:
      "Double-check your answers and catch any mistakes before submitting your test or quiz!",
    image: (
      <ResponsiveImage
        srcs={[
          getFilePath("/images/learner/prizes/testHint.png"),
          getFilePath("/images/learner/prizes/testHint@2x.png"),
        ]}
        alt="Test hints"
      />
    ),
    type: "standard",
    unlockTitle: "Discover which answers you missed!",
    unlockText:
      "Use test hints on post-quizzes, unit tests, and course tests to reveal incorrect answers before you submit. You get one hint for each attempt, so use it wisely!",
  },
  {
    key: "snakeGame",
    name: "Snake Game",
    description:
      "Guide the snake to the correct answers while avoiding obstacles in this fast-paced math challenge!",
    image: (
      <ResponsiveImage
        srcs={[
          getFilePath("/images/learner/prizes/snakeGame.png"),
          getFilePath("/images/learner/prizes/snakeGame@2x.png"),
        ]}
        alt="Snake Game"
      />
    ),
    type: "content",
    contentCta: "Open Game",
    ContentComponent: SnakeGame,
    unlockTitle: "You've Unlocked the Snake Game! 🐍",
    unlockText:
      "Think fast and move the snake to eat the right answers in this mental math game! The longer the snake gets, the more points you score.",
  },
];

/** Get the current learner's full list of potential prizes */
export function usePrizes(): Prize[] {
  const { isLoading, isInExperiment } = useLearnerExperiments();
  if (!isLoading) {
    const shouldSwapGames = isInExperiment("swapGamePrizesOrder", "variant");
    if (shouldSwapGames) {
      return PRIZES.map((p) => {
        if (p.key === "factorBubblesGame") {
          return PRIZES.find((p) => p.key === "snakeGame") || p;
        } else if (p.key === "snakeGame") {
          return PRIZES.find((p) => p.key === "factorBubblesGame") || p;
        }
        return p;
      });
    }
    return PRIZES;
  }
  return [];
}
