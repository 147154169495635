import { SidebarLink } from "../SidebarLink";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import { GridSquareIcon } from "../../../../shared/icons/GridSquareIcon";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import TourCardWrapper from "../../Tour/TourCardWrapper";

const DashboardButton = () => {
  const learnerContext = useLearnerContext();

  return (
    <TourCardWrapper step={3} tourType="learner" stickyElement>
      <SidebarLink
        to={`${REACT_APP_LEARNER_LINK}/dashboard`}
        icon={<GridSquareIcon />}
      >
        {!learnerContext.isSidebarMinimized && "Dashboard"}
      </SidebarLink>
    </TourCardWrapper>
  );
};

export default DashboardButton;
