import { format } from "date-fns";
import { ProfilePage } from "./ProfilePage";
import { ProfileSection } from "./ProfileSection";
import { ProfileField } from "./ProfileField";
import { ProfileFieldGroup } from "./ProfileFieldGroup";
import { Learner } from "../../types";
import { ProfileColumn } from "./ProfileColumn";
import { SubscriptionDetailsField } from "./Fields/SubscriptionDetailsField";
import { BillingFrequencyField } from "./Fields/BillingFrequencyField";
import { BillingHistoryField } from "./Fields/BillingHistoryField";
import { PaymentInformationField } from "./Fields/PaymentInformationField";
import { UpcomingChargesField } from "./Fields/UpcomingChargesField";
import { LearnerEditAccountButton } from "./LearnerEditAccountButton";
import { CancellationContextProvider } from "./Cancellation/CancellationContext";
import { CancelSubscriptionModal } from "./Cancellation/CancelSubscriptionModal";
import { useEffect, useState } from "react";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import Modal from "../../../student/components/generic/Modal";
import { HomepageSignupContextProvider } from "../Home/HomepageSignupContext";
import { Payment } from "../Home/Payment/Payment";
import ToggleChallengeMode from "./ToggleChallengeMode";
import { Infotip } from "../../../shared/Infotip";
import { useSubscription } from "../../utils/useSubscription";
import { useLogout } from "../../../utils/auth/logout";
import Button from "../../../student/components/generic/button";

export const IndependentLearnerProfile: React.FC<{ user: Learner }> = ({
  user,
}) => {
  const { learner } = useLearnerContext();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { subscription } = useSubscription();
  const logout = useLogout();

  useEffect(() => {
    if (learner && !isSubscriptionActive(learner)) {
      setShowPaymentModal(true);
    }
  }, [learner]);

  return (
    <ProfilePage>
      <CancellationContextProvider>
        <ProfileSection title="Account & Subscription Information">
          <Button
            className="absolute right-6 top-2 hidden text-sm sm:block"
            type="link"
            onClick={logout}
          >
            Log out
          </Button>
          <ProfileColumn>
            <ProfileFieldGroup title="Your Account" className="relative">
              <LearnerEditAccountButton
                className="absolute right-0 top-0 sm:right-10"
                user={user}
              />
              <ProfileField label="Account Holder Name">
                {user.first} {user.last}
              </ProfileField>
              <ProfileField label="Account Holder Email">
                {user.email}
              </ProfileField>
              <ProfileField label="Password">{"*".repeat(16)}</ProfileField>
              <ProfileField
                label={
                  <>
                    Challenge Mode{" "}
                    <Infotip
                      options={{
                        tooltipRight: true,
                        maxWidth: 200,
                        aligned: "center",
                      }}
                      message="Courses too easy? Enable challenge mode to see fewer basic problems and more challenging problems. Most content will be the same in each section whether or not Challenge Mode is enabled."
                    >
                      <i
                        className="far fa-info-circle text-lg font-normal leading-none text-dm-gray-200"
                        aria-hidden="true"
                      ></i>
                    </Infotip>
                  </>
                }
              >
                <ToggleChallengeMode learner={learner} />
              </ProfileField>
              <ProfileField label="Learner Start Date">
                {format(new Date(user.createdAt), "MMMM' 'd', 'yyyy")}
              </ProfileField>
            </ProfileFieldGroup>

            <ProfileFieldGroup title="Your Subscription">
              <SubscriptionDetailsField
                subscription={subscription}
                setShowPaymentModal={() => setShowPaymentModal(true)}
              />
            </ProfileFieldGroup>
          </ProfileColumn>

          <ProfileColumn>
            <ProfileFieldGroup title="Billing">
              <UpcomingChargesField subscription={subscription} />

              <BillingFrequencyField subscription={subscription} />

              {subscription?.status !== "trialing" && (
                <BillingHistoryField periodStart={subscription?.periodStart} />
              )}

              <PaymentInformationField subscription={subscription} />

              <div>
                <Button
                  className="mt-10 text-sm sm:hidden"
                  type="link"
                  onClick={logout}
                >
                  Log out
                </Button>
              </div>
            </ProfileFieldGroup>
          </ProfileColumn>
        </ProfileSection>

        <CancelSubscriptionModal />

        <Modal
          visible={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          closeX
          bodyClassName="!p-0"
          widthClassName="max-w-full w-[1041px] transition-none"
          body={
            <HomepageSignupContextProvider
              plansRef={null}
              initialAccountType="learner"
            >
              <Payment
                title="Select a Plan to Renew Access"
                buttonText="Reactivate My Subscription"
                isRenewal
              />
            </HomepageSignupContextProvider>
          }
        />
      </CancellationContextProvider>
    </ProfilePage>
  );
};
