import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  REACT_APP_LEARNER_HOME_LINK,
  REACT_APP_LEARNER_LINK,
} from "../../utils";
import { useLearnerContext } from "../contexts/LearnerContext";

export function ProfileRedirect() {
  const navigate = useNavigate();
  const { learner } = useLearnerContext();

  useEffect(() => {
    if (!learner) {
      return;
    }
    if (learner.entitlements.includes("parent")) {
      navigate(`${REACT_APP_LEARNER_LINK}/parent/profile`);
    } else if (learner.entitlements.includes("learner")) {
      navigate(`${REACT_APP_LEARNER_LINK}/profile`);
    } else {
      navigate(REACT_APP_LEARNER_HOME_LINK);
    }
  }, [learner, navigate]);

  return null;
}
