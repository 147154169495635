import {
  colors,
  addLinearGradient,
  addLoseGameText,
  addWinGameText,
} from "./gameFunctions";
const { DeltaGraph, $, rand, d3 } = window;

const FEATURE_FLAG_KNOCK_DOWN = true;
const bubblesWide = 11;
const top = bubblesWide + 3;
const MIN_ANGLE = Math.PI / 12; // can't allow horizontal shots which would bounce forever
const MAX_ANGLE = Math.PI - MIN_ANGLE;
const VISIBLE_QUEUE = 2; // how many future bubbles can be seen
const BRUSH_PAST_THESHOLD = 0.8; // allow a .2 intersection of circles and let it brush past

/**
 * Adds inactive styles to game (overlay, updates border color)
 * @param graph DeltaGraph instance
 * @return Overlay ref
 */
function inactiveStyles(graph) {
  const xMargin = graph.invx(16) - graph.invx(0);
  const yMargin = graph.invy(16) - graph.invy(0);

  const { xmin, xmax, ymin, ymax } = graph.bounds;
  const overlay = graph.rect(
    xmin - xMargin,
    ymax - yMargin,
    xmax - xmin + xMargin * 2,
    ymax - ymin - yMargin * 2,
    {
      strokeWidth: 0,
      fill: colors["dm-brand-blue-800"],
      rx: 8,
      fillOpacity: 0.9,
    }
  );

  graph.style({
    "border-color": colors["dm-brand-blue-800"],
  });

  return overlay;
}

/**
 * Removes overlay and html with button / text
 * Changes the border color to be the active border
 * @param graph DeltaGraph instance
 * @param overlay Ref for rectangle overlay
 * @param htmlRef Ref for foreign object with html (including buttons, text, etc) on the inactive screen
 * @return void
 */
function activeStyles(graph, overlay, htmlRef) {
  if (overlay) overlay.remove();
  if (htmlRef) htmlRef.remove();

  graph.style({
    "border-color": colors["dm-brand-blue-500"],
  });
}

/** Adds a radial gradient for regular bubbles to the graph with the given ID
 * @param graph DeltaGraph instance
 * @param id string of id for gradient
 * @return void
 */
function addRadialGradient(graph, id) {
  const defs = graph.svg.append("defs");
  const radialGradient = defs.append("radialGradient").attr({
    id,
    cx: "40%",
    cy: "35%",
  });

  radialGradient.append("stop").attr({
    offset: "99%",
    "stop-color": colors["dm-brand-blue-100"],
  });

  radialGradient.append("stop").attr({
    offset: "99%",
    "stop-color": colors["dm-brand-blue-500"],
    "stop-opacity": "0.3",
  });
}

/** Generates instructions for the first two steps of the game
 * @param graph DeltaGraph instance
 * @param group group ref
 * @param center center for x
 * @param text1 text to display
 * @param text2 optional text to display
 * @return void
 */
function generateInstructions(graph, group, center, text1, text2) {
  const instructionSettings = {
    align: "mm",
    fontSize: "16px",
    class: "font-sans select-none",
    fill: colors.white,
    parent: group,
  };
  const firstInstruction1 = graph.text(center, 4, text1, instructionSettings);
  const firstInstruction2 = text2
    ? graph.text(center, 3.3, text2, instructionSettings)
    : undefined;
  const bbox = group.node().getBBox();
  const paddingX = 32;
  const paddingY = 16;
  graph.rect(
    graph.invx(bbox.x - paddingX),
    graph.invy(bbox.y - paddingY),
    graph.invx(bbox.width + 2 * paddingX) - graph.invx(0),
    graph.invy(0) - graph.invy(bbox.height + 2 * paddingY),
    {
      parent: group,
      fill: colors["dm-brand-blue-600"],
      stroke: colors["dm-brand-blue-600"],
      strokeWidth: 0,
      rx: text2 ? "48" : "28",
    }
  );
  // reorder the instructions to be painted on top of rectangle
  firstInstruction1.each(function () {
    this.parentNode.appendChild(this);
  });
  firstInstruction2?.each(function () {
    this.parentNode.appendChild(this);
  });
}

/**
 * Checks if two bubbles are touching
 * @param bubble1 First bubble ref
 * @param bubble2 Second bubble ref
 * @return Boolean
 */
function areBubblesTouching(bubble1, bubble2) {
  if (bubble1.j === bubble2.j && Math.abs(bubble1.i - bubble2.i) === 1) {
    return true; // horizontally adjacent
  }
  if (Math.abs(bubble1.j - bubble2.j) > 1) {
    return false; // two or more rows away
  }
  const evenIndex = bubble1.j % 2 === 0 ? bubble1.i : bubble2.i; // the horizontal position of the bubble in the even number row
  const oddIndex = bubble1.j % 2 === 1 ? bubble1.i : bubble2.i; // the horizontal position of the bubble in the odd number row
  if (oddIndex === evenIndex || oddIndex + 1 === evenIndex) {
    return true;
  }
  return false;
}

/**
 * Filters through the bubbleArray to find an array of contiguous bubbles for the target bubble
 * @param bubbleArray Array of all bubbles to blast
 * @param bubble Target bubble
 * @param factor Integer representing the factor of the target bubble
 * @return Array of all contiguous bubbles
 */
function getContiguous(bubbleArray, bubble, factor) {
  // factor is optional
  let allContiguous = [];
  let newFound = [bubble];
  while (newFound.length) {
    allContiguous = allContiguous.concat(newFound);
    let toSearch = newFound;
    newFound = [];
    for (const bubbleToSearch of toSearch) {
      const touchingBubbles = bubbleArray.filter(
        (bub) =>
          (!factor || factor % bub.factor === 0) &&
          areBubblesTouching(bub, bubbleToSearch)
      );
      for (const bub of touchingBubbles) {
        // push onto newFound only those not already on allContiguous or newFound
        if (
          !allContiguous.find(
            (myBub) => myBub.i === bub.i && myBub.j === bub.j
          ) &&
          !newFound.find((myBub) => myBub.i === bub.i && myBub.j === bub.j)
        ) {
          newFound.push(bub);
        }
      }
    }
  }
  return allContiguous;
}

/**
 * Sets up array of bubbles to shoot
 * @param graph DeltaGraph instance
 * @param numRows Integer representing the number of rows of bubbles
 * @return Array of objects, each object represents a bubble on the grid
 */
function generateBubbles(graph, numRows) {
  const bubbleArray = [];
  for (let j = 0; j < numRows; j++) {
    for (let i = 0; i < (j % 2 === 0 ? bubblesWide : bubblesWide - 1); i++) {
      let factor;
      do {
        factor = rand(2, 12);
      } while (factor === 11);
      const settledBubbleCenter = getSettledBubbleCenter(i, j);
      bubbleArray.push({
        factor,
        i, // x-position (always starting at zero)
        j, // y-position (j==0 is the top row)
        center: settledBubbleCenter,
        ...getTextAndBubbleRefs(graph, settledBubbleCenter, factor, "big"),
      });
    }
  }
  return bubbleArray;
}

/**
 * Generates an array with 2 elements, used to determine the coordinates of the center of the bubble
 * @param i X-coordinate of the bubble
 * @param j Y-coordinate of the bubble
 * @return 2 element array with the x-coordinate and y-coordinate of the center of the bubble
 */
function getSettledBubbleCenter(i, j) {
  return [i + (j % 2 === 0 ? 0 : 0.5), top - (j * Math.sqrt(3)) / 2];
}

/**
 * Creates circles and text for the bubbles, based on the center, the factor and the size of the bubble
 * @param graph DeltaGraph instance
 * @param center Number representing the calculated center of the bubble
 * @param factor Number representing the factor of the bubble
 * @param size String of either "big" or "small"
 * @return Object with the refs for the bubble and the text
 */
function getTextAndBubbleRefs(graph, center, factor, size) {
  return {
    bubbleRef: graph.circle(center[0], center[1], size === "big" ? 0.5 : 0.25, {
      strokeWidth: 2,
      fill: "url('#bubble-gradient')",
      stroke: colors["dm-brand-blue-500"],
    }),
    textRef: graph.text(center[0], center[1], factor, {
      fontSize: size === "big" ? "20px" : "10px",
      align: "mm",
      fill: colors["dm-brand-blue-500"],
      class: "font-sans select-none",
    }),
  };
}

/**
 * Generates the factor for the first bubble
 * @param bubbleArray Array of all bubbles to be blasted on the screen
 * @param numRows Integer of bubble rows
 * @return Integer representing factor for the first bubble
 */
function createFirstBubble(bubbleArray, numRows) {
  const bottomRow = bubbleArray.filter((bub) => bub.j === numRows - 1);
  let iterations = 0;
  let firstBubble; // so the first directions make sense and are doable, also ideally not just the same number 3 times
  do {
    firstBubble = rand(0, 1) ? rand(2, 6) : rand(2, 12);
    if (iterations > 100) firstBubble *= rand(2, 5);
    iterations++;
  } while (
    iterations < 200 &&
    bottomRow.filter(
      // This seems to work as-is
      // eslint-disable-next-line no-loop-func
      (bub) =>
        getContiguous(bubbleArray, bub, firstBubble).length >= 2 &&
        getContiguous(bubbleArray, bub, firstBubble).filter(
          (bub2) => bub2.factor !== firstBubble
        ).length >= 1 &&
        firstBubble % bub.factor === 0
    ).length === 0
  );
  return firstBubble;
}

/**
 * Generates the first shootable bubbles at the bottom of the screen
 * @param graph DeltaGraph instance
 * @param shootableBubble Ref to the bubble that is first in line to shoot or undefined if it is the initial bubble
 * @param shootableQueue Array of shootable bubbles
 * @param center Number representing the calculated center
 * @param firstBubble Number or undefined, the factor of the first bubble
 * @return Object with refs to the shootableBubble and shootableQueue
 * @
 */
function getFirstShootableBubbles(
  graph,
  shootableBubble,
  shootableQueue,
  center,
  firstBubble
) {
  let factor;
  if (firstBubble) {
    factor = firstBubble;
  } else {
    factor = findFactor();
    if (rand(1, 2) === 1) {
      factor *= rand(2, 5);
    }
  }

  const centerX = center - (firstBubble ? 0 : 1 + 0.75 * shootableQueue.length);
  const centerY = firstBubble ? 0 : -0.3;
  const bubble = {
    factor,
    i: -1, // placeholders
    j: -1,
    center: [centerX, centerY],
    ...getTextAndBubbleRefs(
      graph,
      [centerX, centerY],
      factor,
      firstBubble ? "big" : "small"
    ),
  };
  if (firstBubble) {
    // the first one should not be made small. this is the first setup
    shootableBubble = bubble;
  } else {
    shootableQueue.push(bubble);
  }

  return {
    shootableBubble,
    shootableQueue,
  };
}

function findFactor() {
  let factor;
  do {
    if (rand(1, 2) === 1) factor = rand(2, 6);
    else factor = rand(2, 12);
  } while (factor === 11);
  return factor;
}

/**
 * Sets up the initial board
 * @param graph DeltaGraph instance
 * @return Object 3 properties: the bubbleArray, the shootableQueue and the shootableBubble
 */
function bubbleBoardSetUp(graph) {
  const numRows = bubblesWide - 2;
  const bubbleArray = generateBubbles(graph, numRows);
  const firstBubble = createFirstBubble(bubbleArray, numRows);

  const center = bubblesWide / 2 - 0.5;
  let shootableQueue = [];
  let shootableBubble;
  for (let i = 0; i < VISIBLE_QUEUE + 1; i++) {
    ({ shootableBubble, shootableQueue } = getFirstShootableBubbles(
      graph,
      shootableBubble,
      shootableQueue,
      center,
      i === 0 ? firstBubble : undefined
    ));
  }

  return {
    bubbleArray,
    shootableQueue,
    shootableBubble,
  };
}

/** Runs the game
 * @param graph DeltaGraph instance
 * @param winFunc callback to execute upon winning the game
 * @param loseFunc callback to execute upon losing the game
 * @return void
 */
function runBubbleGame(
  graph,
  winFunc,
  loseFunc,
  bubbleArray,
  shootableQueue,
  shootableBubble
) {
  if (!graph) return;

  const isTouchDevice = window.is_touch_device(); // determines the event listeners
  if (isTouchDevice)
    document
      .getElementById("factor-bubble-game-canvas")
      .classList.add("touch-none");

  let firstTime = true; // give extra directions the first time
  const numRows = bubblesWide - 2;
  const center = bubblesWide / 2 - 0.5;

  // will be used to snap shootable bubble to a settled position
  const possibleLattices = {}; // keys are i_j, values are points [x,y] that COULD be a lattice point on this grid
  for (let j = 0; j < numRows * 2; j++) {
    // go twice as low just in case, possible lattices might not be ORIGINAL bubble lattices
    for (let i = 0; i < (j % 2 === 0 ? bubblesWide : bubblesWide - 1); i++) {
      possibleLattices[`${i}_${j}`] = getSettledBubbleCenter(i, j);
    }
  }

  function getNewShootableBubble() {
    let factor = findFactor();

    if (bubbleArray.length < 20 && rand(1, 3) === 1) {
      // hard at the end, help them by giving a higher than normal chance of matching the 1st, 2nd or 3rd highest value on the board
      const allFactors = bubbleArray.map((bub) => bub.factor).unique();
      allFactors.sort((a, b) => b - a);
      let iterations = 0;
      do {
        const myRand = rand(0, Math.max(2, allFactors.length - 1));
        factor = allFactors[myRand];
        iterations++;
      } while (
        iterations < 100 &&
        (!factor ||
          (shootableBubble && factor === shootableBubble.factor) ||
          // This seems to work as-is
          // eslint-disable-next-line no-loop-func
          shootableQueue.filter((bub) => bub.factor === factor).length)
      );
      if (iterations >= 100) {
        factor = rand(2, 6);
      }
    } else if (bubbleArray.length < 20 && rand(1, 2) === 1) {
      // helpful to get a match of a top bubble
      const topBubbles = bubbleArray.filter((bub) => bub.j === 0);
      const myRand = rand(0, topBubbles.length - 1);
      factor = topBubbles[myRand].factor;
    } else if (rand(1, 2) === 1) {
      factor *= rand(2, 5);
    }
    const centerX = center - (1 + 0.75 * shootableQueue.length);
    const centerY = -0.3;
    const bubble = {
      factor,
      i: -1, // placeholders
      j: -1,
      center: [centerX, centerY],
      ...getTextAndBubbleRefs(graph, [centerX, centerY], factor, "small"),
    };
    shootableQueue.push(bubble);

    if (shootableQueue.length > VISIBLE_QUEUE) {
      shootableBubble = shootableQueue.shift();
      shootableBubble.center = [center, 0];
      animatingQueue = true;
      const queueDuration = 1000;
      setTimeout(() => {
        animatingQueue = false;
      }, queueDuration);
      shootableBubble.bubbleRef
        .transition()
        .duration(queueDuration)
        .attr({
          cx: graph.getx(center),
          cy: graph.gety(0),
          r: graph.getx(0.5) - graph.getx(0),
        });
      shootableBubble.textRef
        .transition()
        .duration(queueDuration)
        .attr({ x: graph.getx(center), y: graph.gety(0), "font-size": "20px" });
      for (const bub of shootableQueue) {
        bub.center[0] += 0.75; // slide right .75
        bub.bubbleRef
          .transition()
          .duration(queueDuration)
          .attr({ cx: graph.getx(bub.center[0]) });
        bub.textRef
          .transition()
          .duration(queueDuration)
          .attr({ x: graph.getx(bub.center[0]) });
      }
    }
  }

  let firstInstruction = graph.group();
  const text1 = `Try shooting the ${shootableBubble.factor} upwards to make a`;
  const text2 = `streak of three or more factors of ${shootableBubble.factor}.`;
  generateInstructions(graph, firstInstruction, center, text1, text2);

  let shootingCurrently = false;
  let animatingCurrently = false;
  let animatingQueue = false;
  let shootingDirection;
  const shootFunction = function () {
    if (mouseX === undefined && mouseY === undefined) return; // prevents shoot without end location
    if (shootingCurrently || animatingCurrently || animatingQueue) return; // don't allow retrigger
    shootingDirection = Math.max(
      MIN_ANGLE,
      Math.min(MAX_ANGLE, graph.direction([center, 0], [mouseX, mouseY]))
    );
    shootingCurrently = true; // animation loop below handles the rest
    if (firstInstruction) firstInstruction.remove();
  };

  if (isTouchDevice) graph.svg.on("click", shootFunction);
  else graph.svg.on("mousedown", shootFunction);

  let mouseX, mouseY;
  const moveFunc = function () {
    mouseX = graph.invx(d3.mouse(this)[0]);
    mouseY = graph.invy(d3.mouse(this)[1]);
    if (mouseX < 0 || mouseX > bubblesWide || mouseY < 0 || mouseY > top) {
      mouseX = null;
      mouseY = null;
    }
  };
  if (isTouchDevice) graph.svg.on("touchmove", moveFunc);
  else graph.svg.on("mousemove", moveFunc);

  let allContiguousBubbles;
  const animationInterval = 6;
  const intermediarySteps = 40;
  const bubbleAimerSpace = 0.6;
  let dots;
  let toDrop = [];
  function animationLoop() {
    if (bubbleArray.length === 0) {
      gameWin();
      if (dots) dots.forEach((dot) => dot.remove());
      return;
    }
    const setRefCenters = (bub) => {
      const xpx = graph.getx(bub.center[0]);
      const ypx = graph.gety(bub.center[1]);
      bub.textRef.attr("x", xpx).attr("y", ypx);
      bub.bubbleRef.attr("cx", xpx).attr("cy", ypx);
    };

    if (shootingCurrently && shootableBubble) {
      // somehow this wasn't set
      shootableBubble.center = [
        shootableBubble.center[0] +
          (bubbleAimerSpace / 5) * Math.cos(shootingDirection),
        shootableBubble.center[1] +
          (bubbleAimerSpace / 5) * Math.sin(shootingDirection),
      ];
      if (
        shootableBubble.center[0] < graph.bounds.xmin ||
        shootableBubble.center[0] > graph.bounds.xmax
      ) {
        shootingDirection = Math.PI - shootingDirection;
      }

      setRefCenters(shootableBubble);

      if (
        shootableBubble.center[1] > top ||
        bubbleArray.find(
          (bubble) =>
            graph.distance(shootableBubble.center, bubble.center) <
            BRUSH_PAST_THESHOLD * 0.9
        )
      ) {
        shootingCurrently = false;
        // logic to round the center to the nearest lattice point
        let minDistance = Infinity;
        let minIndexes = [-1, -1]; // [i, j]
        for (const key in possibleLattices) {
          const indexes = key.split("_").map((index) => parseInt(index)); // '3_5' --> [3,5]
          if (
            bubbleArray.find(
              (bub) => bub.i === indexes[0] && bub.j === indexes[1]
            )
          )
            continue; // bubble can't settle on top of an existing bubble
          const thisDistance = graph.distance(
            shootableBubble.center,
            possibleLattices[key]
          );
          if (thisDistance < minDistance) {
            minDistance = thisDistance;
            minIndexes = indexes;
          }
        }
        if (minIndexes[1] >= 16) {
          gameLose();
          if (dots) dots.forEach((dot) => dot.remove());
          return;
        }
        shootableBubble.i = minIndexes[0];
        shootableBubble.j = minIndexes[1];
        shootableBubble.center = getSettledBubbleCenter(...minIndexes);
        setRefCenters(shootableBubble);
        bubbleArray.push(shootableBubble);

        // logic for checking all contiguous bubbles and if they match the factor
        allContiguousBubbles = getContiguous(
          bubbleArray,
          shootableBubble,
          shootableBubble.factor
        );

        if (allContiguousBubbles.length >= 3) {
          shootableBubble = null;
          allContiguousBubbles.forEach((bub) => {
            bub.bubbleRef.attr("fill", "url('#selected-bubble-gradient')");
            bub.textRef.attr("fill", colors.white);
          });
          animatingCurrently = true;

          if (FEATURE_FLAG_KNOCK_DOWN) {
            if (firstTime) {
              firstTime = false;
              firstInstruction = graph.group();
              generateInstructions(
                graph,
                firstInstruction,
                center,
                `Use your ${
                  isTouchDevice ? "finger" : "mouse"
                } to knock down the highlighted bubbles`
              );
            }

            const moveFunc = function () {
              if (firstInstruction) firstInstruction.remove();

              let touchedBubble;
              if (d3.event.type === "mousemove") {
                touchedBubble = allContiguousBubbles.find(function (bub) {
                  return bub.bubbleRef[0][0] === d3.event.target;
                });
              } else if (d3.event.type === "touchmove") {
                touchedBubble = allContiguousBubbles.find(function (bub) {
                  const targetTouches = d3.event.targetTouches;
                  for (let i = 0; i < targetTouches.length; i++) {
                    const touchedElement = document.elementFromPoint(
                      targetTouches.item(i).clientX,
                      targetTouches.item(i).clientY
                    );
                    if (bub.bubbleRef[0][0] === touchedElement) return true;
                  }
                  return false;
                });
              }

              if (touchedBubble) {
                if (touchedBubble.i < 0) return; // falling, just ignore
                touchedBubble.bubbleRef.remove();
                touchedBubble.textRef.remove();
                allContiguousBubbles.splice(
                  allContiguousBubbles.indexOf(touchedBubble),
                  1
                );
                bubbleArray.splice(bubbleArray.indexOf(touchedBubble), 1);
                bubbleArray.forEach((bub) => (bub.connected = false));
                bubbleArray
                  .filter((bub) => bub.j === 0)
                  .forEach((bub) => {
                    getContiguous(bubbleArray, bub).forEach(
                      (bub2) => (bub2.connected = true)
                    );
                  });

                // don't want any dupes on here
                bubbleArray
                  .filter((bub) => !bub.connected)
                  .forEach((bub) => {
                    if (toDrop.indexOf(bub) === -1) {
                      toDrop.push(bub);
                    }
                  });
                toDrop.forEach((bub) => {
                  bub.i = -1000;
                  bub.j = -2000;
                }); // should no longer be part of the contiguous conversation
                if (allContiguousBubbles.length === 0 && toDrop.length === 0) {
                  animatingCurrently = false;
                }
              }
            };
            if (isTouchDevice) {
              graph.svg.on("touchmove.knockdown", moveFunc);
            } else {
              allContiguousBubbles.forEach((bub) => {
                bub.bubbleRef.on("mousemove", moveFunc);
              });
            }
          } else {
            setTimeout(() => {
              for (const bub of allContiguousBubbles) {
                bubbleArray.splice(bubbleArray.indexOf(bub), 1);
                bub.bubbleRef.remove();
                bub.textRef.remove();
              }

              // drop bubbles not connected to top
              bubbleArray.forEach((bub) => (bub.connected = false));
              bubbleArray
                .filter((bub) => bub.j === 0)
                .forEach((bub) => {
                  getContiguous(bubbleArray, bub).forEach(
                    (bub2) => (bub2.connected = true)
                  );
                });

              toDrop = bubbleArray.filter((bub) => !bub.connected);
              if (toDrop.length === 0) {
                animatingCurrently = false;
              }
            }, 2000);
          }
        }
        getNewShootableBubble(
          graph,
          bubbleArray,
          shootableBubble,
          shootableQueue,
          center
        );
      }
    }

    if (toDrop.length) {
      let maxHeight = -Infinity;
      toDrop.forEach((bub) => {
        bub.center[1] -= bubbleAimerSpace / 5;
        bub.bubbleRef.attr("fill", "url('#bonus-bubble-gradient')");
        bub.textRef.attr("fill", colors.white);
        maxHeight = Math.max(maxHeight, bub.center[1]);
        setRefCenters(bub);
      });

      if (maxHeight < -1) {
        for (const bub of toDrop) {
          bubbleArray.splice(bubbleArray.indexOf(bub), 1);
          bub.bubbleRef.remove();
          bub.textRef.remove();
          if (FEATURE_FLAG_KNOCK_DOWN && allContiguousBubbles) {
            const contiguousIndex = allContiguousBubbles.indexOf(bub); // if it was dropped without being touched, should be removed here too
            if (contiguousIndex > -1) {
              allContiguousBubbles.splice(contiguousIndex, 1);
            }
          }
        }
        toDrop.length = 0;
        if (FEATURE_FLAG_KNOCK_DOWN) {
          // for this flag, only reset if allContiguousBubbles is empty
          if (allContiguousBubbles.length === 0) {
            animatingCurrently = false;
          }
        } else {
          animatingCurrently = false;
        }
      }
    }

    // the rest just draws the dots showing the path
    if (dots) dots.forEach((dot) => dot.remove());
    dots = [];
    if (!mouseX || animatingCurrently) return;
    let dir = Math.max(
      MIN_ANGLE,
      Math.min(MAX_ANGLE, graph.direction([center, 0], [mouseX, mouseY]))
    );
    let currentX =
      center +
      (offsetCounter / intermediarySteps) * bubbleAimerSpace * Math.cos(dir);
    let currentY =
      0 +
      (offsetCounter / intermediarySteps) * bubbleAimerSpace * Math.sin(dir);
    // todo: figure out how many iterations and make sure the LAST one is opacity .5 (or whatever looks good)

    let iters = 0;
    const biggest = 0.15;
    const smallest = 0.03;
    while (
      currentY < top &&
      iters < 100 &&
      !bubbleArray.find(
        // This seems to work as-is
        // eslint-disable-next-line no-loop-func
        (bubble) =>
          graph.distance([currentX, currentY], bubble.center) <
          BRUSH_PAST_THESHOLD
      )
    ) {
      iters++;
      const xToAdd = bubbleAimerSpace * Math.cos(dir);
      const yToAdd = bubbleAimerSpace * Math.sin(dir);
      currentX += xToAdd;
      currentY += yToAdd;
      if (currentX < graph.bounds.xmin || currentX > graph.bounds.xmax) {
        dir = Math.PI - dir;
        if (currentX < graph.bounds.xmin) {
          // balances out to give smooth effect
          const amountOut = graph.bounds.xmin - currentX;
          currentX = graph.bounds.xmin + amountOut;
        } else {
          const amountOut = currentX - graph.bounds.xmax;
          currentX = graph.bounds.xmax - amountOut;
        }
      }
      dots.push(
        graph.circle(
          currentX,
          currentY,
          shootingCurrently ? smallest : biggest,
          {
            fill: colors["dm-brand-blue-600"],
            stroke: colors["dm-brand-blue-600"],
            fillOpacity: 1,
            strokeWidth: 1,
            opacity: 1,
          }
        )
      );
    }

    const biggestPixels = graph.getx(biggest) - graph.getx(0);
    const smallestPixels = graph.getx(smallest) - graph.getx(0);
    const decreaseStep = (biggestPixels - smallestPixels) / dots.length;
    let radius = biggestPixels;
    if (dots.length) dots[dots.length - 1].attr("opacity", 0);
    if (!shootingCurrently) {
      for (let i = 0; i < dots.length; i++) {
        dots[i].attr("r", radius);
        radius -= decreaseStep;
      }
    }
  }

  const randValue = Math.floor(Math.random() * Math.pow(10, 15));
  const randCirc = graph.circle(-1000000, 0, 1);
  randCirc.attr("rand", randValue); // attach a rand to the dom to confirm if this global keydown should be off-ed

  let offsetCounter = 0;
  const bubbleAimerInterval = setInterval(() => {
    if (checkDomDestroyed()) return;
    offsetCounter = (offsetCounter + 1) % intermediarySteps;
    animationLoop();
  }, animationInterval);

  function checkDomDestroyed() {
    // "new problem hit" or otherwise game is gone. stop intervals and keydown events on the window
    if ($('circle[rand="' + randValue + '"]').length === 0) {
      if (bubbleAimerInterval) clearTimeout(bubbleAimerInterval);
      return true;
    }
    return false;
  }

  function gameWin() {
    // clear game screen
    if (bubbleAimerInterval) clearTimeout(bubbleAimerInterval);
    clearBubbles();

    addWinGameText(graph);
    winFunc();
  }

  function gameLose() {
    if (bubbleAimerInterval) clearTimeout(bubbleAimerInterval);

    loseFunc({});

    const overlay = inactiveStyles(graph);

    const { htmlRef, button } = addLoseGameText(graph);

    // event handler to restart the game
    button.click(() => {
      activeStyles(graph, overlay, htmlRef);
      clearBubbles();
      const { bubbleArray, shootableQueue, shootableBubble } =
        bubbleBoardSetUp(graph);
      // start the game
      runBubbleGame(
        graph,
        winFunc,
        bubbleArray,
        shootableQueue,
        shootableBubble
      );
    });
  }

  function clearBubbles() {
    bubbleArray.forEach((bub) => {
      bub.bubbleRef.remove();
      bub.textRef.remove();
    });
    shootableQueue.forEach((bub) => {
      bub.bubbleRef.remove();
      bub.textRef.remove();
    });
    shootableBubble?.bubbleRef.remove();
    shootableBubble?.textRef.remove();
  }
}

/** Script that executes the factor bubble game
 * Sets up the initial graph (this should only run once, even if the player plays again)
 * @param winFunc Function to execute upon win
 * @param loseFunc Function to execute upon losing
 * @return void
 */
export function factorBubbleScript(winFunc, loseFunc) {
  const graph = new DeltaGraph("factor-bubble-game-canvas", {
    axes: false,
    xmin: -0.5,
    xmax: bubblesWide - 0.5,
    ymin: -0.5,
    ymax: top + 0.5,
    xscl: 1,
    yscl: 1,
    width: 500,
    height: 500 * ((top + 1) / bubblesWide),
    wideContainer: true,
    margins: { left: 16, right: 16, top: 16, bottom: 16 },
    align: "center",
  });

  addRadialGradient(graph, "bubble-gradient");
  addLinearGradient(
    graph,
    "selected-bubble-gradient",
    colors["dm-brand-blue-500"],
    colors["dm-brand-blue-600"]
  );
  addLinearGradient(
    graph,
    "bonus-bubble-gradient",
    colors["alt-light-blue"],
    colors["alt-dark-blue"]
  );

  graph.style({
    "border-color": colors["dm-brand-blue-500"],
    "border-style": "solid",
    "border-width": 4,
    "border-radius": "1.5rem",
    "background-color": colors["dm-brand-blue-100"],
  });

  const gridSettings = { strokeWidth: 0.5, opacity: 0 };

  for (let i = 0; i <= bubblesWide; i++) {
    graph.line(i, Math.floor(top), i, 0, gridSettings);
  }

  for (let i = 0; i <= top; i++) {
    graph.line(0, i, bubblesWide, i, gridSettings);
  }

  const { bubbleArray, shootableQueue, shootableBubble } =
    bubbleBoardSetUp(graph);

  const overlay = inactiveStyles(graph);

  const titleHtml = `
    <h2 class="font-serif text-white text-xl text-center font-bold">
      Bubble Blast Game Rules:
    </h2>
  `;

  const rulesHtml = `
    <ul class="font-sans text-white text-base flex flex-col gap-3">
      <li>
        <strong>Goal:</strong> Blast all the bubbles before they reach the bottom! 
      </li><li>
        <strong>How to Play:</strong> Aim and shoot the numbered bubble to create a chain of 3 or more bubbles that are a factor of the bubble being launched.
      </li><li>
        <strong>Pro Tip:</strong> Plan your shots! Look for opportunities to create longer chains for maximum bubble-blasting power.

      </li>
    </ul>
  `;

  const buttonHtml = `<button id="startGame" class="font-sans text-sm font-bold bg-white max-w-full border rounded border-dm-charcoal-800 h-10">
    Start Game
  </button>`;

  const outerDivHtml = `<div class="flex flex-col gap-4 content-center max-w-xs">
    ${titleHtml}
    ${rulesHtml}
    ${buttonHtml}
  </div>`;

  const { ymin, ymax } = graph.bounds;
  const overlayDiv = graph.html(
    graph.invx((500 - 320) / 2), // width of graph minus width of div
    (ymin + ymax) * 0.8, // will need to be adjusted depending on copy
    outerDivHtml
  );

  // button handler to start the game
  $("#startGame").click((e) => {
    e.stopPropagation();
    activeStyles(graph, overlay, overlayDiv);
    runBubbleGame(
      graph,
      winFunc,
      loseFunc,
      bubbleArray,
      shootableQueue,
      shootableBubble
    );
  });
}
