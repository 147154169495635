import { useState } from "react";
import { useParentContext } from "../../contexts/ParentContext";
import { Learner, LearnerSubscriptionDetails } from "../../types";
import { QuantityChangeModal } from "./QuantityChangeModal";
import Button from "../../../student/components/generic/button";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useSubscription } from "../../utils/useSubscription";

export const ReactivateLink: React.FC<{
  subscription: LearnerSubscriptionDetails;
  learner: Learner;
}> = ({ subscription, learner }) => {
  const learnerContext = useLearnerContext();
  const { learners, fetchChildLearners } = useParentContext();
  const toastContext = useDeltaToastContext();
  const [showModal, setShowModal] = useState(false);
  const { refetchSubscription } = useSubscription();

  const { mutateAsync: reactivateMutation, isLoading: isReactivating } =
    useMutation(() => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/reactivate/child`,
        JSON.stringify({ learnerId: learner._id }),
        withJsonHeader()
      );
    });

  const reactivateLearner = async () => {
    try {
      await reactivateMutation();
    } catch (e: any) {
      toastContext.addToast({
        message:
          e.response.data.message ||
          `Failed to reactivate ${learner.first} ${learner.last}`,
        status: "Error",
      });
    }
    await Promise.all([
      fetchChildLearners(),
      refetchSubscription(),
      learnerContext.refetchLearner(),
    ]);
  };

  const handleReactivate = async () => {
    const activeLearners = learners.filter((l) =>
      ["active", "pending"].includes(getLearnerAccountStatus(l))
    );
    if (
      (learnerContext.learner?.subscriptionQuantity ?? 0) >
      activeLearners.length
    ) {
      await reactivateLearner();
    } else {
      setShowModal(true);
    }
  };

  const handleConfirm = async () => {
    await reactivateLearner();
    setShowModal(false);
  };

  return (
    <>
      <Button type="link" onClick={handleReactivate} isLoading={isReactivating}>
        Reactivate Learner
      </Button>
      <QuantityChangeModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        subscription={subscription}
        newQuantity={subscription.quantity + 1}
        title={`Reactivate ${learner.first} ${learner.last}`}
        confirmButtonText="Reactivate"
        onConfirm={handleConfirm}
      />
    </>
  );
};
