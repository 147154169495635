import { useEffect } from "react";
import { getFilePath } from "../../utils";

const Favicon = () => {
  const links = [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: getFilePath(`/deltie-apple-touch-icon.png`),
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: getFilePath(`/deltie-favicon-32x32.png`),
    },
    {
      rel: "manifest",
      href: getFilePath(`/deltie-site.webmanifest`),
    },
    {
      rel: "mask-icon",
      href: getFilePath(`/deltie-safari-pinned-tab.svg`),
      color: "#5bbad5",
    },
    {
      id: "deltie-favicon",
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: getFilePath(`/deltie-favicon-16x16.png`),
    },
  ];

  const elements = links.map((l) => {
    const link = document.createElement("link");
    link.rel = l.rel;
    if (l.type) {
      link.type = l.type;
    }
    if (l.id) {
      link.id = l.id;
    }
    link.href = l.href;

    return link;
  });

  useEffect(() => {
    const deltie = document.getElementById("deltie-favicon");
    if (!deltie) {
      if (document.head.childNodes)
        elements.map((el) => document.head.append(el));

      const meta1 = document.createElement("meta");
      meta1.name = "msapplication-TileColor";
      meta1.content = "#da532c";
      document.head.append(meta1);
    }
  }, [elements]);

  return <></>;
};

export default Favicon;
