import { noop } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import {
  FormattedLearnerSubscriptionPlan,
  Learner,
  LearnerSubscriptionDetails,
} from "../../types";
import { useSubscription } from "../../utils/useSubscription";
import { useQuery } from "react-query";
import { executeQuery } from "../../../utils";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import { useLearnerContext } from "../../contexts/LearnerContext";

const ReactivationContext = createContext<{
  isReactivateModalVisible: boolean;
  setIsReactivateModalVisible: (isVisible: boolean) => void;
  subscription: LearnerSubscriptionDetails | undefined;
  plans: FormattedLearnerSubscriptionPlan[];
  learnersToReactivate: Learner[];
  setLearnersToReactivate: (learners: Learner[]) => void;
}>({
  isReactivateModalVisible: false,
  setIsReactivateModalVisible: noop,
  subscription: undefined,
  plans: [],
  learnersToReactivate: [],
  setLearnersToReactivate: noop,
});

export function useReactivationContext() {
  const context = useContext(ReactivationContext);

  return {
    isReactivateModalVisible: context.isReactivateModalVisible,
    learnersToReactivate: context.learnersToReactivate,
    subscription: context.subscription,
    plans: context.plans,
    openReactivationModal: (learnersToReactivate: Learner[]) => {
      context.setIsReactivateModalVisible(true);
      context.setLearnersToReactivate(learnersToReactivate);
    },
    closeReactivationModal: () => {
      context.setIsReactivateModalVisible(false);
    },
  };
}

export const ReactivationContextProvider: React.FC = ({ children }) => {
  const { learner } = useLearnerContext();
  const { subscription, plan } = useSubscription();
  const [isReactivateModalVisible, setIsReactivateModalVisible] =
    useState(false);
  const [learnersToReactivate, setLearnersToReactivate] = useState<Learner[]>(
    []
  );
  const [plansToDisplay, setPlansToDisplay] = useState<
    FormattedLearnerSubscriptionPlan[]
  >([]);

  const { refetch: fetchNewPlans } = useQuery<{
    plans: FormattedLearnerSubscriptionPlan[];
  }>(
    "/payments/subscriptions/plans/list/published",
    () =>
      executeQuery({
        path: "/payments/subscriptions/plans/list/published",
      }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    if (isSubscriptionActive(learner) && plan) {
      setPlansToDisplay([plan]);
    } else {
      fetchNewPlans().then(({ data }) => {
        setPlansToDisplay(data?.plans ?? []);
      });
    }
  }, [fetchNewPlans, learner, plan]);

  return (
    <ReactivationContext.Provider
      value={{
        isReactivateModalVisible,
        setIsReactivateModalVisible,
        subscription,
        plans: plansToDisplay,
        learnersToReactivate,
        setLearnersToReactivate,
      }}
    >
      {children}
    </ReactivationContext.Provider>
  );
};
