import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { findIndex, clamp } from "lodash";
import {
  Skill,
  SubunitProgress,
  LearnerAssignmentSkillData,
} from "../../../types";
import PostQuizLanding from "./PostQuizLanding";
import SubunitsSolving from "../SubunitsSolving";
import PostQuizSummary from "./PostQuizSummary";
import { ComponentTimer } from "../../../utils/useComponentTimer";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";

type Props = {
  skills: Skill[];
  courseId: string;
  coursePath?: string;
  unitId: string;
  unitPath?: string;
  subunitId: string;
  subunitPath?: string;
  subunitProgress: SubunitProgress | undefined;
  testTimer?: ComponentTimer;
  estimatedTime: number;
};

const PostQuiz = (props: Props) => {
  const { indexOfSkill } = useParams();
  const nav = useNavigate();

  const skillGroup = props.subunitProgress?.postQuiz?.assignment?.skills || [];
  const skillsCount = props.skills?.length || 0;

  const getPageState = () => {
    if (!props.subunitProgress?.postQuiz) {
      return "notInitialized";
    } else if (props.subunitProgress?.postQuiz?.assignment?.submitted) {
      return "finished";
    } else {
      return "solving";
    }
  };

  const pageState = getPageState();

  const firstUnsolved = clamp(
    findIndex(
      skillGroup,
      (skill: LearnerAssignmentSkillData) => !skill.skillComplete
    ),
    0,
    skillsCount - 1
  );

  const preQuizComplete =
    !!props.subunitProgress?.skippedPreQuiz ||
    props.subunitProgress?.preQuiz?.progress === 1;

  const prequiz100 = props.subunitProgress?.preQuiz?.maxGrade === 1;

  const practiceComplete = props.subunitProgress?.practice?.progress === 1;

  const firstPostQuizAttempt =
    !props.subunitProgress?.postQuiz?.assignment?.resultHist;

  const baseUrl = `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/${props.subunitPath}`;

  // This will handle to make sure that we don't see the default landing page if we already initialize
  // It will also make sure users are not navigating to questions without initializing the post-quiz first
  useEffect(() => {
    if (!firstPostQuizAttempt) {
      return;
    } else if (!preQuizComplete) {
      nav(`${baseUrl}/prequiz`, { replace: true });
    } else if (!practiceComplete) {
      nav(`${baseUrl}/practice`, { replace: true });
    } else if (pageState === "solving" && !indexOfSkill) {
      nav(`${baseUrl}/postquiz/${firstUnsolved}`, { replace: true });
    } else if (pageState === "notInitialized" && indexOfSkill) {
      nav(`${baseUrl}/postquiz`, { replace: true });
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, indexOfSkill]);

  if (pageState === "notInitialized" || !props.subunitProgress) {
    return (
      <>
        <PostQuizLanding
          {...props}
          skillsCount={skillsCount}
          prequiz100={prequiz100}
        />
      </>
    );
  } else if (
    !firstPostQuizAttempt &&
    indexOfSkill === undefined &&
    props.subunitProgress?.postQuiz?.assignment
  ) {
    return (
      <>
        <PostQuizSummary
          assignment={props.subunitProgress.postQuiz.assignment}
          courseId={props.courseId}
          unitId={props.unitId}
          subunitId={props.subunitId}
        />
      </>
    );
  } else if (
    (pageState === "solving" ||
      (pageState === "finished" && indexOfSkill !== undefined)) &&
    props.subunitProgress?.postQuiz?.assignment
  ) {
    return (
      <>
        <SubunitsSolving
          assignment={props.subunitProgress.postQuiz.assignment}
          skills={props.skills}
          testTimer={props.testTimer}
        />
      </>
    );
  } else if (props.subunitProgress?.postQuiz?.assignment) {
    return (
      <>
        <PostQuizSummary
          assignment={props.subunitProgress.postQuiz.assignment}
          courseId={props.courseId}
          unitId={props.unitId}
          subunitId={props.subunitId}
        />
      </>
    );
  }
  return <></>;
};

export default PostQuiz;
