import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { REACT_APP_ADMIN_LINK, formatNumber, useDMQuery } from "../../utils";
import InviteAdmins from "./school/InviteAdmins";
import CurrentAdmins from "./school/CurrentAdmins";
import InviteTeachers from "./school/InviteTeachers";
import SchoolTabs from "./school/SchoolTabs";
import CurrentTeachers from "./school/CurrentTeachers";
import { DemoMode } from "../../utils/demo";
import InfoIcon from "../../shared/icons/InfoIcon";
import SchoolDetailsTooltip from "./school/SchoolDetailsTooltip";
import { getAdminParams } from "../utils";
import { ExclamationIcon } from "@heroicons/react/outline";

export default function Index({ demoMode }: { demoMode: boolean }) {
  const navigate = useNavigate();
  const params = useParams();
  const { account_type } = getAdminParams();

  const [school, setSchool] = useState<{
    _id?: string;
    schoolName: string;
    schoolid: string;
    dmLicense?: any;
    lowGrade?: string;
    highGrade?: string;
    district?: {
      districtID?: string;
      dmLicense?: Record<string, string | number | Array<string>>;
    };
    timezone?: string;
    coveredByDistrictLicense?: boolean;
  }>({ schoolName: "", schoolid: "" });
  const [teachers, setTeachers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [pendingTeacherInvites, setPendingTeacherInvites] = useState([]);

  const [teacherDataForTable, setTeacherDataForTable] = useState();

  const [isDistrictAdmin, setIsDistrictAdmin] = useState<boolean>(
    ["district", "super_district"].includes(account_type)
  );

  const [showGradeBanner, setShowGradeBanner] = useState(false);

  const { data: pendingInvitesData } = useDMQuery({
    path: "/admin_new/data/pendingInvites",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: teacherData, isLoading: teacherDataisLoading } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: schoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  //This useQuery is never actually invoked from this component (not enabled, refetch not called here) but the "districtData" is fetched in App and Index components, so that cached data is available here as "districtData". Possible TODO is to refactor to pass "districtData" as a prop here and elsewhere, which might be more declarative and easier to follow.
  const { data: districtData } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false, //see note above
    },
  });

  const { data: teacherCountsData } = useDMQuery({
    path: "/admin_new/data/teacherCounts",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: adminsData } = useDMQuery({
    path: "/admin_new/data/admins",
    cacheKey: "admin-data",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  // format teacherData to provide "accessor" key for each column
  useEffect(() => {
    if (teacherData && schoolData && teacherCountsData) {
      const school = schoolData.find(
        (school: { _id: string; schoolid: string }) =>
          school.schoolid === params.school_id
      );
      if (school && teacherCountsData) {
        const newData = teacherData
          .filter(
            (teacher: { schoolPlus: string[]; _id: number; admin: string }) =>
              teacher.schoolPlus.includes(school.schoolid) &&
              (!teacher.admin ||
                (teacher.admin &&
                  parseInt(getStudentCount(teacher._id).toString(), 10) > 0))
          )
          .map((teacher: any) => ({
            teacher: {
              id: teacher.teachercode,
              first: teacher.first,
              last: teacher.last,
            },
            schoolPlus: teacher.schoolPlus,
            last_login: teacher.last_login,
            assignments: getAssignmentsCount(teacher._id),
            num_students: getStudentCount(teacher._id),
            annual_logs: teacher.annual_logs,
            showDeleteButton: teacher.showDeleteButton,
          }));

        setTeacherDataForTable(newData);
      }
    }
  }, [teachers, schoolData, teacherCountsData, params?.school_id, teacherData]); // use off teachers so its reset when url is change (see other useEffect)

  useEffect(() => {
    setAdmins([]);
    if (adminsData) {
      const results = adminsData.filter(
        (a: { account_type: string; schoolIDs: string[] }) =>
          params.school_id && a.schoolIDs.includes(params.school_id)
      );
      results.forEach((a: { account_type: string }, index: number) => {
        if (a.account_type !== "super_school") {
          results[index]["showDeleteButton"] = true;
        }
      });
      setAdmins(results);
    }
  }, [adminsData, params]);

  useEffect(() => {
    if (pendingInvitesData && school) {
      const pendingTeacherInvites = pendingInvitesData.filter(
        (teacher: { schools: string[] }) =>
          teacher.schools.includes(school.schoolid)
      );
      setPendingTeacherInvites(pendingTeacherInvites);
    } else {
      setPendingTeacherInvites([]);
    }
  }, [pendingInvitesData, school]);

  useEffect(() => {
    if (schoolData && teacherData && teacherCountsData) {
      const school = schoolData.find(
        (school: { _id: string; schoolid: string }) =>
          school.schoolid === params.school_id
      );
      if (school) {
        if (districtData?.dmLicense?.expiration) {
          setSchool((prev) => ({
            ...prev,
            ...school,
            district: {
              ...prev.district,
              dmLicense: districtData.dmLicense,
            },
          }));
        } else {
          setSchool((prev) => ({
            ...prev,
            ...school,
          }));
        }
        const teachers = teacherData.filter(
          (teacher: { schoolPlus: string[]; _id: number; admin: string }) =>
            teacher.schoolPlus.includes(school.schoolid) &&
            (!teacher.admin ||
              (teacher.admin &&
                parseInt(getStudentCount(teacher._id).toString(), 10) > 0))
        );
        setTeachers(teachers);
        if (teachers) {
          // No Teachers, default to "Invite Teachers"
          if (
            typeof params.selected_tab === "undefined" &&
            teacherData &&
            teachers
          ) {
            if (teachers && teachers.length === 0) {
              navigate(
                `${REACT_APP_ADMIN_LINK}/schools/${params.school_id}/invite-teachers`,
                {
                  replace: true,
                }
              );
            } else {
              navigate(
                `${REACT_APP_ADMIN_LINK}/schools/${params.school_id}/current-teachers`,
                {
                  replace: true,
                }
              );
            }
          }
        }
      }
    }
  }, [
    schoolData,
    teacherData,
    teacherCountsData,
    params?.school_id,
    params?.selected_tab,
    districtData,
  ]);

  //track whether the current user is a district admin
  useEffect(() => {
    setIsDistrictAdmin(["district", "super_district"].includes(account_type));
  }, [account_type]);

  useEffect(() => {
    setShowGradeBanner(
      isDistrictAdmin &&
        parseInt(school?.lowGrade || "", 10) < 9 &&
        parseInt(school?.highGrade || "", 10) > 9 &&
        districtData?.dmLicense?.license_type === "9-12"
    );
  }, [
    isDistrictAdmin,
    school?.lowGrade,
    school?.highGrade,
    districtData?.dmLicense?.license_type,
  ]);

  function getStudentCount(teacher_id: number) {
    return teacherCountsData &&
      typeof teacherCountsData[teacher_id]?.students != "undefined"
      ? formatNumber(parseInt(teacherCountsData[teacher_id]?.students))
      : 0;
  }

  function getAssignmentsCount(teacher_id: number) {
    return teacherCountsData &&
      typeof teacherCountsData[teacher_id]?.assignments != "undefined"
      ? formatNumber(parseInt(teacherCountsData[teacher_id]?.assignments))
      : 0;
  }

  return (
    <>
      {school && schoolData && (
        <>
          <div id="header" className="border border-b-2 bg-white">
            {showGradeBanner && (
              <div className="flex">
                <div className="mx-4 my-2 inline-flex h-20 grow items-start justify-start gap-2.5 rounded-lg border border-dm-warning-800 bg-dm-warning-100 p-4">
                  <ExclamationIcon
                    className="w-6 self-start text-dm-warning-800"
                    aria-label="Warning"
                  />
                  <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                    <div className="self-stretch font-['Lato'] text-base font-bold leading-normal text-zinc-900">
                      Your license only covers students in grades 9-12. Students
                      outside that grade band are not licensed, and their
                      teachers should not be added/invited, FYI.
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-between px-6 py-8">
              <h2 className="font-serif text-[24px] font-bold">
                <DemoMode
                  value={school.schoolName}
                  type="school_name"
                  district_size={schoolData.length}
                />
              </h2>

              <SchoolDetailsTooltip
                teachers={teachers}
                school={school}
                districtData={districtData}
                schoolData={schoolData}
                teacherCountsData={teacherCountsData}
              >
                <span className="mt-2 text-right text-xs sm:pr-6">
                  <InfoIcon classes="mr-1 mb-1" />
                  School Details
                </span>
              </SchoolDetailsTooltip>
            </div>

            {teachers && pendingTeacherInvites && admins && (
              <SchoolTabs
                pendingTeacherInvites={pendingTeacherInvites}
                teachers={teachers}
                admins={admins}
              />
            )}
          </div>
          <div id="body" className="p-6">
            {params.selected_tab === "current-teachers" && (
              <CurrentTeachers
                teachers={teachers}
                teacherDataForTable={teacherDataForTable}
                teacherDataisLoading={teacherDataisLoading}
              />
            )}
            {params.selected_tab === "invite-teachers" && (
              <>
                {pendingTeacherInvites && (
                  <InviteTeachers
                    school={school}
                    pendingTeacherInvites={pendingTeacherInvites}
                  />
                )}
              </>
            )}
            {params.selected_tab === "current-admins" && (
              <CurrentAdmins
                admins={admins}
                demoMode={demoMode}
                school_id={params.school_id}
                partOfDistrict={
                  (school?.coveredByDistrictLicense && //from this endpoint, coveredByDistrictLicense only tells us whether the dmLicense object on the district would cover the school. Need to explicitly check the expiration date to see if the license is still valid. Open question whether the check should just be run from Node
                    (school?.district?.dmLicense?.expiration as number) *
                      1000 >=
                      Date.now()) ||
                  !school?.dmLicense?.license_type
                    ?.toLowerCase()
                    ?.match(/school/)
                }
              />
            )}
            {params.selected_tab === "invite-admins" && (
              <>
                {params.school_id && (
                  <InviteAdmins school_id={params.school_id} />
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
