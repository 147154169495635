import React, { useState, useLayoutEffect } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { useScrollLock } from "usehooks-ts";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useDMQuery } from "../../utils";
import { UPGRADE_FEATURES } from "../constants";
import { CheckoutStatus } from "../types";
import VideoModal from "../../learner/components/Modals/VideoModal";
import FeatureBlock from "../components/FeatureBlock";
import { EXPIRATION_OCTOBER_2025, deltamathAPI } from "../../manager/utils";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import { getFilePath } from "../../utils";
import clsx from "clsx";
import { Feature } from "../components/Feature";

const PlusFeatures: React.FC = () => {
  const toastContext = useDeltaToastContext();
  const { lock: lockScreen, unlock: unlockScreen } = useScrollLock({
    autoLock: false,
  });
  const { data: accountInfoData } = useDMQuery({
    path: "/teacher/account/info",
    queryOptions: {
      refetchOnReconnect: true,
    },
  });

  const { data: priceData, status: priceStatus } = useDMQuery({
    path: "/payments/checkout/prices",
    queryOptions: {
      refetchOnReconnect: true,
    },
  });

  //TODO: checking if page is embedded in Angular iframe
  const browserWindow = window.self === window.top ? window : window.parent;

  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (showSpinner) {
      lockScreen();
    } else {
      unlockScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSpinner]);

  const { mutate: checkoutStatusMutate } = useMutation(
    () => {
      return axios.post<CheckoutStatus>(
        `${deltamathAPI()}/payments/checkout/status`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess(data) {
        if (data.data.kind === "success") {
          setIsWaitingForStripe(false);
          setShowSpinner(false);
          toastContext.addToast({
            title: "Success!",
            message: `Your payment was successful. You now have access to DeltaMath ${
              data.data.hasIntegral ? "INTEGRAL" : "PLUS"
            }.`,
            status: "Success",
          });
        } else if (data.data.kind === "error") {
          setIsWaitingForStripe(false);
          setShowSpinner(false);
          toastContext.addToast({
            title: "Error",
            message: "There was an error with processing your payment.",
            status: "Error",
            dismiss: "manual",
          });
        } else {
          setShowSpinner(true);
          setTimeout(() => {
            checkoutStatusMutate();
          }, 1000);
        }
      },
    }
  );

  const [isWaitingForStripe, setIsWaitingForStripe] = useState<boolean>(
    browserWindow.location.search.includes("paid")
  );

  useLayoutEffect(() => {
    if (isWaitingForStripe) {
      checkoutStatusMutate();
      browserWindow.history.replaceState(
        null,
        "",
        browserWindow.location.href.split("?")[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaitingForStripe]);

  const expirationPretty = accountInfoData?.license?.expires
    ? new Date(accountInfoData.license.expires * 1000).toLocaleDateString(
        "en-US",
        {
          dateStyle: "medium",
        }
      )
    : undefined;

  const isExpired =
    accountInfoData?.license?.expires !== undefined
      ? accountInfoData.license.expires * 1000 - new Date().getTime() < 0
      : undefined;

  const subscriptionType: "none" | "school" | "individual" | undefined =
    accountInfoData?.license?.operative_subscription_type;

  // show both purchase options
  const showPlusUpgrade =
    accountInfoData?.license?.has_longest_license === false;

  // show only Integral upgrade purchase option
  const showIntegralUpgrade =
    subscriptionType === "individual" &&
    accountInfoData?.license?.has_longest_license === true &&
    accountInfoData?.license?.plus_active === true &&
    accountInfoData?.license?.integral_active === false;

  // Only for teachers on a school PLUS license, allow them to upgrade to an individual INTEGRAL
  const canUpgradeIndividualToIntegral =
    subscriptionType === "school" &&
    accountInfoData?.license?.plus_active === true &&
    accountInfoData?.license?.integral_active === false;

  const hideAllUpgrades =
    subscriptionType === "school" &&
    accountInfoData?.license?.plus_active === true &&
    !accountInfoData?.license?.non_renewal_confirmed &&
    !canUpgradeIndividualToIntegral;

  const isExtendCopy =
    accountInfoData?.license?.has_longest_license === false &&
    accountInfoData?.license?.plus_active === true;

  const currentDate = new Date();
  let DISCOUNT_DATE = null;
  let AFTER_DISCOUNT_DATE = false;
  let PRORATED_DISCOUNT = 0;
  let SHOW_UPCOMING_DISCOUNT_MESSAGE = false;
  let DISCOUNT_DATE_PRETTY = "";
  let productKeys = {
    PLUS: "",
    INTEGRAL: "",
    INTEGRAL_UPGRADE: "",
    INTEGRAL_FROM_SITE_PLUS: "",
  };

  if (priceStatus === "success" && priceData) {
    DISCOUNT_DATE = new Date(priceData.discountDate);
    (AFTER_DISCOUNT_DATE = currentDate >= DISCOUNT_DATE),
      (SHOW_UPCOMING_DISCOUNT_MESSAGE = currentDate < DISCOUNT_DATE);
    DISCOUNT_DATE_PRETTY = DISCOUNT_DATE.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    const proratedSuffix = AFTER_DISCOUNT_DATE ? "_prorated" : "";
    productKeys = {
      PLUS: `oct2025_plus${proratedSuffix}`,
      INTEGRAL: `oct2025_integral${proratedSuffix}`,
      INTEGRAL_UPGRADE: `oct2025_integral_upgrade${proratedSuffix}`,
      INTEGRAL_FROM_SITE_PLUS: "oct2025_integral_from_site_prorated",
    };

    PRORATED_DISCOUNT = priceData.proratedDiscount;
  }

  const getPrice = (
    productKey: string,
    type: "discounted" | "full" = "full"
  ) => {
    // For full price, remove _prorated suffix except for INTEGRAL_FROM_SITE_PLUS
    const finalProductKey =
      type === "full" && productKey !== productKeys.INTEGRAL_FROM_SITE_PLUS
        ? productKey.replace("_prorated", "")
        : productKey;

    // Get price in dollars from cents, or return 0 if not found
    return priceData.priceData[finalProductKey]?.unit_amount / 100 || 0;
  };

  const [isFeatureListOpen, setIsFeatureListOpen] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [videoFile, setVideoFile] = useState<string>("");

  //TODO: added max width, top and side padding to main div
  //      b/c this will be shown in an Angular iframe.
  //
  //      Remove when not using Angular anymore.
  return (
    <>
      <div className="m-auto h-auto w-full max-w-[1280px] px-4 pt-4 md:px-8 md:pt-12">
        <div className="inline-flex w-full items-start justify-start gap-2.5 rounded-lg bg-blue-100 p-10">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
            <div className="font-serif text-2xl font-bold leading-10 text-neutral-900">
              Your Subscription
            </div>
            {accountInfoData && (
              <div className="font-sans text-base font-normal text-neutral-700">
                {accountInfoData?.license?.plus_active === false && (
                  <p>
                    You have a free DeltaMath account. Upgrade below for more
                    exclusive features to DeltaMath!
                  </p>
                )}
                {subscriptionType === "individual" &&
                  accountInfoData?.license?.expires !== 0 &&
                  isExpired && (
                    <p className="pt-2">
                      Your individual{" "}
                      <strong>
                        {accountInfoData?.teacher?.has_integral === true
                          ? "INTEGRAL"
                          : "PLUS"}
                      </strong>{" "}
                      subscription expired on{" "}
                      <span className="font-bold">{expirationPretty}</span>
                    </p>
                  )}
                {subscriptionType === "school" &&
                  accountInfoData?.license?.expires !== 0 &&
                  isExpired && (
                    <p className="pt-2">
                      Your site license expired on{" "}
                      <span className="font-bold">{expirationPretty}</span>
                    </p>
                  )}
                {accountInfoData.license.plus_active === true && (
                  <>
                    {accountInfoData.license.integral_active === false && (
                      <span className="rounded-sm bg-dm-brand-blue-200 px-2 py-0.5 font-sans text-lg tracking-wide text-black">
                        <strong>Delta</strong>Math PLUS
                      </span>
                    )}

                    {accountInfoData.license.integral_active === true && (
                      <span className="rounded-sm bg-dm-earth-yellow-500 px-2 py-0.5 font-sans text-lg tracking-wide text-black">
                        <strong>Delta</strong>Math INTEGRAL
                      </span>
                    )}

                    {expirationPretty && (
                      <p className="pt-2">
                        You have an active{" "}
                        {subscriptionType === "individual"
                          ? "individual subscription "
                          : subscriptionType === "school"
                          ? "site license "
                          : "subscription "}
                        until{" "}
                        <span className="font-bold">{expirationPretty}</span>
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {priceStatus === "success" &&
        priceData &&
        !hideAllUpgrades &&
        (showPlusUpgrade ||
          showIntegralUpgrade ||
          canUpgradeIndividualToIntegral) ? (
          <>
            <div className="mb-6 mt-12 text-center font-serif text-2xl font-bold leading-10 text-neutral-900">
              {isExtendCopy ? "Extend " : "Upgrade "}Your Subscription Today
              {PRORATED_DISCOUNT > 0 && (
                <div className="py-1 text-center font-sans text-base font-normal">
                  {SHOW_UPCOMING_DISCOUNT_MESSAGE ? (
                    <span className="text-dm-gray-600">
                      Subscriptions will have a{" "}
                      <span className="text-dm-success-500 underline">
                        prorated {PRORATED_DISCOUNT}% discount
                      </span>{" "}
                      starting {DISCOUNT_DATE_PRETTY}
                    </span>
                  ) : (
                    AFTER_DISCOUNT_DATE && (
                      <span className="text-dm-gray-600">
                        The prices below reflect a{" "}
                        <span className="text-dm-success-500 underline">
                          prorated {PRORATED_DISCOUNT}% discount
                        </span>
                      </span>
                    )
                  )}
                </div>
              )}
            </div>

            <div
              className={clsx(
                showPlusUpgrade && "m-auto grid gap-6 md:grid-cols-2"
              )}
            >
              {showPlusUpgrade && (
                <FeatureBlock
                  type="plus"
                  fullPrice={getPrice(productKeys.PLUS, "full")}
                  {...(AFTER_DISCOUNT_DATE &&
                    getPrice(productKeys.PLUS, "full") !==
                      getPrice(productKeys.PLUS, "discounted") && {
                      discountedPrice: getPrice(productKeys.PLUS, "discounted"),
                    })}
                  expiration={EXPIRATION_OCTOBER_2025}
                  isOpen={isFeatureListOpen}
                  setIsOpen={setIsFeatureListOpen}
                  productKey={productKeys.PLUS}
                />
              )}

              {/* Upgrading to Integral */}
              {!canUpgradeIndividualToIntegral && showIntegralUpgrade && (
                <FeatureBlock
                  type="integral"
                  fullPrice={getPrice(productKeys.INTEGRAL_UPGRADE, "full")}
                  {...(AFTER_DISCOUNT_DATE &&
                    getPrice(productKeys.INTEGRAL_UPGRADE, "full") !==
                      getPrice(productKeys.INTEGRAL_UPGRADE, "discounted") && {
                      discountedPrice: getPrice(
                        productKeys.INTEGRAL_UPGRADE,
                        "discounted"
                      ),
                    })}
                  expiration={EXPIRATION_OCTOBER_2025}
                  twoColumnDisplay={true}
                  isOpen={isFeatureListOpen}
                  setIsOpen={setIsFeatureListOpen}
                  productKey={productKeys.INTEGRAL_UPGRADE}
                />
              )}

              {/* Regular Integral Purchase */}
              {!canUpgradeIndividualToIntegral && !showIntegralUpgrade && (
                <FeatureBlock
                  type="integral"
                  fullPrice={getPrice(productKeys.INTEGRAL, "full")}
                  {...(AFTER_DISCOUNT_DATE &&
                    getPrice(productKeys.INTEGRAL, "full") !==
                      getPrice(productKeys.INTEGRAL, "discounted") && {
                      discountedPrice: getPrice(
                        productKeys.INTEGRAL,
                        "discounted"
                      ),
                    })}
                  expiration={EXPIRATION_OCTOBER_2025}
                  twoColumnDisplay={false}
                  isOpen={isFeatureListOpen}
                  setIsOpen={setIsFeatureListOpen}
                  productKey={productKeys.INTEGRAL}
                />
              )}

              {canUpgradeIndividualToIntegral && AFTER_DISCOUNT_DATE && (
                <>
                  <FeatureBlock
                    type="integral"
                    fullPrice={getPrice(
                      productKeys.INTEGRAL_FROM_SITE_PLUS,
                      "full"
                    )}
                    expiration={EXPIRATION_OCTOBER_2025}
                    twoColumnDisplay={true}
                    isOpen={isFeatureListOpen}
                    setIsOpen={setIsFeatureListOpen}
                    productKey={productKeys.INTEGRAL_FROM_SITE_PLUS}
                  />
                </>
              )}
            </div>

            <p className="pb-6 pt-4 text-center font-sans text-xs font-normal leading-none text-neutral-700">
              All payments are processed securely with{" "}
              <img
                className="inline"
                width="40"
                src={getFilePath("/images/stripelogo.svg")}
                alt="Stripe Logo"
              />
            </p>
          </>
        ) : null}

        <div className="mt-1.5 text-center">
          <div className="inline-flex items-start justify-start gap-4">
            <div className="flex items-center justify-center gap-2 border-r border-slate-400 pl-2 pr-4">
              <a
                className="font-sans text-sm font-normal leading-tight text-dm-brand-blue-500"
                href="/contact"
                target="_blank"
              >
                Contact Us
              </a>
            </div>
            <a
              className="font-sans text-sm font-normal leading-tight text-dm-brand-blue-500"
              href="/teachers-schools?createQuote=true"
              target="_blank"
            >
              Get a Quote for Your School/District
            </a>
          </div>
        </div>

        <div className="mt-24 inline-flex w-full flex-col items-center justify-start gap-12">
          <div className="font-serif text-2xl font-bold leading-10">
            Plus & Integral Features We Love{" "}
          </div>
          <div className="flex flex-col items-start justify-start gap-10">
            {UPGRADE_FEATURES.map((feature) => (
              <Feature
                key={feature.title}
                title={feature.title}
                description={feature.description}
                image={feature.image}
                plus={feature.plus}
                integral={feature.integral}
                videoSlug={feature.videoSlug || ""}
                videoTime={feature.videoTime || ""}
                helpCenterURL={feature.helpCenterURL || ""}
                imageFirst={feature.imageFirst || false}
                setVideoFile={setVideoFile}
                setShowVideo={setShowVideo}
              />
            ))}
          </div>
        </div>
      </div>
      <VideoModal
        visible={showVideo}
        onClose={() => setShowVideo(false)}
        skillCode={videoFile}
        public={true}
      />
      {showSpinner && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <DmLoadingSpinner
            className="pointer-events-none select-none rounded-lg border border-transparent bg-white px-2 py-1 text-sm font-medium text-black shadow-sm"
            spinnerColor="text-black"
            message="Processing Payment..."
          />
        </div>
      )}
    </>
  );
};

export default PlusFeatures;
