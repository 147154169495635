import clsx from "clsx";

export const FeatureImage = ({
  image,
  title,
  imageFirst,
}: {
  image: string;
  title: string;
  imageFirst?: boolean;
}) => {
  return (
    <img
      className={clsx(
        "shrink-0 rounded-lg border border-slate-200 object-cover object-top max-md:max-h-96 max-md:w-full max-sm:max-h-72 md:h-60 md:w-[464px] lg:h-72 lg:w-[556px]",
        !imageFirst && "md:order-1"
      )}
      src={image}
      alt={`Screenshot of ${title}`}
    />
  );
};
