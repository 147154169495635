import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { myCourseDataType } from "../../../types";
import SidebarWrapper from "../SidebarWrapper";
import MyCourses from "./MyCourses";
import { useCourseContext } from "../../../contexts/CourseContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { SkeletonCircle, SkeletonText } from "../../Skeleton";
import DashboardButton from "./DashboardButton";
import AllCourseButton from "./AllCourseButton";
import { useEffect } from "react";

const FAKE_LINKS = [
  "Et reprehenderit proident",
  "Eiusmod ipsum",
  "In enim deserunt",
  "Aliqua aliqua laborum",
  "Aliquip nostrud",
  "Lorem consectetur mollit dolore",
];

export default function Sidebar(): JSX.Element {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(coursePath);
  const allCoursesData = courseContext.getAllCoursesData();

  const learnerContext = useLearnerContext();

  const currentCourses = courseContext.getCurrentLearnerCourses(
    courseData?.id,
    learnerContext
  );

  useEffect(() => {
    if (learnerContext.isSidebarMinimized) {
      ReactTooltip.rebuild();
    }
  }, [learnerContext.isSidebarMinimized]);

  return (
    <SidebarWrapper showCalculator={false} upperSection={<></>}>
      <div className="flex flex-col gap-2 pb-4">
        <DashboardButton />
        <div className="sidebar-inner-scroll flex flex-col gap-2 overflow-auto">
          {allCoursesData.length
            ? currentCourses
                .filter((x) => !x.archived)
                .map((course: myCourseDataType) => (
                  <MyCourses
                    key={`mycourse-${course?.id}`}
                    id={course?.id}
                    name={course?.name}
                    progress={course?.progress}
                    units={course?.units}
                  />
                ))
            : FAKE_LINKS.map((link) => (
                <div className="p-2" key={link}>
                  <div className="flex gap-2">
                    <div className="h-6 w-6">
                      <SkeletonCircle />
                    </div>
                    <SkeletonText>{link}</SkeletonText>
                  </div>
                </div>
              ))}
        </div>
        <AllCourseButton />
      </div>

      <ReactTooltip
        id="collapsed-nav-tooltip"
        className="!px-2 !py-1 !text-xs"
        effect="solid"
        place="right"
      />
    </SidebarWrapper>
  );
}
