import { REACT_APP_LEARNER_HOME_LINK } from "..";
import { useLearnerAnalytics } from "../../learner/analytics/useLearnerAnalytics";
import { useLearnerContext } from "../../learner/contexts/LearnerContext";
import { useResetExperiments } from "../../learner/utils/experiments/useResetExperiments";

export function useLogout() {
  const learnerContext = useLearnerContext();
  const { reset: resetAnalytics } = useLearnerAnalytics();
  const resetExperiments = useResetExperiments();

  return async () => {
    learnerContext.logoutLearner();
    resetAnalytics();
    await resetExperiments();
    window.location.href = REACT_APP_LEARNER_HOME_LINK;
  };
}
