import { getFilePath } from "../utils";

export const UPGRADE_FEATURES: {
  title: string;
  description: JSX.Element;
  image?: string;
  plus: boolean;
  integral: boolean;
  videoSlug?: string;
  videoTime?: string;
  helpCenterURL: string;
  imageFirst?: boolean;
}[] = [
  {
    title: "Print To PDF",
    description: (
      <>
        Print to PDF, available with <strong>INTEGRAL</strong>, allows teachers
        to export any DeltaMath assignment to PDF. An intuitive print preview
        interface allows teachers to customize the assignment to support student
        needs in the classroom, with full solutions available to students via a
        QR code.
      </>
    ),
    image: getFilePath("/images/features/print-pdf.png"),
    plus: false,
    integral: true,
    videoSlug: "feature_Print_to_PDF",
    videoTime: "0:32",
    helpCenterURL: "https://help.deltamath.com/assignments/print-to-pdf",
    imageFirst: false,
  },
  {
    title: "Schoology/Canvas Integrations",
    description: (
      <>
        With <strong>INTEGRAL</strong>, teachers and students can launch into
        DeltaMath from Canvas or Schoology. Our integration easily creates
        classes based on your LMS using on-the-fly rostering. You can use
        DeltaMath as an “External Tool” to allow for grades to pass back into
        the LMS gradebook.
      </>
    ),
    image: getFilePath("/images/features/canvas-integrations.png"),
    plus: false,
    integral: true,
    videoSlug: "CanvasSchoology",
    videoTime: "0:35",
    helpCenterURL: "https://help.deltamath.com",
    imageFirst: true,
  },
  {
    title: "Upload Student Work",
    description: (
      <>
        This <strong>INTEGRAL</strong> feature serves both as a cheat-prevention
        tool and an opportunity for teachers and admins to take a deeper dive on
        student misconceptions. Students upload one or more images from their
        computer or phone to their assignments. Teachers can then download a PDF
        of student work for each assignment and/or export all student work in a
        single file.
      </>
    ),
    image: getFilePath("/images/features/upload_student_work.png"),
    plus: false,
    integral: true,
    videoSlug: "NewGatsbyClipUploadStudentWork",
    videoTime: "0:28",
    helpCenterURL:
      "https://help.deltamath.com/assignments/assignment-overview-settings#student-work-upload-integral-11",
    imageFirst: false,
  },
  {
    title: "Google Classroom Integration",
    description: (
      <>
        This integration allows teachers to sync courses from Google Classroom
        to automatically roster students in DeltaMath. Assignments created in
        DeltaMath can be posted to Google Classroom automatically and grades
        pass back from DeltaMath into the Google Classroom gradebook.
      </>
    ),
    image: getFilePath("/images/features/google-integration.png"),
    plus: true,
    integral: true,
    videoSlug: "GoogleClassroomMarketing",
    videoTime: "0:32",
    helpCenterURL:
      "https://help.deltamath.com/en_US/getting-started-gc/set-up-gc-integration",
    imageFirst: true,
  },
  {
    title: "Create Your Own Problem",
    description: (
      <>
        Customize assignments by creating your own questions. Answers can be
        multiple choice, free response, guided sentence, or paragraph form.
      </>
    ),
    image: getFilePath("/images/features/create_your_own_problem.png"),
    plus: true,
    integral: true,
    videoSlug: "NewGatsbyClipCreateYourOwnProblem",
    videoTime: "0:36",
    helpCenterURL:
      "https://help.deltamath.com/en_US/create-your-own-problems/intro-to-create-your-own-problems",
    imageFirst: false,
  },
  {
    title: "Standard Maps",
    description: (
      <>
        DeltaMath skills are aligned to state standards for the US and Canada,
        including AP (Calculus and Precalculus). Teachers can sort skills by
        standards when creating assignments, tests, or test corrections to
        quickly find appropriate skills to match their curriculum.
      </>
    ),
    image: getFilePath("/images/features/standard_maps.png"),
    plus: true,
    integral: true,
    videoSlug: "StandardMaps-v2",
    videoTime: "0:18",
    helpCenterURL:
      "https://help.deltamath.com/getting-started/content-overview#search-by-standard-plus-integral-3",
    imageFirst: true,
  },
  {
    title: "Instructional Videos",
    description: (
      <>
        DeltaMath has targeted instructional videos for all of our 1900+ skills,
        allowing students to access instruction or remediation anytime,
        anywhere. Additionally, teachers can assign videos to students and see
        what portion of a video each student has watched.
      </>
    ),
    image: getFilePath("/images/features/videos.png"),
    plus: true,
    integral: true,
    videoSlug: "NewGatsbyClipVideos",
    videoTime: "0:26",
    helpCenterURL:
      "https://help.deltamath.com/assignments/assignment-overview-settings#deltamath-videos-plus-integral-12",
    imageFirst: false,
  },
  {
    title: "Problem Types",
    description: (
      <>
        The majority of DeltaMath skills are further categorized into problem
        types which allows teachers to assign distinct sections of a skill to
        align with classroom instruction.
      </>
    ),
    image: getFilePath("/images/features/problem_types.png"),
    plus: true,
    integral: true,
    videoSlug: "feature_Problem_Subtypes",
    videoTime: "0:20",
    helpCenterURL:
      "https://help.deltamath.com/assignments/add-skills-to-an-assignment#assign-problem-types-plus-integral-1",
    imageFirst: true,
  },
  {
    title: "Tests/Test Corrections",
    description: (
      <>
        Teachers can create tests by mixing and matching DeltaMath skills,
        assigning problem subtypes or specific questions, and creating problems
        of their own. During a test, teachers can restrict student activity on
        the DeltaMath student page and can use passcodes to lock down test
        access. Following the test, teachers can automatically create test
        corrections, with each student receiving a targeted, individualized
        review assignment based on their results.
      </>
    ),
    image: getFilePath("/images/features/automated_test_corrections.png"),
    plus: true,
    integral: true,
    videoSlug: "NewGatsbyClipCreateTestsandCorrections",
    videoTime: "0:38",
    helpCenterURL:
      "https://help.deltamath.com/en_US/tests/introduction-to-tests",
    imageFirst: false,
  },

  {
    title: "Coteachers",
    description: (
      <>
        Teachers on our premium licenses can add or in turn be added to other
        teachers&rsquo; sections as coteachers.
      </>
    ),
    plus: true,
    integral: true,
    helpCenterURL:
      "https://help.deltamath.com/getting-started/class-settings#manage-coteachers-plus-integral-6",
  },
  {
    title: "Folder/File System",
    description: (
      <>
        Teachers using our premium licenses have access to a folder/file system,
        allowing for greater organizational flexibility within teacher accounts.
      </>
    ),
    plus: true,
    integral: true,
    helpCenterURL:
      "https://help.deltamath.com/assignments/assignment-overview-settings#folder-plus-integral-5",
  },
  {
    title: "Assign YouTube Videos",
    description: (
      <>
        Videos from YouTube can be assigned and viewed within a DeltaMath
        assignment, and they can count as part of the assignment grade.
      </>
    ),
    plus: true,
    integral: true,
    helpCenterURL:
      "https://help.deltamath.com/assignments/add-skills-to-an-assignment#assign-youtube-videos-plus-integral-6",
  },
  {
    title: "Max Problems",
    description: (
      <>
        Teachers can limit the total questions a student can complete within a
        skill by setting a maximum number of problems.
      </>
    ),
    plus: true,
    integral: true,
    helpCenterURL:
      "https://help.deltamath.com/assignments/assignment-skill-settings#max-problems-plus-integral-4",
  },
  {
    title: "Passcodes For Tests",
    description: (
      <>
        Teachers have the option to secure their test with a passcode. This
        prevents students from entering the test if they are absent or not
        testing with their class, as well as prevent students from sharing login
        information.
      </>
    ),
    plus: true,
    integral: true,
    helpCenterURL:
      "https://help.deltamath.com/tests/unique-test-settings#secure-with-passcode-plus-integral-5",
  },
  {
    title: "Assign This Problem",
    description: (
      <>
        Teachers can assign the exact same question to their students in a
        DeltaMath assignment.
      </>
    ),
    plus: true,
    integral: true,
    helpCenterURL:
      "https://help.deltamath.com/assignments/add-skills-to-an-assignment#assign-this-problem-plus-integral-2",
  },
  {
    title: "Assign To Individuals Or Groups",
    description: (
      <>
        Teachers can create an assignment for an individual student or a group
        of students.
      </>
    ),
    plus: true,
    integral: true,
    helpCenterURL:
      "https://help.deltamath.com/assignments/assignment-overview-settings#assign-to-individuals-or-groupsplus-integral-3",
  },
  {
    title: "Extend Due Date",
    description: (
      <>
        Teachers can give individual students an extension on any assignment.
        You can set how many days and hours after the original due date you
        would like this student’s assignment to be due.
      </>
    ),
    plus: true,
    integral: true,
    helpCenterURL:
      "https://help.deltamath.com/data/student-data-view#give-an-extension-plus-integral-8",
  },
];
