import { AnswerScripts, Problem } from "../../types";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { MathField, useFocusContext } from "../../contexts/FocusContext";
import { SubmitAnswerButton } from "./SubmitAnswerButton";
const MQ = (window as any).MQ;

type Props = {
  problem: Problem;
  problemData: any;
  isMathQuillLoaded: boolean;
  handleSubmit: (
    answer: string[] | object,
    checkAnswerScript?: {
      correct?: number | undefined;
      messages?: string[] | undefined;
    },
    customMessage?: string,
    prob?: Problem
  ) => void;
  className: string;
  isCheckAnswerLoading: boolean;
};

const CustomAnswer = (props: Props) => {
  const focusContext = useFocusContext();
  const [answerScripts, setAnswerScripts] = useState<AnswerScripts>();
  const submitContainerRef = useRef<HTMLSpanElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const answerForm = props.problem?.htmlTemplates
    ? props.problem?.htmlTemplates[
        props.problem?.data?.answerFormTemplate
          ? props.problem?.data?.answerFormTemplate.slice(0, -5)
          : props.problem?.skillcode + "_AnswerForm"
      ]
    : undefined;

  const submit = () => {
    const stuAnswer = generateAnswers(containerRef);

    if (answerScripts) {
      const customAnsPreview = answerScripts.previewAnswer(stuAnswer);

      // This if statement is currently never running :)
      if (answerScripts.packageAnswer !== undefined) {
        const packagedAnswer = answerScripts.packageAnswer(stuAnswer);
        props.handleSubmit(packagedAnswer);
      } else if (customAnsPreview !== false && customAnsPreview !== undefined) {
        const customCheckAnsResult = answerScripts.checkAnswer(stuAnswer);
        const processedAnswer = processAnswer(customCheckAnsResult);
        props.handleSubmit(
          stuAnswer,
          processedAnswer,
          customAnsPreview !== undefined ? `${customAnsPreview}` : undefined
        );
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      if (
        props.problem.problemScripts?.answerScripts &&
        !answerScripts &&
        props.isMathQuillLoaded
      ) {
        const scripts = props.problem.problemScripts.answerScripts(
          containerRef.current
        );
        setAnswerScripts(scripts);
      }

      const mqElements = document.querySelectorAll(".mathquill-editable");
      if (mqElements.length) focusContext.setMQ(mqElements[0]);
    }
    if (!submitContainerRef.current) {
      const submitTagEls = containerRef?.current
        ? containerRef?.current.getElementsByTagName("submit-button")
        : null;
      if (submitTagEls && submitTagEls.length) {
        const btn = submitTagEls.item(0); // there should only be one submit button in the innerAnswerForm
        if (btn) {
          const parent = document.createElement("span");
          btn.after(parent);
          btn.remove();
          submitContainerRef.current = parent;
        }
      }
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerScripts, props.isMathQuillLoaded]);

  /* useEffect to add event listeners to common buttons in custom problems */
  useEffect(() => {
    const commonBtns = document.querySelectorAll("button.common-button");
    const commonBtnClick = (btnLatex: string, mq: MathField | null) => {
      if (!mq) return;
      // replace all parentheses and square brackets with correct latex
      btnLatex = btnLatex
        .replaceAll("(", "\\left(")
        .replaceAll(")", "\\right)")
        .replaceAll("[", "\\left[")
        .replaceAll("]", "\\right]");
      // account for special buttons
      if (btnLatex.includes(",")) {
        // such as [,] for interval notation
        mq.write(btnLatex).keystroke("Left Left");
      } else if (btnLatex.includes("cuberoot")) {
        mq.cmd("nthroot")
          .typedText("3")
          .keystroke("Tab")
          .keystroke("Left Right");
      } else if (btnLatex.includes("nthroot")) {
        mq.cmd("nthroot").keystroke("Left Right");
      } else if (btnLatex.includes("logbase")) {
        mq.write("log_{}").keystroke("Tab").keystroke("Left");
      } else if (btnLatex.includes("sqrt")) {
        if (btnLatex === "\\sqrt{}") btnLatex = "sqrt";
        mq.cmd(btnLatex);
      } else {
        // all other latex
        mq.write(btnLatex);
      }
      mq.focus();
    };
    const eventListenerMap = new Map();
    if (commonBtns.length) {
      commonBtns.forEach((btn: Element) => {
        const quill = btn.attributes.getNamedItem("quill")?.value;
        const btnLatex = quill ? quill : (btn as HTMLElement).innerText;
        const btnEventListener = () =>
          commonBtnClick(btnLatex, focusContext.state);
        eventListenerMap.set(btn, btnEventListener);
        btn.addEventListener("click", btnEventListener);
      });
    }
    return () => {
      eventListenerMap.forEach(
        (evListenerFunc: () => void, element: Element) => {
          element.removeEventListener("click", evListenerFunc);
        }
      );
    };
  }, [focusContext.state]);

  useEventListenerHandler({
    isMfeLoaded: props.isMathQuillLoaded,
    ansFuncs: answerScripts,
    containerRef,
    handleSubmit: submit,
  });

  return (
    <>
      <div className={`answerArea display-problem ${props.className}`}>
        {answerForm ? (
          <div id="innerAnswerForm" ref={containerRef}>
            <div
              className="row"
              dangerouslySetInnerHTML={{ __html: answerForm }}
            ></div>
          </div>
        ) : null}
      </div>
      {submitContainerRef.current !== null &&
        createPortal(
          <SubmitAnswerButton
            submit={submit}
            isCheckAnswerLoading={props.isCheckAnswerLoading}
          />,
          submitContainerRef.current
        )}
    </>
  );
};

/* Custom Hook to Add an Event Listener to MathQuill / Input Elements */
function useEventListenerHandler({
  isMfeLoaded,
  ansFuncs,
  containerRef,
  handleSubmit,
}: {
  isMfeLoaded: boolean;
  ansFuncs: any;
  containerRef: React.RefObject<HTMLDivElement> | null;
  handleSubmit: () => void;
}) {
  useEffect(() => {
    let inputEls: NodeListOf<HTMLInputElement> | undefined;
    let keyEventListener: (e: KeyboardEvent) => void;

    if (ansFuncs && isMfeLoaded) {
      const ansFormEl = containerRef?.current;

      /* Assign an event listener for input boxes */
      inputEls = ansFormEl?.querySelectorAll("input");
      keyEventListener = (e: KeyboardEvent) => {
        if (e.code === "Enter") handleSubmit();
      };
      inputEls?.forEach((inputEl) => {
        inputEl.addEventListener("keyup", keyEventListener);
      });

      /* Assign an event handler for MathQuill boxes */
      const mqEls = ansFormEl?.querySelectorAll(".mathquill-editable");
      mqEls?.forEach((el: any) => {
        const mq = MQ(el);
        const currentFuncs = mq?.__options?.handlers?.fns || {};
        /* Combine current functions with an enter handler for submission */
        mq?.config({
          handlers: {
            ...currentFuncs,
            enter: (mq: any) => {
              if (currentFuncs.enter) currentFuncs.enter(mq);
              handleSubmit();
            },
          },
        });
      });
    }

    return () => {
      inputEls?.forEach((inputEl) => {
        inputEl.removeEventListener("keyup", keyEventListener);
      });
    };
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMfeLoaded, ansFuncs]);
}

/* **************** */
/* Helper Functions */
/* **************** */

/* Generates and returns an object with the ids of all answer form elements as keys, and the corresponding values (if applicable) as values */
const generateAnswers = (ref: React.RefObject<HTMLDivElement> | null) => {
  const ansObj: any = {}; // TS TODO
  const ansFormEl = ref?.current;
  if (ansFormEl) {
    const elList = ansFormEl.querySelectorAll("[id]");
    elList.forEach((node) => {
      let value = "";
      if (node.classList.contains("mathquill-editable")) {
        const mq = MQ(node);
        if (mq) {
          value = mq
            .latex()
            .replace(/−/g, "-")
            .replace(/[^\x00-\x7F]/g, ""); // eslint-disable-line no-control-regex
        }
      } else if (node.tagName === "INPUT") {
        const inputNode = node as HTMLInputElement;
        value = inputNode.value.replace(/−/g, "-");
      } else {
        const possValue = node.attributes.getNamedItem("value")?.value;
        if (possValue !== undefined) value = possValue;
        else {
          const testNode = node as any; // TS TODO
          if (testNode.value !== undefined) value = testNode.value;
        }
      }
      ansObj[node.id] = value;
    });
  }
  return ansObj;
};

/* Processes the answer object returned by checkAnswer to the metaData obj required for checkAnswer endpoint */
const processAnswer = (
  answer:
    | number
    | boolean
    | { correct: boolean; messages?: string[]; message?: string }
    | { equal: boolean; messages?: string[]; message?: string }
) => {
  const processedAnswer: { correct?: number; messages?: string[] } = {};
  if (typeof answer === "boolean") {
    processedAnswer.correct = answer ? 1 : 0;
  } else if (typeof answer === "number") {
    processedAnswer.correct = answer;
  } else {
    if ("equal" in answer) {
      processedAnswer.correct = answer.equal ? 1 : 0;
    } else {
      processedAnswer.correct = answer.correct ? 1 : 0;
    }
    let messages: string[] = [];
    if (answer.message !== undefined) messages.push(answer.message);
    else if (answer.messages !== undefined)
      messages = messages.concat(answer.messages);
    processedAnswer.messages = messages;
  }
  return processedAnswer;
};

export default CustomAnswer;
