import { Skeleton } from "../../Skeleton";
import { formatPrice } from "../../../utils/formatPrice";
import { PriceCard } from "./PriceCard";
import { useHomepageSignupContext } from "../HomepageSignupContext";
import { calculateAnnualPlanDiscount } from "./calculateAnnualPlanDiscount";
import { PricingQuantityInput } from "./PricingQuantityInput";

export const PricingConfigurator: React.FC<{
  incrementorType: "blue" | "white";
  accountType: "parent" | "learner" | undefined;
}> = ({ incrementorType, accountType }) => {
  const { plans, numLearners, setNumLearners, selectedPlan, showPromoPrice } =
    useHomepageSignupContext();

  if (!plans) {
    return (
      <div className="h-96 w-full">
        <Skeleton />
      </div>
    );
  }

  const [monthlyPlan, annualPlan] = plans;
  const discount = calculateAnnualPlanDiscount(monthlyPlan, annualPlan);

  return (
    <>
      {(!accountType || accountType === "parent") && (
        <PricingQuantityInput
          numLearners={numLearners}
          onChange={(n) => setNumLearners(n)}
          incrementorType={incrementorType}
          title="How Many Learners?"
          subtext={
            <>
              Additional Learners:{" "}
              {showPromoPrice ? (
                selectedPlan?.interval === "monthly" ? (
                  <>
                    <span className="line-through">
                      {formatPrice({
                        value: 6.95,
                        currency: monthlyPlan.additionalLearnerPrice.currency,
                      })}
                      /mo
                    </span>{" "}
                    <strong>
                      {formatPrice(monthlyPlan.additionalLearnerPrice)}/mo
                    </strong>
                  </>
                ) : (
                  <>
                    <span className="line-through">
                      {formatPrice({
                        value: 65,
                        currency: annualPlan.additionalLearnerPrice.currency,
                      })}
                      /yr
                    </span>{" "}
                    <strong>
                      {formatPrice(annualPlan.additionalLearnerPrice)}/yr
                    </strong>
                  </>
                )
              ) : selectedPlan?.interval === "monthly" ? (
                `${formatPrice(monthlyPlan.additionalLearnerPrice)}/mo`
              ) : (
                `${formatPrice(annualPlan.additionalLearnerPrice)}/yr`
              )}{" "}
              each
            </>
          }
        />
      )}
      <div className="flex flex-col gap-4 lg:flex-row">
        <PriceCard title="Monthly Plan" plan={monthlyPlan} type="month" />
        <PriceCard
          title="Annual Plan"
          plan={annualPlan}
          type="year"
          discount={discount}
        />
      </div>
    </>
  );
};
