import { useCallback, useState } from "react";
import Modal from "../../../student/components/generic/Modal";
import { SkillResult } from "./useCourseSearchResults";
import {
  useCreateAssignmentMutation,
  useSkipPreQuizMutation,
} from "../../utils";
import { useParams } from "react-router-dom";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { skipPreQuizEvent } from "../../analytics/events";

interface SearchConfirmationModalProps {
  result: SkillResult | null;
  courseId: string | undefined;
  onClose: () => void;
}

export const SearchConfirmationModal: React.FC<
  SearchConfirmationModalProps
> = ({ result, courseId, onClose }) => {
  const { coursePath } = useParams();
  const skipPreQuizMutation = useSkipPreQuizMutation();
  const createAssignmentMutation = useCreateAssignmentMutation();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { track, getAssignmentData } = useLearnerAnalytics();

  const startPreQuizText = "Start Pre-Quiz";
  const skipPreQuizText = "Skip Pre-Quiz";

  const startPreQuiz = useCallback(async () => {
    if (result && courseId) {
      setButtonsDisabled(true);
      try {
        await createAssignmentMutation(
          "preQuiz",
          courseId,
          result.unitId,
          result.subunitId
        );
        window.open(
          `${REACT_APP_LEARNER_LINK}/${coursePath}/${result.unitPath}/${result.subunitPath}/prequiz`,
          "_blank"
        );
      } catch (e) {
        // No special handling
      }
      setButtonsDisabled(false);
      onClose();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, courseId]);

  const skipPreQuiz = useCallback(async () => {
    if (result && courseId) {
      setButtonsDisabled(true);
      try {
        await skipPreQuizMutation(courseId, result.subunitId);

        track(
          skipPreQuizEvent({
            ...getAssignmentData(
              "preQuiz",
              courseId,
              result.unitId,
              result.subunitId
            ),
            numCompleted: 0,
          })
        );

        window.open(
          `${REACT_APP_LEARNER_LINK}/${coursePath}/${
            result.unitPath ?? result.unitId
          }/${result.subunitPath}/practice/${result.skillIndex}`,
          "_blank"
        );
      } catch (e) {
        // No special handling
      }
      setButtonsDisabled(false);
      onClose();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, courseId]);

  return (
    <Modal
      visible={result !== null}
      onClose={onClose}
      title={
        <>
          <ResponsiveImage
            className="mx-auto h-auto w-56"
            srcs={[
              getFilePath(
                "/images/learner/prequiz/start-prequiz-from-search.png"
              ),
              getFilePath(
                "/images/learner/prequiz/start-prequiz-from-search@2x.png"
              ),
            ]}
            alt=""
            aria-hidden="true"
          />
          <span className="mt-4 block font-serif text-2xl font-bold">
            Get started with {result?.skillName}!
          </span>
        </>
      }
      body={
        <span className="text-sm">
          Take the pre-quiz for Unit {result?.unitIndex}, Section{" "}
          {result?.subunitIndex} to get personalized practice questions, or jump
          right in and start practicing. The choice is yours!
        </span>
      }
      secondaryButtonText={skipPreQuizText}
      secondaryAriaLabel={skipPreQuizText}
      secondaryDisabled={buttonsDisabled}
      secondaryOnClick={skipPreQuiz}
      confirmButtonText={startPreQuizText}
      confirmAriaLabel={startPreQuizText}
      confirmDisabled={buttonsDisabled}
      onConfirm={startPreQuiz}
    />
  );
};
