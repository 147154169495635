import { useEffect, useState } from "react";
import { useDMQuery } from "../../../utils";
import { ExampleProblemResponse, Problem } from "../../types";
import { obfuscate } from "../../../student/utils";
import CustomFileWrapper from "../Problem/CustomFileWrapper";
import Button from "../../../student/components/generic/button";
import axios from "axios";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import { ModuleInfo } from "../../../manager/components/courseSubunit/SkillBox";
import Select from "react-select";
import { compact } from "lodash";
import PortalModal from "../../../shared/PortalModal";

type Props = {
  visible: boolean;
  onClose: () => void;
  skillCode: string;
  assignmentId?: string;
  toggleQuestion?: boolean;
  noAnalytics?: boolean;
};

const ExampleModal = (props: Props) => {
  const [problems, setProblems] = useState<Problem[]>([]);
  const [renderKey, setRerenderKey] = useState<number>(0);
  const [totalExamples, setTotalExamples] = useState<number>(-1);
  const [showAnswer, setShowAnswer] = useState<boolean>(true);
  const [typesSelected, setTypesSelected] = useState<string[]>([]);

  const { data: skillData, refetch: skillRefetch } = useDMQuery<ModuleInfo>({
    path: `/manager_new/skill-codes/module_info/${props.skillCode}`,
    queryOptions: {
      enabled: false,
    },
  });

  const getExampleProblems = useMutation(
    (body: string) => {
      return axios.post<ExampleProblemResponse>(
        `${deltamathAPI()}/common/problem/example/${props.skillCode}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess(data) {
        if (
          problems.filter((x) => x.skillcode === props.skillCode).length <= 0
        ) {
          setRerenderKey(0);
        }
        setProblems([
          ...problems.filter(
            (x) =>
              x.skillcode === props.skillCode &&
              data.data.numExamples === totalExamples
          ),
          ...data.data.problems.map((p) => {
            return {
              ...p,
              lines: p.lines || [],
              data:
                typeof p.data === "string"
                  ? obfuscate(`${p._id}`).reveal(`${p.data}`)
                  : p.data,
            };
          }),
        ]);
        setTotalExamples(data.data.numExamples);
      },
    }
  );

  useEffect(() => {
    if (props.skillCode && props.toggleQuestion) {
      fetchProblems();
      skillRefetch();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.toggleQuestion, props.skillCode]);

  const fetchProblems = (selectedTypes?: string[]) => {
    if (props.visible) {
      const body = {
        range: [0, 5],
        ...(selectedTypes && selectedTypes.length > 0
          ? { typesSelected: selectedTypes }
          : undefined),
      };
      getExampleProblems.mutate(JSON.stringify(body));
    }
  };

  useEffect(() => {
    if (problems.length === 0) {
      setRerenderKey(0);
      fetchProblems();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const changeProblem = (direction: "next" | "previous") => {
    if (direction === "next") {
      if (
        problems.length - 1 === renderKey &&
        totalExamples > problems.length
      ) {
        const body = {
          range: [5, totalExamples],
          ...(typesSelected && typesSelected.length > 0
            ? { typesSelected: typesSelected }
            : undefined),
        };
        getExampleProblems.mutate(JSON.stringify(body));
        setRerenderKey(renderKey + 1);
      } else {
        setRerenderKey(renderKey + 1);
      }
      return;
    } else {
      setRerenderKey(renderKey - 1);
    }
  };

  const currentProblem = problems[renderKey];

  const typeOptions =
    skillData && skillData.typeDescription
      ? Object.keys(skillData.typeDescription).map((x) => {
          const type = skillData.typeDescription[x];
          return {
            label: type,
            value: x,
          };
        })
      : [];

  return (
    <PortalModal
      visible={props.visible}
      onClose={props.onClose}
      title={
        <>
          <div className="flex w-full items-center justify-between">
            Example Problems
            {props.toggleQuestion && (
              <Button
                onClick={() => setShowAnswer(!showAnswer)}
                className="text-sm"
              >
                Show {showAnswer ? "Question" : "Answer"}
              </Button>
            )}
          </div>
          {typeOptions.length !== 0 && (
            <>
              <Select
                placeholder="Select Problem Type"
                name="colorstypeOptions"
                options={typeOptions}
                isClearable={true}
                isSearchable={false}
                closeMenuOnSelect={false}
                className="w-96"
                onChange={(newValue) => {
                  setProblems([]);
                  setRerenderKey(0);
                  fetchProblems(compact([newValue?.value]));
                  setTypesSelected(compact([newValue?.value]));
                }}
              />
            </>
          )}
        </>
      }
      secondaryButtonText="Previous Example"
      secondaryDisabled={renderKey <= 0}
      secondaryOnClick={() => changeProblem("previous")}
      confirmButtonText="Next Example"
      onConfirm={() => changeProblem("next")}
      confirmDisabled={renderKey >= totalExamples - 1}
      body={
        <>
          {currentProblem ? (
            <div className="leading-8">
              <CustomFileWrapper
                skillId=""
                key={`renderKey-${currentProblem._id}-${renderKey}`}
                problem={currentProblem}
                checkAnswer={() => {
                  console.log("CHECK ANSWER");
                }}
                isCheckAnswerLoading={false}
                unsubmitAllowed={false}
                showSolutions={showAnswer}
                hideAnswer={!props.toggleQuestion || showAnswer}
                inModal={true}
                renderKey={renderKey}
                noBorder
                noAnalytics={props.noAnalytics}
              />
            </div>
          ) : (
            "Example Modal"
          )}
        </>
      }
      largeModal
    />
  );
};

export default ExampleModal;
