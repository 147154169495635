import {
  FormattedLearnerSubscriptionPlan,
  LearnerSubscriptionDetails,
} from "../../types";
import { AccountsTableWide } from "./AccountsTableWide";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { AccountsTableNarrow } from "./AccountsTableNarrow";

export const AccountsTable: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
  plan: FormattedLearnerSubscriptionPlan | undefined;
}> = ({ subscription, plan }) => {
  if (!subscription || !plan) {
    return (
      <div className="flex h-52 w-full items-center justify-center">
        <DmLoadingSpinner message="" />
      </div>
    );
  }

  return (
    <>
      <AccountsTableNarrow subscription={subscription} plan={plan} />
      <AccountsTableWide subscription={subscription} plan={plan} />
    </>
  );
};
