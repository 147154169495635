import { ProfileField } from "../ProfileField";
import { LearnerSubscriptionDetails } from "../../../types";
import { capitalize } from "lodash";
import { SkeletonText } from "../../Skeleton";
import { useCancellationContext } from "../Cancellation/CancellationContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import Button from "../../../../student/components/generic/button";
import { useParentContext } from "../../../contexts/ParentContext";
import { usePaymentUpdate } from "../../../utils/usePaymentUpdate";

export const PaymentInformationField: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
}> = ({ subscription }) => {
  const learnerContext = useLearnerContext();
  const { learners } = useParentContext();
  const { setShowCancelModal, setSelectedLearners } = useCancellationContext();
  const { updatePaymentMethod } = usePaymentUpdate();

  const { paymentMethod } = subscription || {};

  const handleCancelSubscription = async () => {
    if (
      learnerContext.learner &&
      !learnerContext.learner.entitlements.includes("parent")
    ) {
      setSelectedLearners([learnerContext.learner?._id]);
    } else if (subscription?.quantity === 1 && learners.length === 1) {
      setSelectedLearners(learners.map((l) => l._id));
    }
    setShowCancelModal(true);
  };

  const expMonth =
    paymentMethod?.type === "card"
      ? paymentMethod.expMonth === undefined
        ? "??"
        : paymentMethod.expMonth < 10
        ? `0${paymentMethod.expMonth}`
        : paymentMethod.expMonth
      : "??";
  const expYear =
    paymentMethod?.type === "card"
      ? paymentMethod.expYear === undefined
        ? "??"
        : paymentMethod.expYear.toString().slice(-2)
      : "??";

  const ManagementLinks = () => {
    if (
      learnerContext.learner?.subscriptionWillCancel !== true &&
      subscription?.status !== "canceled"
    ) {
      return (
        <>
          <Button type="link" onClick={updatePaymentMethod}>
            Update Payment
          </Button>
          {learnerContext.learner?.subscriptionPaymentFail === true && (
            <div className="text-dm-error-500">Payment method failed</div>
          )}
          <Button
            type="link"
            className="mt-6"
            onClick={handleCancelSubscription}
          >
            Cancel Subscription
          </Button>
        </>
      );
    }
    return null;
  };

  return (
    <ProfileField label="Payment Information">
      {paymentMethod?.type === "missing" ? (
        <>
          <span>No payment information available</span>
          <ManagementLinks />
        </>
      ) : paymentMethod?.type === "card" ? (
        <>
          <span>Name: {paymentMethod.cardholder}</span>
          <span>
            {capitalize(paymentMethod.brand)}: **** **** ****{" "}
            <strong>{paymentMethod.last4}</strong>
          </span>
          <span>
            Expires: {expMonth}/{expYear}
          </span>
          <ManagementLinks />
        </>
      ) : paymentMethod?.type === "cashapp" ? (
        <>
          <span>Cash App Pay ({paymentMethod.cashtag})</span>
          <ManagementLinks />
        </>
      ) : (
        <>
          <SkeletonText>Cardholder</SkeletonText>
          <SkeletonText>Card brand</SkeletonText>
          <SkeletonText>Card expiration</SkeletonText>
          <SkeletonText>Update payment</SkeletonText>
        </>
      )}
    </ProfileField>
  );
};
