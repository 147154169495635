import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import React, { ComponentProps, useEffect, useState } from "react";
import { IconButton } from "../../student/components/generic/IconButton";
import { noop } from "lodash";

export interface DMModalProps extends ComponentProps<typeof Dialog.Panel> {
  /**
   * Determines if the modal is visible. Note: modal is *not mounted* when
   * visible=false
   */
  visible: boolean;
  /** Handles closing the modal. Typically used to toggle the `visible` state */
  onClose?: () => void;
  /**
   * Determines if the modal can be closed by clicking an X button or by
   * clicking outside the modal
   * @default true
   */
  closeable?: boolean;
  /** Props for the backdrop element */
  backdropProps?: Partial<ComponentProps<typeof Dialog>>;
}

/**
 * A composable and customizable modal component. Use in conjunction with
 * `DMModalTitle`, `DMModalContent`, and `DMModalFooter`. Additional props are
 * applied to the inner `Dialog.Panel` component. Use `backdropProps` to
 * customize the backdrop.
 */
export const DMModal: React.FC<DMModalProps> = ({
  visible,
  onClose = noop,
  closeable = true,
  className,
  backdropProps,
  children,
  ...props
}) => {
  const [modalState, setModalState] = useState<
    "not-mounted" | "transition-in" | "transition-out" | "visible"
  >("not-mounted");

  const { className: backdropClassName, ...backdropPropsFinal } =
    backdropProps ?? {};

  const handleClose = () => {
    if (closeable) {
      setModalState("transition-out");
    }
  };

  const handleTransitionEnd = () => {
    if (modalState === "transition-in") {
      setModalState("visible");
    } else if (modalState === "transition-out") {
      if (visible) {
        onClose();
      }
      setModalState("not-mounted");
    }
  };

  useEffect(() => {
    if (visible) {
      if (modalState === "not-mounted") {
        setModalState("transition-in");
      } else if (modalState === "transition-in") {
        setModalState("visible");
      }
    } else {
      if (modalState === "visible") {
        setModalState("transition-out");
      }
    }
  }, [modalState, visible]);

  return (
    <Dialog
      open={modalState !== "not-mounted"}
      onClose={handleClose}
      className={clsx(
        // Using display:grid here will center the content if it fits on the
        // screen and overflow nicely if it doesn't
        "grid",
        "fixed inset-0 z-50 w-screen overflow-auto scroll-smooth bg-black/50 p-4 transition-opacity duration-200",
        modalState === "visible" ? "opacity-100" : "opacity-0",
        backdropClassName
      )}
      onTransitionEnd={handleTransitionEnd}
      {...backdropPropsFinal}
    >
      <div className="flex h-full w-full items-center justify-center">
        <Dialog.Panel
          className={clsx(
            "relative top-0 flex w-full max-w-[600px] flex-col gap-10 rounded-lg bg-white p-4 shadow-xl transition-all delay-100 duration-200 sm:p-6",
            closeable && "pt-10",
            modalState === "visible"
              ? "transform-none opacity-100"
              : "translate-y-10 transform opacity-0 delay-0",
            className
          )}
          {...props}
        >
          {closeable && (
            <IconButton
              onClick={handleClose}
              className="group absolute right-3 top-2 p-1 text-2xl"
              aria-label="Close"
              icon={
                <i className="far fa-times !text-dm-gray-500 group-hover:!text-dm-gray-800" />
              }
            />
          )}
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

/**
 * A styled title for use in a `DMModal`. Accepts props for a `Dialog.Title`
 * component
 */
export const DMModalTitle: React.FC<ComponentProps<typeof Dialog.Title>> = ({
  className,
  children,
  ...props
}) => (
  <Dialog.Title
    className={clsx(
      "w-full text-center font-serif text-2xl font-bold",
      className
    )}
    {...props}
  >
    {children}
  </Dialog.Title>
);

/**
 * A styled content section for use in a `DMModal`. Accepts props for a
 * `Dialog.Description` component
 */
export const DMModalContent: React.FC<
  ComponentProps<typeof Dialog.Description>
> = ({ className, children, ...props }) => (
  <Dialog.Description
    as="div"
    className={clsx("flex w-full flex-1 flex-col items-start gap-4", className)}
    {...props}
  >
    {children}
  </Dialog.Description>
);

/**
 * A styled footer for use in a `DMModal`. Accepts props for a `footer`
 * element
 */
export const DMModalFooter: React.FC<
  {
    /**
     * Determines if the footer should have a top border
     * @default true
     */
    bordered?: boolean;
  } & ComponentProps<"footer">
> = ({ bordered = true, className, children, ...props }) => (
  <footer
    className={clsx(
      "flex w-full flex-col items-stretch justify-between gap-4 sm:flex-row sm:items-center",
      bordered && "border-t border-dm-charcoal-100 pt-6",
      className
    )}
    {...props}
  >
    {children}
  </footer>
);
