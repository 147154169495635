import mixpanelPlugin from "@analytics/mixpanel";

export const MIXPANEL_PLUGIN = mixpanelPlugin({
  token: process.env.REACT_APP_MIXPANEL_TOKEN || "abc123",
  pageEvent: "pageView",
  options: {
    // 5% of sessions will be recorded
    record_sessions_percent: 5,
    record_mask_text_selector: "[type=password]",
  },
});
