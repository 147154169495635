import { getLearnerAccountStatus } from "../components/Profile/getLearnerAccountStatus";
import { Learner } from "../types";

export function getOpenSeats(totalSeats: number, learners: Learner[]) {
  const nonCancelledLearners = learners.reduce((accumulator, learner) => {
    return ["canceled", "inactive"].includes(getLearnerAccountStatus(learner))
      ? accumulator
      : accumulator + 1;
  }, 0);

  return totalSeats - nonCancelledLearners;
}
