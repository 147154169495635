import clsx from "clsx";
import { FormEventHandler, forwardRef, ReactElement } from "react";
import { useBoolean } from "usehooks-ts";

export const SignupFormField = forwardRef<
  HTMLInputElement,
  {
    value: string;
    onChange: FormEventHandler<HTMLInputElement>;
    onBlur?: FormEventHandler<HTMLInputElement>;
    label: string;
    type?: "text" | "password" | "email";
    error?: string | ReactElement;
    disabled?: boolean;
  }
>(({ value, onChange, label, type = "text", error, onBlur, disabled }, ref) => {
  const id = label.replace(/\s+/g, "-").toLowerCase();
  const { value: showPassword, toggle: togglePasswordVisiblity } =
    useBoolean(false);

  const ErrorMessage = () => {
    if (error) {
      if (typeof error === "string") {
        return <p className="text-sm text-dm-error-500">{error}</p>;
      } else {
        return error;
      }
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-1">
      <label className="text-sm font-bold" htmlFor={id}>
        {label}
      </label>
      <div className="relative">
        <input
          ref={ref}
          className={clsx(
            "h-10 w-full rounded-[4px] border border-dm-gray-200 disabled:text-dm-charcoal-200",
            // Always mask password fields in session replays
            type === "password" && "mp-mask"
          )}
          id={id}
          type={type === "password" && showPassword ? "text" : type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        {type === "password" && (
          <button
            className={clsx(
              "absolute right-2 top-2 flex h-6 w-6 items-center justify-center",
              showPassword
                ? "text-dm-brand-blue-500 hover:text-dm-brand-blue-600"
                : "text-dm-gray-200 hover:text-dm-gray-600"
            )}
            onClick={togglePasswordVisiblity}
            type="button"
          >
            <i className="far fa-eye" />
          </button>
        )}
      </div>
      <ErrorMessage />
    </div>
  );
});

SignupFormField.displayName = "SignupFormField";
