import { getFilePath } from "../../../../utils";

export type DeltieAssetOffset = [number, number];

export interface DeltieAsset {
  thumbnail: string;
  src: string;
  name: string;
  offset?: DeltieAssetOffset;
  accessoryCategory?: "accessories" | "shoes" | "hats" | "pants";
  replaces?: "arm-right";
  accessoryOrder?: number;
}

function createAsset(
  thumbnail: string,
  src: string,
  name: string,
  offset?: DeltieAssetOffset,
  accessoryCategory?: DeltieAsset["accessoryCategory"],
  accessoryOrder?: DeltieAsset["accessoryOrder"],
  replaces?: DeltieAsset["replaces"]
): DeltieAsset {
  return {
    thumbnail: getFilePath(thumbnail),
    src: getFilePath(src),
    name,
    offset,
    accessoryCategory,
    replaces,
    accessoryOrder,
  };
}

export const ASSETS = {
  scenes: {
    classroom: createAsset(
      "/images/learner/deltie/scenes/thumbs/classroom.png",
      "/images/learner/deltie/scenes/classroom.png",
      "Classroom"
    ),
    moon: createAsset(
      "/images/learner/deltie/scenes/thumbs/moon.png",
      "/images/learner/deltie/scenes/moon.png",
      "Moon"
    ),
    beach: createAsset(
      "/images/learner/deltie/scenes/thumbs/beach.png",
      "/images/learner/deltie/scenes/beach.png",
      "Beach"
    ),
    coffeeshop: createAsset(
      "/images/learner/deltie/scenes/thumbs/coffee-shop.png",
      "/images/learner/deltie/scenes/coffee-shop.png",
      "Coffee shop"
    ),
    danceclub: createAsset(
      "/images/learner/deltie/scenes/thumbs/dance-club.png",
      "/images/learner/deltie/scenes/dance-club.png",
      "Dance club"
    ),
    mountains: createAsset(
      "/images/learner/deltie/scenes/thumbs/mountains.png",
      "/images/learner/deltie/scenes/mountains.png",
      "Mountains"
    ),
    newyork: createAsset(
      "/images/learner/deltie/scenes/thumbs/new-york.png",
      "/images/learner/deltie/scenes/new-york.png",
      "New York"
    ),
    skatepark: createAsset(
      "/images/learner/deltie/scenes/thumbs/skatepark.png",
      "/images/learner/deltie/scenes/skatepark.png",
      "Skate park"
    ),
  },
  expressions: {
    bashful: createAsset(
      "/images/learner/deltie/expressions/thumbs/bashful.png",
      "/images/learner/deltie/expressions/bashful.png",
      "Bashful",
      [3, 10]
    ),
    confused: createAsset(
      "/images/learner/deltie/expressions/thumbs/confused.png",
      "/images/learner/deltie/expressions/confused.png",
      "Confused",
      [3, 5]
    ),
    excited: createAsset(
      "/images/learner/deltie/expressions/thumbs/excited.png",
      "/images/learner/deltie/expressions/excited.png",
      "Excited",
      [3, 5]
    ),
    frustrated: createAsset(
      "/images/learner/deltie/expressions/thumbs/frustrated.png",
      "/images/learner/deltie/expressions/frustrated.png",
      "Frustrated",
      [3, 10]
    ),
    genius: createAsset(
      "/images/learner/deltie/expressions/thumbs/genius.png",
      "/images/learner/deltie/expressions/genius.png",
      "Genius",
      [3, 10]
    ),
    happy: createAsset(
      "/images/learner/deltie/expressions/thumbs/happy.png",
      "/images/learner/deltie/expressions/happy.png",
      "Happy",
      [5, 2]
    ),
    mischievous: createAsset(
      "/images/learner/deltie/expressions/thumbs/mischievous.png",
      "/images/learner/deltie/expressions/mischievous.png",
      "Mischievous",
      [3, 6]
    ),
    thinking: createAsset(
      "/images/learner/deltie/expressions/thumbs/thinking.png",
      "/images/learner/deltie/expressions/thinking.png",
      "Thinking",
      [3, 5]
    ),
    tired: createAsset(
      "/images/learner/deltie/expressions/thumbs/tired.png",
      "/images/learner/deltie/expressions/tired.png",
      "Tired",
      [3, 5]
    ),
    worried: createAsset(
      "/images/learner/deltie/expressions/thumbs/worried.png",
      "/images/learner/deltie/expressions/worried.png",
      "Worried",
      [-18, -8]
    ),
  },
  accessories: {
    acccoffee: createAsset(
      "/images/learner/deltie/accessories/thumbs/acc-coffee.png",
      "/images/learner/deltie/accessories/acc-coffee.png",
      "Coffee",
      [-99, 68],
      "accessories",
      1, // Should above the sling and below the space helmet
      "arm-right"
    ),
    accphone: createAsset(
      "/images/learner/deltie/accessories/thumbs/acc-phone.png",
      "/images/learner/deltie/accessories/acc-phone.png",
      "Phone",
      [164, 120],
      "accessories"
    ),
    accskateboard: createAsset(
      "/images/learner/deltie/accessories/thumbs/acc-skateboard.png",
      "/images/learner/deltie/accessories/acc-skateboard.png",
      "Skateboard",
      [0, 230],
      "accessories"
    ),
    accsling: createAsset(
      "/images/learner/deltie/accessories/thumbs/acc-sling.png",
      "/images/learner/deltie/accessories/acc-sling.png",
      "Sling bag",
      [-16, 59],
      "accessories"
    ),
    hatbeanie: createAsset(
      "/images/learner/deltie/accessories/thumbs/hat-beanie.png",
      "/images/learner/deltie/accessories/hat-beanie.png",
      "Beanie",
      [12, -98],
      "hats"
    ),
    hatcap: createAsset(
      "/images/learner/deltie/accessories/thumbs/hat-cap.png",
      "/images/learner/deltie/accessories/hat-cap.png",
      "Cap",
      [12, -90],
      "hats"
    ),
    hatspacehelmet: createAsset(
      "/images/learner/deltie/accessories/thumbs/hat-space-helmet.png",
      "/images/learner/deltie/accessories/hat-space-helmet.png",
      "Space helmet",
      [5, -20],
      "hats",
      10 // Should render above the coffee cup
    ),
    pantschecked: createAsset(
      "/images/learner/deltie/accessories/thumbs/pants-checked.png",
      "/images/learner/deltie/accessories/pants-checked.png",
      "Checked pants",
      [-5, 131],
      "pants"
    ),
    pantsjoggers: createAsset(
      "/images/learner/deltie/accessories/thumbs/pants-joggers.png",
      "/images/learner/deltie/accessories/pants-joggers.png",
      "Joggers",
      [-10, 132],
      "pants"
    ),
    pantstrunks: createAsset(
      "/images/learner/deltie/accessories/thumbs/pants-trunks.png",
      "/images/learner/deltie/accessories/pants-trunks.png",
      "Trunks",
      [-9, 120],
      "pants"
    ),
    pantswide: createAsset(
      "/images/learner/deltie/accessories/thumbs/pants-wide.png",
      "/images/learner/deltie/accessories/pants-wide.png",
      "Wide pants",
      [-10, 139],
      "pants"
    ),
    shoesboots: createAsset(
      "/images/learner/deltie/accessories/thumbs/shoes-boots.png",
      "/images/learner/deltie/accessories/shoes-boots.png",
      "Boots",
      [-13, 173],
      "shoes"
    ),
    shoesclogs: createAsset(
      "/images/learner/deltie/accessories/thumbs/shoes-clogs.png",
      "/images/learner/deltie/accessories/shoes-clogs.png",
      "Clogs",
      [-12, 165],
      "shoes"
    ),
    shoesoldschool: createAsset(
      "/images/learner/deltie/accessories/thumbs/shoes-old-school.png",
      "/images/learner/deltie/accessories/shoes-old-school.png",
      "Old school shoes",
      [-11, 174],
      "shoes"
    ),
    shoessneaker: createAsset(
      "/images/learner/deltie/accessories/thumbs/shoes-sneaker.png",
      "/images/learner/deltie/accessories/shoes-sneaker.png",
      "Sneaker",
      [-12, 176],
      "shoes"
    ),
  },
};

export interface DeltieOptions {
  scene: keyof typeof ASSETS.scenes;
  expression: keyof typeof ASSETS.expressions;
  accessories: Array<keyof typeof ASSETS.accessories>;
}

export const DEFAULT_OPTIONS: DeltieOptions = {
  scene: "classroom",
  expression: "happy",
  accessories: ["shoesoldschool"],
};

export function loadAsset(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = src;
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", () => reject());
  });
}
