import React, { useCallback, useEffect, useRef, useState } from "react";
import { TourTypes, useTourContext } from "../../contexts/TourContext";
import clsx from "clsx";
import TourCard from "./TourCard";
import { useMediaQuery } from "usehooks-ts";
import { useCourseContext } from "../../contexts/CourseContext";
import { useNavigate, useParams } from "react-router-dom";
import { generateStartCourseLink } from "../../utils/generateStartCourseLink";

type Props = {
  step: number;
  tourType: TourTypes;
  children: React.ReactNode;
  classNames?: string;
  inactive?: boolean;
  forceTop?: boolean;
  stickyElement?: boolean;
  allowClick?: boolean;
  onNext?: () => void;
};

const TourCardWrapper = (props: Props) => {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");
  const tourContext = useTourContext();
  const navigate = useNavigate();

  const course = courseContext.getCourseData(coursePath);

  const startCourseLink = course ? generateStartCourseLink(course) : undefined;

  const cardRef = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<{
    top: number;
    bottom: number;
    left: number;
    right: number;
  }>();

  const showCard =
    tourContext.onboardingActive === props.tourType &&
    tourContext.onboardingStep === props.step;

  const handleResize = useCallback(() => {
    if (cardRef.current) {
      const boundingRect = cardRef.current.getBoundingClientRect();

      const top =
        Math.round(boundingRect.top) >= Math.round(cardRef.current.offsetTop)
          ? boundingRect.top
          : cardRef.current.offsetTop + (isSmallDevice ? 75 : 0);
      const bottom =
        Math.round(boundingRect.top) >= Math.round(cardRef.current.offsetTop)
          ? boundingRect.bottom
          : cardRef.current.offsetTop +
            boundingRect.height +
            (isSmallDevice ? 75 : 0);

      setRect({
        top: top,
        left: boundingRect.left,
        right: boundingRect.right,
        bottom: bottom,
      });
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef.current]);

  useEffect(() => {
    if (cardRef.current && !props.inactive) {
      tourContext.addSteps([
        {
          step: props.step,
          ref: cardRef.current,
          tourType: props.tourType,
        },
      ]);
      handleResize();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef.current, props.inactive]);

  useEffect(() => {
    handleResize();
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.tourType,
    props.step,
    tourContext.onboardingActive,
    tourContext.onboardingStep,
  ]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cardRef.current) {
      if (showCard) {
        window.scrollTo({
          top: cardRef.current.offsetTop + 70 - 300,
          behavior: "smooth",
        });
        handleResize();

        cardRef.current.style.setProperty("z-index", "1000");
        cardRef.current.style.setProperty("background", "white");
      } else {
        cardRef.current.style.removeProperty("z-index");
        cardRef.current.style.removeProperty("background");
      }
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCard, cardRef.current]);

  const overflowLeft = rect ? rect.left - 300 < 0 : false;
  const overflowTop = rect ? rect.top - 300 < 0 : false;
  const overflowRight = rect ? rect.right + 300 > window.innerWidth : false;
  const overflowBottom = rect ? rect.bottom + 300 > window.innerHeight : false;

  const halfOverflowTop = rect ? (rect.bottom - rect.top) / 2 - 300 < 0 : false;

  const position = props.forceTop
    ? "top"
    : !overflowLeft && !halfOverflowTop
    ? "left"
    : !overflowRight && !overflowTop && !overflowBottom
    ? "right"
    : !overflowTop
    ? "top"
    : "bottom";

  if (props.inactive) {
    return <>{props.children}</>;
  }

  return (
    <div
      ref={cardRef}
      className={clsx(
        props.classNames,
        "relative",
        showCard && !props.allowClick && "pointer-events-none",
        props.forceTop && isSmallDevice && showCard && "mt-[150px]"
      )}
    >
      {props.children}
      {rect && (
        <TourCard
          position={{
            top: rect.top,
            left: rect.left,
            right: rect.right,
            bottom: rect.bottom,
            position: position,
          }}
          visible={showCard}
          tour={props.tourType}
          step={props.step}
          onNext={() => {
            if (props.onNext) {
              props.onNext();
              return;
            }
            tourContext.nextStep();
          }}
          remindLater={() => {
            tourContext.remindLater();
          }}
          onComplete={() => {
            tourContext.skip();

            if (
              props.tourType === "learner" &&
              startCourseLink &&
              props.step === 7
            ) {
              navigate(startCourseLink);
            }
          }}
          stickyElement={props.stickyElement}
        />
      )}
    </div>
  );
};

export default TourCardWrapper;
