import clsx from "clsx";
import { ReactNode } from "react";

export const PricingQuantityInput: React.FC<{
  numLearners: number;
  onChange: (q: number) => void;
  title: ReactNode;
  subtext: ReactNode;
  incrementorType: "blue" | "white";
}> = ({ numLearners, title, subtext, incrementorType, onChange }) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold">{title}</p>
      <div className="isolate inline-flex rounded-md text-base">
        <button
          type="button"
          className={clsx(
            "relative inline-flex w-[46px] items-center justify-center rounded-l-md border border-dm-charcoal-800 px-3 py-2 text-sm focus:z-10",
            incrementorType === "blue"
              ? "bg-dm-brand-blue-100 hover:bg-dm-brand-blue-200 disabled:cursor-not-allowed disabled:hover:bg-dm-brand-blue-100"
              : "bg-white hover:bg-dm-charcoal-100"
          )}
          onClick={() => onChange(numLearners - 1)}
          aria-label="Decrease the number of learners"
          disabled={numLearners === 1}
        >
          &minus;
        </button>
        <span
          className="relative -ml-px inline-flex w-[60px] items-center justify-center border border-dm-charcoal-800 bg-white px-3 py-2 text-sm focus:z-10"
          aria-live="polite"
        >
          {numLearners}
        </span>
        <button
          type="button"
          className={clsx(
            "relative -ml-px inline-flex w-[46px] items-center justify-center rounded-r-md border border-dm-charcoal-800 px-3 py-2 text-sm focus:z-10",
            incrementorType === "blue"
              ? "bg-dm-brand-blue-100 hover:bg-dm-brand-blue-200"
              : "bg-white hover:bg-dm-charcoal-100"
          )}
          onClick={() => onChange(numLearners + 1)}
          aria-label="Increase the number of learners"
        >
          +
        </button>
      </div>
      <p className="italic text-dm-gray-500">{subtext}</p>
    </div>
  );
};
