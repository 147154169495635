import { useEffect, useMemo, useState } from "react";
import {
  CheckAnswerResponse,
  Problem,
  UnsubmitAnswerResponse,
} from "../../types";
import CustomFileWrapper from "./CustomFileWrapper";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { obfuscate, scrollToView } from "../../../student/utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Modal from "../../../student/components/generic/Modal";
import AdditionalHelp from "../Subunit/Practice/AdditionalHelp";
import StatusTagsWrapper from "./StatusTagsWrapper";
import {
  ComponentTimer,
  useComponentTimer,
} from "../../utils/useComponentTimer";
import { useProblemSolvingContext } from "../../contexts/ProblemSolvingContext";
import { ProblemLoading } from "./ProblemLoading";
import { ProblemWrapper } from "./ProblemWrapper";
import { Loading } from "../Loading";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { solveProblemEvent } from "../../analytics/events";

type Props = {
  sk: string;
  skippedProblem?: boolean;
  skillIndex: number;
  optional?: boolean;
  skillName: string;
  postQuizOrTest?: boolean;
  baseUrl?: string;
  skillPracticeUrl?: string;
  testTimer?: ComponentTimer;
};

const ProblemSolving = (props: Props) => {
  const { isLoadingProblem, resetCurrentProblem, currentProblem, skillId } =
    useProblemSolvingContext();

  useEffect(() => {
    resetCurrentProblem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillId]);

  if (isLoadingProblem) {
    return <ProblemLoading />;
  }

  if (currentProblem) {
    return <ProblemSolvingWithData {...props} />;
  }

  return (
    <ProblemWrapper>
      <p className="flex h-full w-full items-center justify-center text-dm-error-500">
        Unable to load this problem
      </p>
    </ProblemWrapper>
  );
};

const ProblemSolvingWithData: React.FC<Props> = (props) => {
  const {
    currentProblem,
    setCurrentProblem,
    logData,
    setLogData,
    attempts,
    setAttempts,
    problemData,
    resetCurrentProblem,
    assignment,
    skillId,
    versionIndex,
  } = useProblemSolvingContext();
  const nav = useNavigate();
  const { submittedTime, indexOfSkill } = useParams();
  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();
  const [checkAnswerResponse, setCheckAnswerResponse] =
    useState<CheckAnswerResponse>();
  const [renderKey, setRerenderKey] = useState<number>(0);
  const [showSolutions, setShowSolutions] = useState<boolean>(false);
  const [showAttemptModal, setShowAttemptModal] = useState<boolean>(false);
  const [nextProblemResponse, setNextProblemResponse] = useState<
    Problem | undefined
  >();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [searchParams, setSearchParams] = useSearchParams();

  const { track, getAssignmentData } = useLearnerAnalytics();

  // Disable the timer when the problem is complete. We've got learnerContext.state in
  // the dependencies because watching props.assignment alone doesn't guarantee this
  // fires in all situations (e.g. unsubmit then submit). This is mostly to accommodate
  // quizzes and tests which allow for unsubmitting and resubmitting an answer.
  const isTimerDisabled = useMemo(() => {
    return assignment.type === "practice"
      ? showSolutions
      : assignment.skills[props.skillIndex].skillComplete === true;
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment, props.skillIndex, learnerContext.state, showSolutions]);

  useEffect(() => {
    if (!isTimerDisabled) {
      timer.restartTimer();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimerDisabled]);

  const truncateId = (str: string) => str.substring(str.length - 6);
  const timer = useComponentTimer(
    [
      "problem",
      `l${truncateId(assignment.learnerId)}`,
      `c${truncateId(assignment.courseId)}`,
      `u${truncateId(assignment.unitId ?? "")}`,
      `s${truncateId(assignment.subunitId ?? "")}`,
      `sk${truncateId(skillId)}`,
    ].join(":"),
    isTimerDisabled,
    10 * 60 * 1000 // 10 minutes
  );

  const questionTitle =
    assignment.type === "practice"
      ? "Question"
      : `Question ${props.skillIndex + 1} of ${assignment.skills.length}`;

  const checkAnswer = useMutation(
    (body: string) => {
      //   return axios.post<CheckAnswerResponse | ProblemAttempts>(
      return axios.post<CheckAnswerResponse>(
        `${deltamathAPI()}/learner/assignment/checkAnswer?courseId=${
          assignment.courseId
        }${
          assignment.unitId !== undefined ? `&unitId=${assignment.unitId}` : ""
        }${
          assignment.subunitId !== undefined
            ? `&subunitId=${assignment.subunitId}`
            : ""
        }&assignmentId=${assignment._id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess(data) {
        // When we get a full response we go update what we have in context with the updated progress from the server
        if (data.data.responseType === "full") {
          setNextProblemResponse(data.data.nextProblem);
          learnerContext.updateProgressWithCheckAnswerResponse(
            data.data,
            assignment.courseId,
            assignment.type,
            props.sk,
            skillId,
            assignment.unitId,
            assignment.subunitId
          );
          if (
            assignment.type === "practice" &&
            currentProblem &&
            data.data.solution
          ) {
            const prob = {
              ...data.data.solution,
              data:
                typeof data.data.solution.data === "string"
                  ? obfuscate(`${currentProblem._id}`).reveal(
                      `${data.data.solution.data}`
                    )
                  : data.data.solution.data || currentProblem.data,
            };
            setCurrentProblem({
              ...currentProblem,
              ...prob,
            });
            setLogData(data.data.solution.log_data);
            setRerenderKey(renderKey + 1);
            setAttempts(0);
            setShowSolutions(true);
          }
          setTimeout(() => {
            scrollToView(null, "instant");
          }, 100);

          timer.clearTimer();
        } else if (data.data.responseType === "retry") {
          // give this a non 0 switch
          setAttempts(data.data.max - data.data.used + 1);
          setShowAttemptModal(true);
        }
        props.testTimer?.resetTimer();
        setCheckAnswerResponse(data.data);

        if (data.data.responseType === "full") {
          track(
            solveProblemEvent({
              ...getAssignmentData(assignment.type),
              correct: data.data.skillScore === 1,
              skillCode: props.sk,
              skillNumber: indexOfSkill ? parseInt(indexOfSkill, 10) : -1,
            })
          );
        }
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "There was an error checking your answer",
        });
      },
    }
  );

  const unsubmitCall = useMutation(
    (body: string) => {
      return axios.post<UnsubmitAnswerResponse>(
        `${deltamathAPI()}/learner/assignment/unsubmit/${skillId}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess(data) {
        learnerContext.updateAssignmentAndProgress(data.data);
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "There was an error unsubmitting your answer.",
        });
      },
    }
  );

  const unsubmit = () => {
    const body = {
      assignmentId: assignment._id,
      courseId: assignment.courseId,
    };
    unsubmitCall.mutate(JSON.stringify(body));
  };

  useEffect(() => {
    if (props.sk && urlParams.get("next") === "true") {
      nextProblem();
      if (urlParams.get("next") === "true" && assignment.type === "practice") {
        nav(
          `${props.baseUrl}/${assignment.type.toLowerCase()}/${
            props.skillIndex
          }`,
          {
            replace: true,
          }
        );
      }
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  useEffect(() => {
    if (searchParams.get("refetch")) {
      setSearchParams("", { replace: true });
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillId, submittedTime, searchParams]);

  const nextProblem = () => {
    if (
      checkAnswer.data?.data &&
      checkAnswer.data.data.responseType === "full" &&
      checkAnswer.data.data.nextProblem &&
      props.sk === checkAnswer.data.data.nextProblem?.skillcode &&
      !submittedTime &&
      nextProblemResponse
    ) {
      setCurrentProblem(nextProblemResponse);
      setLogData(undefined);
      // We were having bug where the incorrect next problem from the check answer response was getting used.
      // We are only allowing it to get used once
      setNextProblemResponse(undefined);
      timer.restartTimer();
    } else {
      resetCurrentProblem();
      // Forcing the problem to rerender so the problem scripts and everything else will run
    }
    setShowSolutions(false);
    setAttempts(0);
    setRerenderKey(renderKey + 1);
  };

  const checkAnswerCall = (body: string) => {
    checkAnswer.mutate(body);
  };

  // This should be impossible, but checking for it
  // prevents having to be extra defensive below
  if (!currentProblem) {
    throw new Error("Current problem is unexpectedly undefined");
  }

  const skillComplete =
    assignment.skills?.[props.skillIndex]?.skillComplete || false;

  const continuePractice =
    (assignment?.skills?.[props.skillIndex]?.record || 0) < 3;

  return (
    <>
      {assignment.type === "practice" && (
        <>
          <div className="mb-6 text-start max-sm:my-4 max-sm:px-4">
            <h3 className="font-serif text-lg font-bold">{props.skillName}</h3>
          </div>
        </>
      )}
      <Modal
        visible={showAttemptModal}
        onClose={() => {
          setShowAttemptModal(false);
        }}
        body={`Your answer is not correct. Try to find your mistake. You have ${
          attempts || 1
        } attempt${(attempts || 1) > 1 ? "s" : ""} remaining.`}
        title={"Notice"}
        confirmButtonText="Ok"
        onConfirm={() => {
          setShowAttemptModal(false);
        }}
        noLine
      />
      {assignment.type === "practice" && checkAnswer.isLoading && <Loading />}
      <CustomFileWrapper
        skillId={skillId}
        key={`renderKey-${currentProblem._id}-${renderKey}`}
        renderKey={renderKey}
        problem={currentProblem}
        logData={logData}
        checkAnswer={checkAnswerCall}
        isCheckAnswerLoading={checkAnswer.isLoading}
        elapsedTime={timer.elapsed}
        elapsedTestTime={props.testTimer?.elapsed}
        checkAnswerResponse={checkAnswerResponse}
        unsubmitAllowed={
          assignment.type !== "practice" && versionIndex === undefined
        }
        showSolutions={
          assignment.submitted !== undefined ||
          showSolutions ||
          versionIndex !== undefined
        }
        skippedProblem={props.skippedProblem}
        unsubmit={unsubmit}
        attemptText={
          assignment.type === "practice" && currentProblem.ansType !== 2
            ? `Attempt ${attempts + 1} of 2`
            : undefined
        }
        header={
          <StatusTagsWrapper
            skillCode={currentProblem.skillcode}
            skippedProblem={props.skippedProblem}
            optional={props.optional}
            questionTitle={questionTitle}
            solutionShowing={
              assignment.submitted !== undefined ||
              showSolutions ||
              versionIndex !== undefined
            }
            showExampleButton={problemData?.problem.showExamples}
            postQuizOrTest={props.postQuizOrTest}
            skillName={props.skillName}
            baseUrl={props.baseUrl}
            skillPracticeUrl={props.skillPracticeUrl}
            skillIndex={props.skillIndex}
            assignment={assignment}
            showVideo={currentProblem.showVideo}
          />
        }
      />
      {assignment.type === "practice" &&
        skillComplete &&
        !continuePractice &&
        showSolutions && <AdditionalHelp nextProblem={nextProblem} />}
    </>
  );
};

export default ProblemSolving;
