import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import ReactTooltip from "react-tooltip";
import { NavLink, Link } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import { SkillDataType, AssignmentType } from "../_constants";
import { skillDataDisplay, skillToSolve, scrollToView } from "../utils";
import {
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_IMPERSONATION_LINK,
  REACT_APP_STUDENT_LINK,
  deltamathLogo,
} from "../../utils";
import CalculatorButton from "./CalculatorButton";
import Cookies from "js-cookie";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import UploadWork from "./UploadWork";
import IconProgress from "./IconProgress";
import BackToHome from "./BackToHome";
import SideProgressBar from "./SideProgressBar";
import { Tooltip } from "../../shared/Tooltip";
import { useUserContext } from "../../shared/contexts/UserContext";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./generic/button";
// import { getFilePath } from "../../utils";
import FadeInSection from "./FadeInSection";
import { SidebarDueDate } from "./SidebarDueDate";

type Props = {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export default function SideBarSolveMenu({
  setSidebarOpen,
}: Props): JSX.Element {
  const userContext = useUserContext();
  const impersonate = window.localStorage.getItem("impersonateStudent");
  const queryClient = useQueryClient();
  const { activeSection, dmAssignmentData } = useContext(
    StudentSectionsContext
  );
  const solveSkill: any =
    dmAssignmentData && activeSection && dmAssignmentData[activeSection]
      ? skillToSolve(activeSection, dmAssignmentData)
      : undefined;
  const order = solveSkill?.ta?.order;
  const isTestType = solveSkill?.ta?.is_test;
  const isCorrectionType = solveSkill?.ta?.type == AssignmentType.correction;
  const isTimed = !!solveSkill?.ta?.timeLimitData;
  const lockedTaId = userContext.getTAid();

  const [showNoNavigateModal, setShowNoNavigateModal] =
    useState<boolean>(false);

  const { calcAvailable } = useContext(StudentSectionsContext);

  const [uploadAvailable, setUploadAvailable] = useState(
    solveSkill && solveSkill.ta?.notes === 2
  );
  const [tooltipText, setTooltipText] = useState<string | null>(null);

  // If this exists in local storage it was an LTI launch
  const lti = JSON.parse(
    localStorage.getItem("lti_assignment_payload") || "{}"
  ).isLtiResourceLaunch;

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userName =
    user?.first === undefined && user?.last === undefined
      ? "signing out..."
      : `${user.first} ${user.last}`;

  const logout = () => {
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    userContext.clearJWT();
    Cookies.remove("refresh_token_javascript");
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
  };

  const isSmallDevice = useMediaQuery("(max-width : 1023px)");

  const scrollToActive = () => {
    const activeEl: any = document.querySelector(".solve-side-nav.active");
    const sideEl: any = document.querySelector(".sidebar-inner-scroll");
    if (sideEl && activeEl)
      sideEl.scrollTop =
        activeEl.offsetTop - sideEl.getBoundingClientRect().top;
  };

  // scroll sidebar to active/current skill

  // no flicker on desktop when using useLayoutEffect, but
  // didn't work with mobile so needed the useEffect() below
  useLayoutEffect(() => {
    if (!isSmallDevice) scrollToActive();
  }, []);

  useEffect(() => {
    if (isSmallDevice) scrollToActive();
  }, []);

  useEffect(() => {
    if (solveSkill && solveSkill.ta?.notes === 2) {
      setUploadAvailable(true);
    }
  }, []);

  ReactTooltip.rebuild();

  return (
    <>
      <div className="relative flex h-full flex-col">
        <div className="mb-1 shrink-0">
          <Link
            onClick={(e) => {
              const locked = userContext.getIsLocked();
              if (lti || locked) {
                e.preventDefault();
                if (locked) {
                  setShowNoNavigateModal(true);
                }
              }
            }}
            to={lti ? "" : `${REACT_APP_STUDENT_LINK}`}
            className={`mb-8 block h-6 bg-white focus:outline-none ${
              lti ? "cursor-default" : ""
            }`}
          >
            <img
              className="z-2 h-full w-auto"
              src={deltamathLogo}
              alt="DeltaMath Home"
            />
          </Link>
          <div className="border-b-2 border-b-gray-100 pb-6 pt-4">
            <BackToHome
              type="link"
              className="mb-4 inline-block font-semibold"
              setSidebarOpen={setSidebarOpen}
            />
            <h3 className="mb-1 flex justify-between pr-5 font-bold leading-5 sm:justify-normal">
              {solveSkill?.ta?.name}
              {solveSkill?.sa?.correctionInitialized &&
                (lti || (solveSkill?.ta._id === lockedTaId && isTimed)) && (
                  <Tooltip message="Reset Test Correction">
                    <NavLink
                      to={`${REACT_APP_STUDENT_LINK}/link/${solveSkill.ta?._id}?reset=true`}
                    >
                      <i className="far fa-redo ml-2 h-6 w-6"></i>
                    </NavLink>
                  </Tooltip>
                )}
            </h3>
            <SidebarDueDate studentAssignment={solveSkill?.sa ?? null} />
            <SideProgressBar solveSkill={solveSkill} />
            {isCorrectionType &&
            solveSkill?.ta?.creditBack > 0 &&
            solveSkill?.sa?.newtest !== undefined ? (
              <Tooltip message={`Original Test Grade: ${solveSkill.sa.test}%`}>
                <span className="mt-4 inline-flex items-center gap-x-0.5 rounded-full bg-dm-success-100 px-2 py-1 text-xs text-dm-success-500 ring-1 ring-inset ring-dm-success-500/20">
                  New Test Grade: <strong>{solveSkill.sa.newtest}%</strong>
                </span>
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div className="flex flex-grow flex-col justify-start overflow-hidden">
          <div className="sidebar-inner-scroll overflow-auto pb-4 pt-6">
            {order &&
              order.length > 0 &&
              order.map((skill: string) => {
                const skillData: SkillDataType = skillDataDisplay(
                  skill,
                  solveSkill
                );
                const weight = skillData.progress?.weight || 1;
                const score = (skillData.progress?.score || 0) * weight;
                const [isHover, setIsHover] = useState<boolean>(false);
                return (
                  <FadeInSection
                    key={`fade-sbs:${skillData.uid}`}
                    additionalClasses="last:mb-0 mb-2"
                  >
                    <NavLink
                      // key={`skill:${skillData.uid}`}
                      to={`${skillData.url}`}
                      onClick={() => {
                        scrollToView();
                        setSidebarOpen(false);
                      }}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? "active bg-dm-brand-blue-100 font-bold text-dm-gray-800"
                            : "text-dm-gray-600",
                          "solve-side-nav group relative mb-1 flex items-center gap-x-2 rounded-md p-2 pr-4 text-sm leading-5 last:mb-0 hover:bg-dm-brand-blue-100 hover:text-dm-gray-800"
                        )
                      }
                      onMouseEnter={() => {
                        if (!isHover) setIsHover(true);
                      }}
                      onMouseLeave={() => {
                        if (isHover) setIsHover(false);
                      }}
                    >
                      {({ isActive }) => (
                        <>
                          <IconProgress
                            skillData={skillData}
                            setTooltipText={setTooltipText}
                            isActive={isActive || isHover}
                            // key={`icon-solve:${skillData.uid}`}
                          />
                          <h3 className="line-clamp-2 flex flex-grow items-center text-ellipsis">
                            {skillData.name}
                            {isTestType ? (
                              <>
                                {" "}
                                (
                                {!skillData.obscureResults ? `${score}/` : null}
                                {weight} pts)
                              </>
                            ) : null}
                          </h3>
                        </>
                      )}
                    </NavLink>
                  </FadeInSection>
                );
              })}
          </div>
        </div>
        <div className="mt-1 flex flex-col border-t bg-white pt-6">
          {uploadAvailable && <UploadWork inSidebar={true} />}
          {calcAvailable && (
            <CalculatorButton setSidebarOpen={setSidebarOpen} />
          )}
          <div
            id="user-buttons"
            className="flex items-center justify-between p-2"
          >
            <NavLink
              to={`${REACT_APP_STUDENT_LINK}/profile`}
              onClick={() => setSidebarOpen(false)}
              className="truncate text-dm-gray-600 hover:text-dm-gray-800"
              aria-label="Profile Settings"
            >
              <div className="group flex items-center text-sm">
                <i className="far fa-user mr-2 h-6 w-6 pl-1 text-xl leading-none text-dm-gray-200 group-hover:text-dm-gray-800"></i>
                <span className="truncate text-dm-gray-600">{userName}</span>
              </div>
            </NavLink>
            <Button
              type="link"
              className="flex-shrink-0 text-sm"
              onClick={logout}
            >
              Log Out
            </Button>
          </div>
          {impersonate === "true" && (
            <Button
              id="decamp"
              onClick={() => {
                window.location.href = `${REACT_APP_IMPERSONATION_LINK}?decamp=true`;
              }}
              className="w-full"
            >
              Back to Teacher Account
            </Button>
          )}
        </div>
      </div>
      <ReactTooltip
        id="piechart"
        effect="solid"
        delayShow={50}
        delayHide={50}
        multiline={true}
        html={true}
        place="right"
        getContent={() => tooltipText}
      />
      <Transition show={showNoNavigateModal}>
        <Dialog
          onClose={() => {
            setShowNoNavigateModal(false);
          }}
          className="relative z-50"
        >
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white px-6 py-4 text-left align-middle shadow-xl transition-all sm:w-[672px] sm:px-12 sm:py-10">
                  <div className="mb-10 flex flex-col gap-6">
                    <Dialog.Title
                      as="h3"
                      className="mb-3 text-2xl font-medium leading-6 text-gray-900"
                    >
                      Restricted
                    </Dialog.Title>
                    <Dialog.Description className="text-sm">
                      You are only allowed to work on this assignment until time
                      is up.
                    </Dialog.Description>
                  </div>
                  <div className="mt-4 flex flex-col justify-between gap-4 border-t pt-8 sm:flex-row">
                    <div></div>
                    <Button
                      onClick={() => {
                        setShowNoNavigateModal(false);
                      }}
                      size="small"
                    >
                      Okay
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
