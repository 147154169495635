import { Fragment, useEffect, useMemo, useRef } from "react";
import { Problem } from "../../types";
import { displayInlineMath, resizeKatexLine } from "../../../student/utils";
import renderMathInElement from "../../../student/utils/auto-render";
import { formatAns } from "../../../student/components/answerForm/FormattedAnswer";
import { getSelectedChoice } from "../../../utils";
import clsx from "clsx";

type Props = {
  problem: Problem;
  answer: object | string[] | undefined;
  noSolutionText: string;
  noAnswerSubmitted: boolean;
  customMessage?: string;
  totalAttempts: number;
  index: number;
  correct: boolean;
  messages?: string[];
};

const DisplaySolution = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const { formattedAnsArray, formattedAnsStrArray } = useMemo(() => {
    return formatAns({
      ansType: props.problem.ansType,
      answer: props.answer,
      studentIsStuck: false,
      noAnswerSubmitted: props.noAnswerSubmitted,
      noSolutionText: props.noSolutionText,
      leftLatex: props.problem.data.leftLatex,
      rightLatex: props.problem.data.rightLatex,
      setNotation: props.problem.data.setNotation,
      customMsg: props.customMessage,
      studentSolution: getSelectedChoice(
        props.answer,
        props.problem.choices ?? []
      ),
    });
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answer]);

  useEffect(() => {
    if (
      props.problem.ansType === "custom" ||
      (props.problem.ansType === 2 &&
        props.problem.choices &&
        props.problem.choices.length > 0 &&
        props.problem.choices[0][0] === "<" &&
        ref.current &&
        renderMathInElement)
    ) {
      renderMathInElement(ref.current);
    }
    const resizeNeeded = ref.current?.querySelector(".katex") !== null;
    const handleResize = () => resizeKatexLine(ref.current);
    if (resizeNeeded) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (resizeNeeded) window.removeEventListener("resize", handleResize);
    };
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answer]);

  if (!props.answer) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        props.index + 1 !== props.totalAttempts && "mb-8",
        props.index === 0 && "mt-8",
        "row font-sans text-sm/[26px] text-dm-charcoal-500"
      )}
    >
      {props.totalAttempts > 1 && (
        <div
          className={clsx("row flex items-center", props.index !== 0 && "pt-8")}
        >
          Attempt {props.index + 1} out of {props.totalAttempts}
          {props.correct ? (
            <>
              {" "}
              (correct)
              <i className="far fa-check ml-2 !text-dm-success-500" />
            </>
          ) : (
            <>
              {" "}
              (incorrect)
              <i className="far fa-times ml-2 !text-dm-error-500" />
            </>
          )}
        </div>
      )}
      {props.messages && props.messages.length > 0 ? (
        <ul>
          {props.messages.map((msg, index) => (
            <li
              key={"message" + props.index + index}
              className={clsx(
                "px-4 py-2 sm:px-6",
                props.correct ? "text-[#078445]" : "text-[#D21527]"
              )}
            >
              {msg}
            </li>
          ))}
        </ul>
      ) : null}
      <div
        id={`format-ans-${props.index}`}
        ref={ref}
        className={clsx(
          props.problem.ansType === "custom"
            ? "text-[1.3125em]"
            : "text-center text-[1.3125em]",
          "col-xs-12 col-md-9 !px-0"
        )}
      >
        {props.problem.ansType === 1 || props.noAnswerSubmitted ? (
          <Fragment>
            {displayInlineMath(formattedAnsStrArray.join(""), true)}
          </Fragment>
        ) : (
          formattedAnsArray
        )}
      </div>
      <div className="col-md-3"></div>
    </div>
  );
};

export default DisplaySolution;
