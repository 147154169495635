import { useLearnerContext } from "../../contexts/LearnerContext";
import { Subunit } from "../../types";
import Button from "../../../student/components/generic/button";
import ProgressBar from "../../../shared/ProgressBar";
import SubunitCardAssignmentProgress from "./SubunitCardAssignmentProgress";
import { useNavigate } from "react-router";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import SkillsTooltip from "./SkillsTooltip";

type Props = {
  index: number;
  subunit: Subunit;
  coursePath?: string;
  courseId: string;
  unitPath?: string;
  unitId: string;
};

const SubunitSummaryCard = (props: Props) => {
  const learnerContext = useLearnerContext();
  const navigate = useNavigate();

  const courseProgress = learnerContext.getProgress(props.courseId);
  const unitProgress =
    courseProgress &&
    courseProgress.units.find((u) => u.unitId === props.unitId);
  const subunitProgress =
    unitProgress &&
    unitProgress.subunits.find((s) => s.subunitId === props.subunit.id);

  const preQuizComplete =
    subunitProgress?.preQuiz?.submitted || subunitProgress?.skippedPreQuiz;
  const practiceComplete = subunitProgress?.practice?.progress === 1;
  const postQuizComplete = subunitProgress?.postQuiz?.submitted;

  const difficulty =
    subunitProgress?.difficulty || learnerContext.learner?.level || "easy";

  const lockedText =
    !subunitProgress?.preQuiz && !subunitProgress?.skippedPreQuiz
      ? "Locked until Pre-Quiz is complete or skipped"
      : subunitProgress.practice?.progress !== 1
      ? "Locked until Practice is completed"
      : undefined;

  const buttonText =
    !subunitProgress?.preQuiz && !subunitProgress?.skippedPreQuiz
      ? "Go To Section"
      : subunitProgress.progress === 1 &&
        (postQuizComplete || subunitProgress.preQuiz?.maxGrade === 1)
      ? "Review Section"
      : "Resume Section";

  const baseUrl = `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/${props.subunit.path}`;

  const hrefToNavigateTo = `${baseUrl}/${
    !preQuizComplete ? "prequiz" : !practiceComplete ? "practice" : "postquiz"
  }`;

  return (
    <div className="flex w-full flex-wrap justify-between gap-y-3 rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4 md:items-center md:gap-x-2">
      <div className="flex basis-full flex-col md:flex-grow md:basis-3/5">
        <h4 className="mb-2 text-lg font-bold text-dm-charcoal-800">
          <span className="mr-2">
            Section {props.index}: {props.subunit.name}
          </span>
          <SkillsTooltip
            skills={props.subunit.skills}
            difficulty={difficulty}
            subunitId={props.subunit.id}
          />
        </h4>
        <ProgressBar
          percentCompleted={(subunitProgress?.progress || 0) * 100}
          solvePage={false}
          tooltipEnabled={false}
          gradientBknd={true}
          width="md:w-80"
          height="h-6"
        />
        <h4 className="text-xs">
          <strong>{Math.round((subunitProgress?.progress || 0) * 100)}%</strong>{" "}
          complete
        </h4>
      </div>
      {preQuizComplete &&
      !practiceComplete &&
      subunitProgress?.practice?.progress === 0 ? (
        <Button
          onClick={() =>
            navigate(hrefToNavigateTo, {
              state: { landing: true },
            })
          }
          size="small"
          className="basis-full !leading-7 max-md:order-1 md:flex-shrink-0 md:basis-auto"
          type={buttonText === "Review Section" ? "outline" : "primary"}
        >
          {buttonText}
        </Button>
      ) : (
        <Button
          className="basis-full !leading-7 max-md:order-1 md:flex-shrink-0 md:basis-auto"
          href={hrefToNavigateTo}
          size="small"
          type={buttonText === "Review Section" ? "outline" : "primary"}
        >
          {buttonText}
        </Button>
      )}
      <div className="flex basis-full flex-col gap-2 text-sm">
        <SubunitCardAssignmentProgress
          type="preQuiz"
          assignmentRef={subunitProgress?.preQuiz}
          preQuiz100={subunitProgress?.preQuiz?.maxGrade === 1}
          locked={
            subunitProgress?.skippedPreQuiz !== undefined &&
            subunitProgress?.preQuiz?.submitted === undefined
          }
          lockedText={undefined}
          href={`${baseUrl}/prequiz`}
          courseId={props.courseId}
          unitId={props.subunit.unitId}
          subunitId={props.subunit.id}
        />
        <SubunitCardAssignmentProgress
          type="practice"
          assignmentRef={subunitProgress?.practice}
          preQuiz100={subunitProgress?.preQuiz?.maxGrade === 1}
          locked={!preQuizComplete}
          lockedText={lockedText}
          href={`${baseUrl}/practice`}
          courseId={props.courseId}
          unitId={props.subunit.unitId}
          subunitId={props.subunit.id}
        />
        <SubunitCardAssignmentProgress
          type="postQuiz"
          assignmentRef={subunitProgress?.postQuiz}
          preQuiz100={subunitProgress?.preQuiz?.maxGrade === 1}
          locked={
            (!subunitProgress?.preQuiz && !subunitProgress?.skippedPreQuiz) ||
            subunitProgress.practice?.progress !== 1
          }
          lockedText={lockedText}
          href={`${baseUrl}/postquiz`}
          courseId={props.courseId}
          unitId={props.subunit.unitId}
          subunitId={props.subunit.id}
        />
      </div>
    </div>
  );
};

export default SubunitSummaryCard;
