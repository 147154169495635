import React, { useContext, useEffect, useState } from "react";
import { useLearnerContext } from "./LearnerContext";
import { useMediaQuery, useStep } from "usehooks-ts";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../manager/utils";
import {
  DASHBOARD_ONBOARDING_STEPS,
  LEARNER_ONBOARDING_STEPS,
  PARENT_ONBOARDING_STEPS,
} from "../components/Points/onboarding/constants";
import StartTourModal from "../components/Tour/StartTourModal";
import { useParams } from "react-router-dom";
import OpacityCover from "../../shared/OpacityCover";

export type TourTypes = "dashboard" | "parent" | "learner";

type Step = {
  step: number;
  ref: HTMLDivElement;
  tourType: TourTypes;
  absolute?: boolean;
  movingElement?: boolean;
  onNext?: () => void;
};

interface TourApi {
  steps: Step[];
  onboardingStep: number;
  onboardingActive?: TourTypes;
  addSteps(steps: Step[]): void;
  nextStep(): void;
  skip(): void;
  remindLater(): void;
}

const TourContext = React.createContext<TourApi>({
  steps: [],
  onboardingStep: 0,
  addSteps: () => {
    return;
  },
  nextStep: function (): void {
    return;
  },
  skip: function (): void {
    return;
  },
  remindLater: function (): void {
    return;
  },
});

export const TourContextProvider: React.FC = ({ children }) => {
  const { coursePath } = useParams();
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");

  const learnerContext = useLearnerContext();
  const [state, setState] = React.useState<{ steps: Step[] }>({ steps: [] });
  const [onboardingVisible, setOnboardingVisible] = useState<boolean>(false);
  const [showOnboardingModal, setShowOnboardingModal] =
    useState<boolean>(false);

  const currentTour = learnerContext.shouldShowOnboarding();

  const totalSteps =
    currentTour === "dashboard"
      ? DASHBOARD_ONBOARDING_STEPS
      : currentTour === "learner"
      ? LEARNER_ONBOARDING_STEPS
      : PARENT_ONBOARDING_STEPS;

  const [step, { goToNextStep }] = useStep(totalSteps + 1);

  // Special rules for skipping a step if the user is on desktop
  useEffect(() => {
    if (!isSmallDevice && step === 2 && currentTour === "learner") {
      goToNextStep();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallDevice, step]);

  useEffect(() => {
    // Triggering the next step after the user navigates
    if (currentTour === "learner" && coursePath && step === 5) {
      goToNextStep();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursePath, step, currentTour]);

  const onboardingCompleted = useMutation(
    (body: { type: TourTypes }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/shared/onboarding`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: () => {
        if (currentTour) {
          learnerContext.onboardingCompleted(currentTour);
        }
        setOnboardingVisible(false);
      },
      onError: (err) => {
        // go ahead and mark it as completed even if there is an error
        if (currentTour) {
          learnerContext.onboardingCompleted(currentTour);
        }
        console.error("Issue completing onboarding");
      },
    }
  );

  useEffect(() => {
    if (
      learnerContext.state.learner &&
      !learnerContext.state.showedOnboarding
    ) {
      setShowOnboardingModal(true);
    }

    if (showOnboardingModal) {
      learnerContext.setOnboardingShown();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showOnboardingModal,
    learnerContext.state.showedOnboarding,
    learnerContext.state.learner,
  ]);

  const currentStep = state.steps.find(
    (s) => s.step === step && s.tourType === currentTour
  );

  const skipTour = () => {
    if (currentTour) {
      setShowOnboardingModal(false);
      onboardingCompleted.mutate({ type: currentTour });
    }
  };

  const addSteps = (steps: Step[]) => {
    setState((prevState) => {
      return {
        steps: [
          ...prevState.steps.filter(
            (x) =>
              !steps.some(
                (step) => step.tourType === x.tourType && step.step === x.step
              )
          ),
          ...steps,
        ],
      };
    });
  };

  const startTour = () => {
    if (learnerContext.isSidebarMinimized) {
      learnerContext.setIsSidebarMinimized(false);
    }
    setShowOnboardingModal(false);
    setOnboardingVisible(true);
  };

  const remindMeLater = () => {
    setShowOnboardingModal(false);
    setOnboardingVisible(false);
  };

  return (
    <TourContext.Provider
      value={{
        steps: state.steps,
        onboardingStep: step,
        onboardingActive:
          currentTour && onboardingVisible ? currentTour : undefined,
        nextStep: goToNextStep,
        addSteps: addSteps,
        skip: skipTour,
        remindLater: remindMeLater,
      }}
    >
      {(currentStep || step > 1) && currentTour && onboardingVisible && (
        <OpacityCover visible={true} closing={false} />
      )}
      {children}
      {currentStep && currentTour && (
        <>
          <StartTourModal
            type={currentTour}
            visible={showOnboardingModal}
            onClose={remindMeLater}
            startTour={startTour}
            remindMeLater={remindMeLater}
            skipTour={skipTour}
          />
        </>
      )}
    </TourContext.Provider>
  );
};

export function useTourContext() {
  return useContext(TourContext);
}
