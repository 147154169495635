import React, { forwardRef, useContext, useEffect, useRef } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  useTable,
  HeaderGroup,
  useSortBy,
  Row,
  useRowSelect,
  SortByFn,
  usePagination,
  TableToggleCommonProps,
} from "react-table";
import Select, { components, OptionProps } from "react-select";
import clsx from "clsx";
import { WEIGHTED_TEXT } from "../admin/utils";
import { GlobalFilter } from "./table/filters";
import AdminFeatureContext from "../admin/_context/FeatureContext";

// Define a default UI for filtering (Required by react-table)
const DefaultColumnFilter = () => <></>;

const useCombinedRefs = (
  ...refs: Array<
    | React.Ref<HTMLInputElement>
    | React.MutableRefObject<null>
    | HTMLDivElement
    | React.Ref<HTMLDivElement>
    | null
  >
): React.MutableRefObject<HTMLInputElement | null> => {
  const targetRef = useRef(null);

  useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

//Possible TODO: deprecate? This looks 'indeterminate' (a dash through the box) but that's not necessarily how we use it. The below has a fully fulled box per new styling
const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  TableToggleCommonProps
>(({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
  const { checked } = { ...rest };
  const defaultRef = useRef<any>(null);
  const combinedRef = useCombinedRefs(ref, defaultRef);

  useEffect(() => {
    if (combinedRef?.current) {
      combinedRef.current.indeterminate = checked ?? indeterminate ?? false;
    }
  }, [combinedRef, indeterminate, checked]);

  return (
    <>
      <input type="checkbox" ref={combinedRef} {...rest} />
    </>
  );
});

//Sligthly different style for the checkbox. Possible TODO: make this an option and not the default
const RowSelectCheckbox = forwardRef<HTMLInputElement, TableToggleCommonProps>(
  ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
    const { checked } = { ...rest };
    const defaultRef = useRef<any>(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    // useEffect(() => {
    //   if (combinedRef?.current) {
    //     combinedRef.current.indeterminate = checked ?? indeterminate ?? false;
    //   }
    // }, [combinedRef, indeterminate, checked]);

    delete rest.style;
    rest.style = { backgroundImage: "none" };

    return (
      <>
        <input
          type="checkbox"
          className="custom-checkbox"
          ref={combinedRef}
          {...rest}
        />
      </>
    );
  }
);

export function MultiTeacherFilter({
  /**
   * Takes a comma separated entry of DeltaMathTable and splits on each element to create
   * a multi-select filter of unique entries in a column.
   */
  column: { preFilteredRows, setFilter, id },
}: {
  column: HeaderGroup;
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: any = [];
    preFilteredRows.forEach((rowz: any) => {
      const teachersInRow = rowz.values[id]
        .split(",")
        .map((v: string) => v.trim());
      teachersInRow.forEach((element: string) => {
        if (element) {
          const name = element.split(" ");
          name &&
            options.push({ fullName: element, last: name[name.length - 1] });
        }
      });
    });
    options.sort((a: { last: string }, b: { last: string }) =>
      a.last.toLowerCase() > b.last.toLowerCase()
        ? 1
        : b.last.toLowerCase() > a.last.toLowerCase()
        ? -1
        : 0
    );
    const final = options.map((item: any) => item.fullName);
    // convert to set to remove duplicates
    const set = new Set(final);
    return Array.from(set).map((x: any) => ({
      value: x,
      label: x,
    }));
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      options={options}
      isMulti
      styles={{
        menu: (provided: any) => ({
          ...provided,
          color: "black",
          textAlign: "left",
        }),
        input: (provided: any) => ({
          ...provided,
          border: "none",
          textAlign: "left",
        }),
        control: (provided: any) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "24px",
          textAlign: "left",
        }),
      }}
      onChange={(e) => {
        setFilter(e.map((x) => x.value) || undefined);
      }}
    />
  );
}
//interface for custom option component used in multiCheckFilter
interface Option {
  value: string;
  label: string;
}
//Custom option component for multi-select filter, used in multiCheckFilter
const multiCheckOption: React.FC<OptionProps<Option>> = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center px-4 py-2">
        <div className="mr-4 flex items-center">
          <div className="relative h-4 w-full hover:cursor-pointer">
            <div
              className={`absolute left-0 top-0 h-4 w-4 border-2 bg-white ${
                props.isSelected ? "border-blue-600" : "border-slate-400"
              }`}
            />
            {props.isSelected && (
              <div className="absolute left-[4px] top-[4px] h-2 w-2 border-2 border-blue-600 bg-blue-600" />
            )}
          </div>
        </div>
        <span className="ml-2 text-gray-800 hover:cursor-pointer">
          {props.label}
        </span>
      </div>
    </components.Option>
  );
};
//custom select value container for multi-select filter, used in multiCheckFilter
const multiCheckValueContainer: React.FC<any> = ({ children, ...props }) => {
  const count = props.getValue().length;
  const name = props.selectProps.name;
  return (
    <components.ValueContainer {...props}>
      <div className="m-2 w-full cursor-pointer text-gray-400" tabIndex={0}>
        {count > 0
          ? `${count} ${name}${count > 1 ? "s" : ""} Selected`
          : `Select ${name}s`}
        <div className="sr-only">{children}</div>
      </div>
    </components.ValueContainer>
  );
};

//MB - this is absolutely redundant (w/ the MultiselectFilter) but I was having trouble figuring out how to pass any sort of flag/props to the custom filter from the column definition. Props generally seem to exist here in DeltaMathTable. At the least, it's probably worth going back at some point and abstracting out some of the shared functions
export function MultiCheckFilter({
  column: { filterValue, preFilteredRows, setFilter, id, Header, sortType },
}: {
  column: HeaderGroup & { sortType?: string };
}) {
  // load preference values from localStorage
  const tempPreferences = JSON.parse(
    localStorage.getItem("tempPreferences") || "{}"
  );
  const filterPreferenceArray = tempPreferences?.filterPreferences || [
    { id: id, value: [] },
  ];

  // parse localStorage contents into filter values for this column
  const thisFilter =
    Array.isArray(filterPreferenceArray) &&
    filterPreferenceArray.find(
      (pref: { id: string; value: string[] }) => pref.id === id
    );

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: Set<string> = new Set();
    preFilteredRows.forEach((rowz: any) => {
      options.add(rowz.values[id]);
    });

    const optionArray = Array.from(options).map((x) => ({
      value: x,
      label: x,
    }));

    if (sortType === "datetime") {
      optionArray.sort(
        (a: { value: string | number }, b: { value: string | number }) => {
          const aDate = new Date(a?.value)?.getTime();
          const bDate = new Date(b?.value)?.getTime();
          if (!isNaN(aDate) && !isNaN(bDate)) {
            return aDate - bDate;
          } else {
            return 0;
          }
        }
      );
    }
    return optionArray;
  }, [id, preFilteredRows]);

  const [thisValue, setThisValue] = React.useState(thisFilter?.value || []);

  /**
   * takes an array of selected option values and updates the filter and localStorage to
   * reflect the new selection
   * @param value string array of currently selected options
   */
  const handleFilterInput = (value: string[]) => {
    setFilter(value || undefined); // Set undefined to remove the filter entirely
    setThisValue(value || undefined);
    const newPref = {
      id: id,
      value: value,
    };
    if (
      // if there is no existing preference for this column's filter, push a new entry
      !filterPreferenceArray.find(
        (pref: { id: string; value: string[] }) => pref.id === id
      )
    ) {
      filterPreferenceArray.push(newPref);
    } else {
      // if there is an existing preference, update the column's filter value
      const newPreferenceArray = filterPreferenceArray.map(
        (pref: { id: string; value: string[] }) => {
          if (pref.id === id) {
            return {
              id: id,
              value: value,
            };
          } else return pref;
        }
      );
      filterPreferenceArray.splice(
        0,
        filterPreferenceArray.length,
        ...newPreferenceArray
      );
    }
    // update local storage with new values
    localStorage.setItem(
      "tempPreferences",
      JSON.stringify({
        ...tempPreferences,
        filterPreferences: filterPreferenceArray,
      })
    );
  };
  // Render a multi-select box
  return (
    <Select
      options={options}
      isMulti
      name={`${Header}`}
      // display selected options in Select box, whether new or pre-selected
      value={options.filter((option: { value: string; label: string }) => {
        // console.log('in select', thisValue, option.value)
        return thisValue.includes(option.value);
      })}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      styles={{
        menu: (provided: any) => ({
          ...provided,
          color: "black",
          transition: "opacity 0.5s",
        }),
        input: (provided: any) => ({
          ...provided,
          border: "none",
        }),
        control: (provided: any) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "24px",
        }),
        option: (provided: any, state: any) => ({
          background: "white",
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transition: "transform 0.15s", // Ensure the transition is applied
        }),
      }}
      classNames={{
        menu: (state) =>
          `text-gray-800 px-2 py-2 text-sm font-normal font-['Lato'] leading-relaxed`,
        input: () =>
          "text-gray-800 px-4 py-2 text-sm font-normal font-['Lato'] leading-relaxed ",
        dropdownIndicator: (state) =>
          `${
            state.selectProps.menuIsOpen
              ? "rotate-180 cursor-pointer"
              : "rotate-0 cursor-pointer"
          }`,
      }}
      components={{
        ValueContainer: multiCheckValueContainer,
        Option: multiCheckOption,
      }}
      onChange={(e) => {
        if (Array.isArray(e)) {
          handleFilterInput(e.map((x: Option) => x.value));
        }
      }}
    />
  );
}

// Component which renders a multi select filter
export function MultiSelectFilter({
  column: { filterValue, preFilteredRows, setFilter, id },
}: {
  column: HeaderGroup;
}) {
  // load preference values from localStorage
  const tempPreferences = JSON.parse(
    localStorage.getItem("tempPreferences") || "{}"
  );
  const filterPreferenceArray = tempPreferences?.filterPreferences || [
    { id: id, value: [] },
  ];

  // parse localStorage contents into filter values for this column
  const thisFilter =
    Array.isArray(filterPreferenceArray) &&
    filterPreferenceArray.find(
      (pref: { id: string; value: string[] }) => pref.id === id
    );

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: Set<string> = new Set();
    preFilteredRows.forEach((rowz: any) => {
      options.add(rowz.values[id]);
    });
    return Array.from(options).map((x) => ({ value: x, label: x }));
  }, [id, preFilteredRows]);

  const [thisValue, setThisValue] = React.useState(thisFilter?.value || []);

  useEffect(() => {
    if (thisValue) {
      setFilter(thisValue);
    } else {
      setThisValue(filterValue || []);
    }
  }, []);

  /**
   * takes an array of selected option values and updates the filter and localStorage to
   * reflect the new selection
   * @param value string array of currently selected options
   */
  const handleFilterInput = (value: string[]) => {
    setFilter(value || undefined); // Set undefined to remove the filter entirely
    setThisValue(value || undefined);
    const newPref = {
      id: id,
      value: value,
    };
    if (
      // if there is no existing preference for this column's filter, push a new entry
      !filterPreferenceArray.find(
        (pref: { id: string; value: string[] }) => pref.id === id
      )
    ) {
      filterPreferenceArray.push(newPref);
    } else {
      // if there is an existing preference, update the column's filter value
      const newPreferenceArray = filterPreferenceArray.map(
        (pref: { id: string; value: string[] }) => {
          if (pref.id === id) {
            return {
              id: id,
              value: value,
            };
          } else return pref;
        }
      );
      filterPreferenceArray.splice(
        0,
        filterPreferenceArray.length,
        ...newPreferenceArray
      );
    }
    // update local storage with new values
    localStorage.setItem(
      "tempPreferences",
      JSON.stringify({
        ...tempPreferences,
        filterPreferences: filterPreferenceArray,
      })
    );
  };

  // Render a multi-select box
  return (
    <Select
      options={options}
      isMulti
      // display selected options in Select box, whether new or pre-selected
      value={options.filter((option: { value: string; label: string }) =>
        thisValue.includes(option.value)
      )}
      styles={{
        menu: (provided: any) => ({
          ...provided,
          color: "black",
        }),
        input: (provided: any) => ({
          ...provided,
          border: "none",
        }),
        control: (provided: any) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "24px",
        }),
      }}
      onChange={(e) => handleFilterInput(e.map((x) => x.value))}
    />
  );
}

/**
 * Takes a string value and converts it to either a very high integer or very low integer,
 * depending on the sort direction.
 * @param value string value to be converted to integer
 * @param desc boolean value indicationg sort direction
 * @returns
 */
const rankLowest = (value: string, desc: boolean) => {
  if (value === "K") {
    // arbitrary high number less than infinity
    return desc ? Number.MIN_SAFE_INTEGER + 1 : Number.MAX_SAFE_INTEGER - 1;
  }
  if (value === "PK") {
    // PK to the bottom
    return desc ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
  }
  return 0;
};
/**
 * Custom sort function that is used to sort column data in the form {lowGrade - highGrade},
 * First using lowGrade, then using highGrade (if lowGrades are equal)
 * @param rowA
 * @param rowB
 * @param desc
 * @returns
 */
export const customGradeSort: any = (
  rowA: Row,
  rowB: Row,
  id: string,
  desc: boolean
) => {
  const lowestGradeOptions = ["K", "PK"];
  const initialLow = lowestGradeOptions.includes(rowA.values["lowGrade"])
    ? rankLowest(rowA.values["lowGrade"], desc)
    : parseInt(rowA.values["lowGrade"]);
  const nextLow = lowestGradeOptions.includes(rowB.values["lowGrade"])
    ? rankLowest(rowB.values["lowGrade"], desc)
    : parseInt(rowB.values["lowGrade"]);

  if (initialLow > nextLow) return 1;
  if (initialLow < nextLow) return -1;
  if (initialLow === nextLow) {
    const initialHigh = lowestGradeOptions.includes(rowA.values["highGrade"])
      ? rankLowest(rowA.values["highGrade"], desc)
      : parseInt(rowA.values["highGrade"]);
    const nextHigh = lowestGradeOptions.includes(rowB.values["highGrade"])
      ? rankLowest(rowB.values["highGrade"], desc)
      : parseInt(rowB.values["highGrade"]);
    if (initialHigh > nextHigh) return 1;
    if (initialHigh < nextHigh) return -1;
    return 0;
  }
  return 0;
};

export const fixedHeaderSort: any = (
  rowA: Row,
  rowB: Row,
  id: string,
  desc: boolean
) => {
  const a = rowA.values[id];
  const b = rowB.values[id];
  if (rowB.values["sectionName"] === WEIGHTED_TEXT) {
    return desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const sortTypes: Record<string, SortByFn<object>> = {
  customGradeSort: customGradeSort,
  fixedHeaderSort: fixedHeaderSort,
};

export default function DeltaMathTable({
  columns: userColumns,
  data,
  options,
  setSelectedRows,
  updateMutation,
  getRowProps,
  setCurrentTableIndex,
}: {
  columns: Array<Column>;
  data: Array<any>;
  options?: {
    globalFilter?: boolean;
    selectable?: boolean;
    showSelectAll?: boolean;
    preSelectedRows?: Record<string, boolean>;
    initialState?: {
      sortBy?: [{ id: string; desc: boolean }];
      hiddenColumns?: Array<any>;
      pageIndex?: number;
    };
    stickyHeader?: boolean;
    autoResetSortBy?: boolean;
    autoResetAll?: boolean;
    autoResetFilters?: boolean;
    autoResetGlobalFilter?: boolean;
    requireSelectOne?: boolean;
  };
  setSelectedRows?: any;
  updateMutation?: any;
  getRowProps?: any;
  setCurrentTableIndex?: React.Dispatch<React.SetStateAction<number>>;
}) {
  const defaultColumn: any = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { useAdminStyles } = useContext(AdminFeatureContext);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
    isAllRowsSelected,
    state: { pageIndex, selectedRowIds, globalFilter },
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns: userColumns,
      data,
      sortTypes,
      defaultColumn,
      updateMutation,
      initialState: options?.initialState,
      autoResetSortBy: true && options?.autoResetSortBy,
      autoResetAll: true && options?.autoResetAll,
      autoResetFilters: true && options?.autoResetFilters,
      autoResetGlobalFilter: true && options?.autoResetGlobalFilter,
      enableMultiRowSelection: false,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      options?.selectable
        ? hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              align: "left",
              width: "12px",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) =>
                options.showSelectAll && (
                  <RowSelectCheckbox {...getToggleAllRowsSelectedProps()} />
                ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }: { row: Row }) => {
                return (
                  <RowSelectCheckbox
                    key={`row-select-${row.id}`}
                    {...row.getToggleRowSelectedProps()}
                  />
                );
              },
            },
            ...columns,
          ])
        : {};
    }
  );

  useEffect(() => {
    setPageSize(250);
  }, []);

  //this is a hack for making sure that trying to maintain page on data update doesn't break the view when you use filters. Only invoking if setCurrentTableIndex is passed in as a prop
  useEffect(() => {
    if (setCurrentTableIndex) {
      gotoPage(0);
    }
  }, [pageCount, setCurrentTableIndex]);

  //this allows for maintaining the page the viewer was on. The current use case is not to reset the page when a re-render occurs (in particular b/c of a data update)
  useEffect(() => {
    if (setCurrentTableIndex) {
      setCurrentTableIndex(pageIndex);
    }
  }, [pageIndex, setCurrentTableIndex]);

  useEffect(() => {
    if (options?.preSelectedRows) {
      for (const [row, selected] of Object.entries(options?.preSelectedRows)) {
        toggleRowSelected(row, selected);
      }
    }
  }, [options?.preSelectedRows]);

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedRowIds);
    }
  }, [selectedFlatRows, options?.preSelectedRows, isAllRowsSelected]);

  const headerStyle = options?.stickyHeader
    ? {
        wrapperClass: "h-screen overflow-x-scroll  border rounded-md",
        headerClass: clsx(
          useAdminStyles
            ? "text-md px-6 py-4 font-semibold m-4"
            : "sticky top-0 bg-white"
        ),
      }
    : {
        wrapperClass: clsx(
          useAdminStyles ? "" : "border",
          "overflow-x-auto rounded-md"
        ),
        headerClass: clsx(useAdminStyles ? "bg-white px-6 py-4" : "bg-gray-50"),
      };

  return (
    <div className="flex flex-col">
      <div className={headerStyle.wrapperClass}>
        <div
          className={clsx(
            useAdminStyles
              ? "inline-block min-w-full"
              : "inline-block min-w-full align-middle"
          )}
        >
          {options?.globalFilter && (
            <div className="flex flex-col">
              <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              {useAdminStyles && options?.globalFilter && (
                <span className="w-full border border-b"></span>
              )}
            </div>
          )}

          <div
            className={clsx(
              useAdminStyles
                ? "-mb-1"
                : "shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            )}
          >
            <table
              className="min-w-full divide-y divide-gray-300"
              {...getTableProps()}
            >
              <thead className={headerStyle.headerClass}>
                {headerGroups.map((headerGroup, i) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={`header-group-${i}`}
                  >
                    {headerGroup.headers.map((column, j) => (
                      <th
                        {...column.getHeaderProps({
                          style: {
                            textAlign: column.align,
                            minWidth: column.minWidth,
                            width: column.width,
                          },
                        })}
                        key={`group-${i}-header-${j}`}
                        scope="col"
                        className={clsx(
                          useAdminStyles
                            ? "font-sans text-sm font-semibold"
                            : "text-md bg-dm-light-blue p-3 font-semibold text-white",
                          "px-6 py-4"
                        )}
                      >
                        <span
                          className="inline-flex"
                          {...column.getSortByToggleProps()}
                        >
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              // sort arrow down
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={clsx(
                                  useAdminStyles ? "text-dm-gray-200" : "",
                                  "ml-2 h-6 w-6"
                                )}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
                                />
                              </svg>
                            ) : (
                              // sort arrow up
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={clsx(
                                  useAdminStyles ? "text-dm-gray-200" : "",
                                  "ml-2 h-6 w-6"
                                )}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
                                />
                              </svg>
                            )
                          ) : (
                            // click Header to toggle sort
                            ""
                          )}
                        </span>
                        {/* Render the columns filter UI */}
                        <div>
                          {column.canFilter
                            ? column.render("Filter", {
                                column,
                                id: column.id,
                              })
                            : null}{" "}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-200 bg-white"
              >
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps(getRowProps && getRowProps(row))}
                      onClick={() => {
                        if (options?.requireSelectOne) {
                          page.map((r) => {
                            if (r.id !== row.id) {
                              r.toggleRowSelected(false);
                            }
                          });
                        }
                        row.toggleRowSelected();
                      }}
                      className={clsx(
                        {
                          "cursor-pointer hover:bg-slate-300":
                            options?.selectable,
                          "bg-slate-200 hover:bg-slate-300":
                            options?.selectable && row.isSelected,
                        },
                        "px-6"
                      )}
                      key={`row-${index}`}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                textAlign: cell.column.align,
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                                whiteSpace: cell.column.wrap
                                  ? "normal"
                                  : "nowrap",
                              },
                            })}
                            className="text-md px-6 py-4 text-gray-900"
                            key={`row-${index}-cell-${cellIndex}`}
                          >
                            {cell.column["Header"] === "#"
                              ? index + 1
                              : cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {pageOptions.length > 1 && (
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Viewing Page{" "}
                    <span className="font-medium">{pageIndex + 1}</span> of{" "}
                    <span className="font-medium">{pageOptions.length}</span>
                  </p>
                </div>
                <div className="ml-8 flex flex-1 justify-start sm:justify-start">
                  <button
                    className={clsx(
                      !canPreviousPage && "invisible",
                      "relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:cursor-not-allowed disabled:bg-slate-300"
                    )}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    &larr; Previous
                  </button>
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className={clsx(
                      !canNextPage && "invisible",
                      "relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:cursor-not-allowed disabled:bg-slate-300"
                    )}
                  >
                    Next &rarr;
                  </button>
                </div>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { DeltaMathTable };
