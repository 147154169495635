import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Transition } from "@headlessui/react";
import axios from "axios";
import ReactPlayer from "react-player";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { findNearestUpcoming } from "../utils";
import { useUserContext } from "../../shared/contexts/UserContext";
import { REACT_APP_STUDENT_LINK, useDMQuery } from "../../utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import clsx from "clsx";
import { XIcon } from "@heroicons/react/outline";
import PictureInPictureIcon from "../components/icons/PictureInPictureIcon";
import { useLocation, useBeforeUnload } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { OnProgressProps } from "react-player/base";

const BLUE = "#dbeafe";
const GREEN = "#117035";

const VideoPlayer = ({
  solveSkill,
  showProgress = true,
  showInModal = false,
  setShowVideo,
}: {
  solveSkill: SolveSkill;
  showProgress?: boolean;
  showInModal?: boolean;
  setShowVideo: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const currentLocation = useLocation();
  const {
    dmAssignmentData,
    setDmAssignmentData,
    activeSection,
    assignmentsRefresh,
    setLoadingData,
    dmSectionsData,
  } = useContext(StudentSectionsContext);
  const token = userContext.getJWT();
  const videoRef = useRef<ReactPlayer>(null);

  // If the video is currently playing
  const [isPlaying, setIsPlaying] = useState(false);

  // Keep track of the full duration (length) of the video
  const videoDurationRef = useRef<number>(0);

  // Used to keep track of the play time of any watched segment, resets on pause, seek, etc.
  const [interval, setInterval] = useState<[number | null, number | null]>([
    null,
    null,
  ]);

  // We need to keep track of the interval in a ref so that we can submit the video progress to the server
  // during an unmount event
  const intervalRef = useRef<[number | null, number | null]>([null, null]);

  // State to store the video tracker data
  const [trackerData, setTrackerData] = useState<TrackerFormat | null>(null);

  // Manages behavior for the custom seek bar
  const [seekValue, setSeekValue] = useState<number>(0);

  // The grade, as initialized and mutated by way of solveSill
  const [grade, setGrade] = useState<number>(0);

  // Controls the progress gradient of the custom seek bar
  const [progressGradient, setProgressGradient] = useState<string>(BLUE);

  // Set to true if the api states the student is locked to another assignment
  const [restrictVideo, setRestrictVideo] = useState<boolean>(false);

  // Reference to the student assignment object
  // CC - I dont fully understand this but presumably we are using it to update the 'solveSkill' in the parent state
  // I have my doubts about this pattern, but it is what it is.
  const saRef = useRef<PartialSA>(solveSkill.sa);

  // Managing the Player in Player - CC: ???
  const [pipOpen, setPipOpen] = useState(false);

  // Used to deal with some conditional rendering
  const isTouchDevice: boolean = (window as any).is_touch_device();

  // Used to handle API rejections
  const isLti = !!localStorage.getItem("lti_assignment_payload");

  // Initialize the state
  const sk =
    solveSkill.ta.currentSkill.type_selected === true ||
    solveSkill.ta.currentSkill.single_problem === true
      ? (solveSkill.ta.currentSkill.skillcode as string)
      : solveSkill.ta.currentSkill.skill || solveSkill.ta.skillName;
  const videoSk = sk.includes("custom") ? sk : `custom_${sk}`;
  const { url: videoUrl, subtitleUrl } = getVideoUrl({
    sk: sk,
    type:
      solveSkill.ta.currentSkill.type === "youtube_video"
        ? "youtube_video"
        : "dm_video",
    video: solveSkill.ta.currentSkill.video,
  });

  // Nominally check if the student is locked to a different taId
  // This should probably be attached to the play even but later
  const { refetch, error } = useDMQuery({
    path: `/student/checkLock/${solveSkill.ta._id}`,
    method: "post",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      retry: false,
    },
  });

  const updateVideoData = useMutation(
    (body: UpdateVideoProgressRequest) => {
      return axios.post(
        deltamathAPI() + "/student/updateVideoProgress",
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess(data: any) {
        /**
         * The old endpoint, /update_student_video_progress returned the updated assignment object, which was then used
         * to set the client side variable, thus making sure the values were in sync after each update.  They should
         * be the same already, but this seems like a good additional safety measure to include.
         */
        if (data) {
          /** update existing ref of student assignment data to match returned values */
          saRef.current = {
            ...saRef.current,
            grade: data.data.grade,
            actuallyComplete: data.data.actuallyComplete,
            complete: data.data.complete,
            video_data: {
              ...saRef.current.video_data,
              [videoSk]: {
                ...saRef?.current?.video_data?.[videoSk],
                ...data.data.video_data[videoSk],
              },
            },
          };
          if (data.data.newtest) {
            saRef.current = {
              ...saRef.current,
              newtest: data.data.newtest,
            };
          }

          // Mutate the grade and tracker data state variables to incorporate the new video progress data
          if (data.data.video_data[videoSk]) {
            setGrade(data.data.video_data[videoSk].grade);
            setTrackerData(data.data.video_data[videoSk].tracker);
          }

          /** update dmAssignment data to reflect new video progress data */
          dmAssignmentData[activeSection].filter(
            (assignment: any, index: number) => {
              if (assignment.ta._id === solveSkill.ta._id) {
                const assignmentObj = { ...dmAssignmentData };
                assignmentObj[activeSection][index].sa = {
                  ...assignmentObj[activeSection][index].sa,
                  ...saRef.current,
                };
                setDmAssignmentData({ ...assignmentObj });
              }
            }
          );
        }
        if (data?.data?.message) {
          console.log({ message: data.data.message });
          toastContext.addToast({
            status: "Error",
            message: "There was an issue saving your video progress.",
          });
        }
      },
      onError: (error: any) => {
        const errorStatus = error?.response?.status;

        if (
          (errorStatus === 409 || errorStatus === 422) &&
          !userContext.getIsLocked() &&
          !isLti
        ) {
          assignmentsRefresh();
          navigate(
            `${REACT_APP_STUDENT_LINK}/${
              findNearestUpcoming(dmAssignmentData) ?? dmSectionsData[0]?._id
            }/upcoming`
          );

          setTimeout(() => {
            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: true,
              error: true,
              title: "Error",
              message: `${
                error?.response?.data?.message ||
                error?.message ||
                error?.error ||
                ""
              }`,
            }));
          }, 100);
        }
      },
    }
  );

  // This function is responsible for submitting the video progress to the server
  const submitVideoProgress = () => {
    const [start, end] = intervalRef.current;
    if (typeof start === "number" && typeof end === "number") {
      // Submit the video progress to the server
      updateVideoData.mutate({
        taId: solveSkill.ta._id,

        // We could probably just pass sk here, the back-end seems to behave the same either way
        // However in the interest of matching current behavior...
        sk: solveSkill.ta.currentSkill.skill ? `custom_${sk}` : sk,
        video_sk: videoSk,
        tracker: {
          duration: videoDurationRef.current,
          hist: [[Math.round(Date.now() / 1000), [start, end]]],
        },
        seconds: end - start,
        duration: videoDurationRef.current,
        last_edit: solveSkill.ta.last_edit,
      });

      // Seek to the value of interval[1] - this ensure we don't get gaps of like 100ms in the progress bar
      if (videoRef.current) {
        videoRef.current.seekTo(end, "seconds");
      }
    }
  };

  // Initialize the grade based on the video data
  useEffect(() => {
    const newGrade = solveSkill?.sa?.video_data?.[videoSk]?.grade || 0;
    setGrade(newGrade);
  }, [solveSkill, videoSk]);

  // This use effect will submit the video progress to the server whenever the video stops playing
  useEffect(() => {
    if (isPlaying === false) {
      submitVideoProgress();
    }
  }, [isPlaying]);

  // Update the intervalRef whenever the interval state changes
  useEffect(() => {
    intervalRef.current = interval;
  }, [interval]);

  // On unmount, submit the video progress to the server
  useEffect(() => {
    return () => {
      submitVideoProgress();
    };
  }, []);

  // Listen for events which would navigate from the page, prompting the user and ensuring progress is saved
  const windowClosing = (event: BeforeUnloadEvent) => {
    if (!isPlaying) {
      return;
    }
    setIsPlaying(false);

    event.preventDefault();
    event.returnValue = "onbeforeunload";
    return "onbeforeunload";
  };
  useBeforeUnload(windowClosing);

  // Listen for changes in the current location, and pause the video if the location changes
  useEffect(() => {
    if (isPlaying) {
      setIsPlaying(false);
    }
  }, [currentLocation]);

  // This useEffect is responsible for updating the progress gradient when the trackerData, grade, or interval changes
  useEffect(() => {
    if (trackerData) {
      setProgressGradient(
        getColorSpan(
          {
            ...trackerData,

            // Conditionally add the new interval to the hist array
            // We intentionally do not mutate the trackerData object
            // As that will happen in the updateVideoData mutation
            hist:
              interval[0] !== null && interval[1] !== null
                ? [
                    ...trackerData.hist,
                    [Math.round(Date.now() / 1000), [interval[0], interval[1]]],
                  ]
                : trackerData.hist,
          },
          grade,
          videoDurationRef.current ? videoDurationRef.current : 1
        )
      );
    }
  }, [trackerData, grade, interval]);

  // This is triggering the warning from the call to checkLock - probably does not belong here
  useEffect(() => {
    if (error) {
      setRestrictVideo(true);
    }
  }, [error]);

  // Used to manage state of the video player in modal - CC: is this strictly neccessary?
  useEffect(() => {
    const handlePipClose = () => setPipOpen(false);
    const handlePipOpen = () => setPipOpen(true);
    const videoElement = document.querySelector("video");
    if (videoUrl && videoElement && showInModal) {
      videoElement.addEventListener("enterpictureinpicture", handlePipOpen);
      videoElement.addEventListener("leavepictureinpicture", handlePipClose);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          "enterpictureinpicture",
          handlePipOpen
        );
        videoElement.removeEventListener(
          "leavepictureinpicture",
          handlePipClose
        );
      }
    };
  }, [showInModal, videoUrl]);

  // Collectively used to manage the hover state of the video player in modal - CC: is this strictly neccessary?
  const [mouseInHouse, setMouseInHouse] = useState(true);
  const [hoverInterval, setHoverInterval] = useState(true);
  const handleMouseInHouse = () => {
    setMouseInHouse(true);

    if (hoverInterval) {
      setHoverInterval(false);
      setTimeout(() => {
        /** this causes a brief thrash when the interval runs out.  Is there a better way of handing this? */
        setMouseInHouse(false);
        setHoverInterval(true);
        /** does not quite align with native timeout because this gets reset onMouseMove */
      }, 3500);
    }
  };

  // Methods collectively responsible for managing interactions with the custom seek bar
  const handleSeekMouseDown = () => {
    setIsPlaying(false);
  };
  const handleSeekMouseUp = (e: React.PointerEvent<HTMLInputElement>) => {
    setIsPlaying(true);
    if (videoRef.current !== null) {
      videoRef.current.seekTo(parseFloat(e.currentTarget.value));
    }
  };
  const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeekValue(Number(e.currentTarget.value));
  };

  /**
   * Triggered the first time the video starts playing.
   * Used to set the video duration and start time.
   */
  const onStart = () => {
    if (videoRef.current) {
      videoDurationRef.current = videoRef.current.getDuration();

      const videoData = saRef.current.video_data || {};
      const videoDataTemp = videoData[videoSk] || {
        grade: 0,
        tracker: {
          duration: videoDurationRef.current,
          hist: [],
        },
      };

      // In the past we would delete the tracker when the grade reached 100
      // We need to handle this case by inserting a completed interval
      if (videoDataTemp.grade === 100 && !videoDataTemp.tracker) {
        videoDataTemp.tracker = {
          duration: videoDurationRef.current,
          hist: [
            [Math.round(Date.now() / 1000), [0, videoDurationRef.current]],
          ],
        };
      }

      // If there is no hist defined it implies that this videos is still using the deprecated format
      // And so we ned to convert it to the new format
      if (!videoDataTemp?.tracker?.hist) {
        videoDataTemp.tracker = migrateTracker(
          videoDataTemp.tracker as DeprecatedTrackerFormat,
          videoDurationRef.current
        );
      }

      setTrackerData(videoDataTemp.tracker);
    }
  };

  /**
   * Trggered any time the video starts playing.
   * We use this to set the time the video started playing.
   */
  const onPlay = () => {
    if (videoRef.current) {
      setInterval([videoRef.current.getCurrentTime(), null]);
    }

    setIsPlaying(true);

    if (
      userContext.getIsLocked() &&
      userContext.getTAid() !== solveSkill.ta._id
    ) {
      setRestrictVideo(true);
    } else {
      /** 8/11/23 Zach asked to remove the check locked on ever play to limit backend calls before release */
      // Refetch on play to check if student is locked again
      // refetch();
      // CC - We can probably enforce this on the back-end without any additional calls
      // We will leave that project for another day
    }
  };

  /**
   * Runs approximately every 1 second while the video is playing.
   * @param state The state of the video player
   */
  const onProgress = (state: OnProgressProps) => {
    // We only want to update the interval if the video is playing
    if (isPlaying === false) {
      return;
    }

    setInterval([interval[0], state.playedSeconds]);
    setSeekValue(state.played);

    // Update the grade based on the progress
    if (trackerData) {
      const newGrade = calculateVideoProgress({
        ...trackerData,
        hist: [
          ...trackerData.hist,
          [
            Math.round(Date.now() / 1000),
            [interval[0] || 0, state.playedSeconds],
          ],
        ],
      });
      setGrade(newGrade);

      // Update the ref of the student assignment object to reflect the new grade
      if (saRef?.current?.video_data?.[videoSk]) {
        saRef.current.video_data[videoSk] = {
          ...saRef.current.video_data[videoSk],
          grade: newGrade,
        };

        // Copied from onSuccess of updateVideoData - this gives of a bad smell, but not going to fix it today
        // It ensures that the assignment progress stays in sync with the sidebar.
        dmAssignmentData[activeSection].filter(
          (assignment: any, index: number) => {
            if (assignment.ta._id === solveSkill.ta._id) {
              const assignmentObj = { ...dmAssignmentData };
              assignmentObj[activeSection][index].sa = {
                ...assignmentObj[activeSection][index].sa,
                ...saRef.current,
              };
              setDmAssignmentData({ ...assignmentObj });
            }
          }
        );
      }
    }
  };

  /**
   * Runs when the video is paused
   */
  const onPause = () => {
    setIsPlaying(false);
  };

  /**
   * Runs when the video is completed
   */
  const onEnded = () => {
    setIsPlaying(false);
  };

  return (
    <>
      {showInModal ? (
        <FocusTrap paused={pipOpen}>
          <div
            id="video-modal-container"
            className={clsx(
              pipOpen ? "hidden" : "block",
              "fixed inset-0 z-50 overflow-y-auto"
            )}
            onMouseMove={() => handleMouseInHouse()}
            onMouseLeave={() => setMouseInHouse(false)}
          >
            {!pipOpen && (
              <div
                className={clsx("fixed inset-0 block bg-black bg-opacity-60")}
              />
            )}
            <div className="flex h-screen max-h-screen flex-col justify-center overflow-hidden p-4 sm:p-8">
              <div className="mx-auto flex w-full max-w-5xl transform flex-col overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div
                  className={clsx(
                    mouseInHouse || !isPlaying
                      ? "block bg-gradient-to-b from-black"
                      : "hidden",
                    "absolute z-50 h-1/4 w-full from-black transition delay-300 ease-in-out sm:h-1/6"
                  )}
                >
                  <button
                    type="button"
                    className="absolute left-2 top-2 rounded-md text-white focus:outline-dm-brand-blue-500 focus:ring-offset-2 sm:left-6 sm:top-6"
                    onClick={() => {
                      onPause();
                      setShowVideo(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {!isTouchDevice && (
                    <button
                      type="button"
                      className="absolute right-2 top-2 rounded-md focus:outline-dm-brand-blue-500 focus:ring-offset-2 sm:right-6 sm:top-6"
                      onClick={() => {
                        setPipOpen(true);
                      }}
                    >
                      <span className="sr-only">Picture in picture</span>
                      <PictureInPictureIcon classes="h-8 w-8" />
                    </button>
                  )}
                </div>
                <div
                  id="video-modal"
                  className="relative grow overflow-y-auto bg-white"
                  aria-live="polite"
                >
                  <div className="text-sm" id="foo">
                    {videoUrl && userContext.getJWT() && (
                      <ReactPlayer
                        playing={isPlaying}
                        ref={videoRef}
                        url={!restrictVideo ? videoUrl : undefined}
                        controls={true}
                        style={{
                          margin: "0 auto auto",
                          maxWidth: "100%",
                        }}
                        height="100%"
                        width="100%"
                        onStart={onStart}
                        onPlay={onPlay}
                        onProgress={onProgress}
                        onPause={onPause}
                        onEnded={onEnded}
                        onDisablePIP={() => setPipOpen(false)}
                        pip={pipOpen}
                        config={{
                          file: {
                            hlsOptions: {
                              forceHLS: true,
                              maxMaxBufferLength: 30,
                            },
                            attributes: { crossOrigin: "anonymous" },
                            tracks: [
                              {
                                label: "English",
                                kind: "captions",
                                src: subtitleUrl,
                                srcLang: "en",
                                default: false,
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FocusTrap>
      ) : (
        <Transition
          as="div"
          show={true}
          appear={true}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div
            id="assigned-video"
            className={clsx(
              showProgress ? "p-9" : "",
              "w-full rounded-lg bg-white shadow-md"
            )}
          >
            {solveSkill?.ta?.currentSkill && (
              <div className="mb-2">
                <h1 className="font-serif text-lg">Video Lesson</h1>
                <span className="text-[14px]">
                  Watch this video to complete the section.
                </span>
              </div>
            )}
            {videoUrl && userContext.getJWT() && (
              <ReactPlayer
                ref={videoRef}
                playing={isPlaying}
                url={!restrictVideo ? videoUrl : undefined}
                controls={true}
                style={{
                  margin: "0 auto auto",
                  maxWidth: "100%",
                }}
                height="100%"
                width="100%"
                onStart={onStart}
                onPlay={onPlay}
                onProgress={onProgress}
                onPause={onPause}
                onEnded={onEnded}
                pip={true}
                config={{
                  file: {
                    hlsOptions: {
                      forceHLS: true,
                    },
                    attributes: { crossOrigin: "anonymous" },
                    tracks: [
                      {
                        label: "English",
                        kind: "captions",
                        src: subtitleUrl,
                        srcLang: "en",
                        default: false,
                      },
                    ],
                  },
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      rel: 0,
                    },
                  },
                }}
              />
            )}
            <input
              type="range"
              value={seekValue}
              min={0}
              max={0.999999}
              step="any"
              /** use pointer events to capture both/either "onTouch" & "onMouse" events */
              onPointerDown={handleSeekMouseDown}
              onPointerUp={handleSeekMouseUp}
              onChange={handleSeekChange}
              style={{
                background: progressGradient,
              }}
              className="h-2 w-full"
            />
            {/** s-desktop-h2 lato  */}
            <span className="font-sans text-sm font-bold text-dm-charcoal-800">
              Complete: {grade}%
            </span>
          </div>
        </Transition>
      )}
    </>
  );
};

export default VideoPlayer;

/**
 * Format using the new tracker format.
 * @param tracker The video data from the student assignment object.
 * @param grade The grade of the video.
 * @param duration The total duration of the video.
 * @returns The linear gradient string for the video scrub bar.
 */
function getColorSpan(tracker: TrackerFormat, grade: number, duration: number) {
  if (grade === 100) {
    return GREEN;
  } else if (!tracker?.hist?.length) {
    return BLUE;
  }

  const mergedIntervals = computeMergedIntervals(tracker.hist);
  const segments: { width: number; color: boolean }[] = [];

  let lastEnd = 0;

  mergedIntervals.forEach(([start, end]) => {
    if (start > lastEnd) {
      segments.push({
        width: (start - lastEnd) / duration,
        color: false,
      });
    }
    segments.push({
      width: (end - start) / duration,
      color: true,
    });
    lastEnd = end;
  });

  if (lastEnd < duration) {
    segments.push({
      width: (duration - lastEnd) / duration,
      color: false,
    });
  }

  return `linear-gradient(90deg, ${segments
    .map((segment, index) => {
      const start =
        segments.slice(0, index).reduce((acc, seg) => acc + seg.width, 0) * 100;
      const end = start + segment.width * 100;
      return `${segment.color ? GREEN : BLUE} ${start}%, ${
        segment.color ? GREEN : BLUE
      } ${end}%`;
    })
    .join(", ")})`;
}

/**
 * Computes the merged intervals from the history of watched intervals.
 * @param hist The history of watched intervals.
 * @returns An array of merged intervals.
 */
function computeMergedIntervals(
  hist: [Timestamp, TrackerInterval][]
): TrackerInterval[] {
  const watchedIntervals: TrackerInterval[] = hist.map(
    ([, interval]) => interval
  );

  // Sort intervals by start time
  watchedIntervals.sort((a, b) => a[0] - b[0]);

  // Merge overlapping intervals
  const mergedIntervals: TrackerInterval[] = [];
  let currentInterval = watchedIntervals[0];

  for (let i = 1; i < watchedIntervals.length; i++) {
    const [currentStart, currentEnd] = currentInterval;
    const [nextStart, nextEnd] = watchedIntervals[i];

    if (nextStart <= currentEnd || nextStart - currentEnd <= 0.5) {
      // Overlapping or close intervals, merge them
      currentInterval = [currentStart, Math.max(currentEnd, nextEnd)];
    } else {
      // Non-overlapping interval, push the current interval and update
      mergedIntervals.push(currentInterval);
      currentInterval = watchedIntervals[i];
    }
  }

  // Push the last interval - if it exists
  if (currentInterval) {
    mergedIntervals.push(currentInterval);
  }

  return mergedIntervals;
}

/**
 * Migrates a tracker object from the deprecated format to the new format.
 *
 * This method converts a `DeprecatedTrackerFormat` to a `TrackerFormat` using the provided duration.
 *
 * @param tracker - The tracker object to be migrated. It is of type `DeprecatedTrackerFormat`.
 * @param duration - The total duration of the video.
 * @returns The migrated tracker object of type `TrackerFormat`.
 */
function migrateTracker(
  deprecatedTracker: DeprecatedTrackerFormat,
  duration: number
): TrackerFormat {
  const tracker: TrackerFormat = {
    duration: duration,
    hist: [],
  };

  const timestamp = Math.floor(Date.now() / 1000);
  const sortedEntries = Object.entries(deprecatedTracker)
    .map(([key, value]) => [parseInt(key, 10), value] as [number, boolean])
    .sort(([a], [b]) => a - b);

  let start: number | null = null;
  let end: number | null = null;

  for (const [key, value] of sortedEntries) {
    if (value) {
      if (start === null) {
        start = key;
      }
      end = key;
    } else {
      if (start !== null) {
        tracker.hist.push([timestamp, [start, key]]);
        start = null;
      }
    }
  }

  // Handle the case where the last segment is true
  if (start !== null && end !== null) {
    tracker.hist.push([timestamp, [start, end + 5]]);
  }

  return tracker;
}

/**
 * Calculate the video progress from the tracker.
 * @param tracker The tracker to calculate the progress from.
 * @returns The progress as a percentage.
 */
function calculateVideoProgress(tracker: TrackerFormat): number {
  // Use the computeMergedIntervals function to get merged intervals
  const mergedIntervals = computeMergedIntervals(tracker.hist);

  // No intervals, return 0 progress
  if (!mergedIntervals || mergedIntervals.length === 0) {
    return 0;
  }

  // Calculate the total watched time from merged intervals
  let totalWatchedTime = 0;
  for (const [start, end] of mergedIntervals) {
    totalWatchedTime += end - start;
  }

  // Calculate the progress as a percentage
  const progress = (totalWatchedTime / tracker.duration) * 100;

  // Ensure the progress is rounded and capped at 100
  return Math.min(Math.round(progress), 100);
}

/**
 * Helper function to get the video url and subtitle url for a given skill.
 * @param param The skillcode, type, and video url needed to construct the video url and subtitle url.
 * @returns The video url and subtitle url.
 */
const getVideoUrl = ({
  sk,
  type,
  video,
}: {
  sk: string;
  type?: "youtube_video" | "dm_video";
  video?: string;
}) => {
  let url = "";
  let subtitleUrl = "";

  if (type === "youtube_video") {
    url = `https://www.youtube.com/watch?v=${video}`;
  } else {
    const root = `https://videos.deltamath.com`;
    url = `${root}/${sk}/Default/HLS/${sk}.m3u8`;
    subtitleUrl = `${root}/captions/${sk}.mp4.vtt`;
  }

  return { url, subtitleUrl };
};

/**
 * The request payload for updating video progress.
 */
type UpdateVideoProgressRequest = {
  /** The teacher assignment id. */
  taId: number;
  /** The skill code which is indexing the ta.skills[sk] object. */
  sk: string;
  /** The skill code of the video which is being watched. */
  video_sk: string;
  /** The tracker object which tracks their progress in 5 second intervals. */
  tracker: TrackerFormat;
  /** The number of seconds watched **since the last post was made**. */
  seconds: number;
  /** The total duration of the video. */
  duration: number;
  /** The time at which the client believes the assignment was last edited. */
  last_edit: number;
};

type TrackerFormat = {
  /**
   * The total *(current)* duration of the video, in seconds.
   * Video duratio are not expected to change often, but can be updated if the video is replaced with a new version.
   * Additionally note we do not 'lookup' the video duration on the backend, it is provided by the client.
   */
  duration: number;

  /**
   * The history of video watches for the student. Note only captures up until video completions, any watches
   * after the video is completed are irrelevant to grade calculation, and omitted.
   * - The first element is the timestamp of the last update to the tracker,
   * - The second element is an array of tuples, where each tuple represents intervals of the video watched.
   */
  hist: [Timestamp, TrackerInterval][];
};

/**
 * Represents a closed interval of segments watched, where each segment represents a closed interval of seconds watched.
 * This is done primarily for legacy reasons to maintain backwards compatibility / interoperability with the old tracker format.
 * *(Which remains in use on the client and api layers)*.
 *
 * For example:
 * - [0, 5] represents the first 5 seconds of the video.
 * - [5, 11] represents the next 6 seconds, and so on.
 * - [0, 30.2] represents the first 30.2 seconds of the video.
 */
type TrackerInterval = [number, number];

/**
 * The epoch timestamp in seconds.
 */
type Timestamp = number;

/**
 * Prior to Dec '24 this was the format used in the sa.video_data.tracker field to keep track of a students progress on a video.
 * Keys of the object are numbers [0, 5, 10, 15, ... i+5, N] representing 5 second intervals of the video.
 * Values are booleans, true if the student has watched that segment, false otherwise.
 */
type DeprecatedTrackerFormat = Record<number, boolean>;

/**
 * A partial type definition for the Student Assignment Object
 * Only including those props which are relevant to the video progress tracking.
 */
type PartialSA = {
  /**
   * The grade after late credit is taken off
   */
  grade: number;

  /**
   * The grade of the student assuming it's not late, only when each skill is 3/3 and 5/5, etc...
   */
  complete: number;

  /**
   * In the free version of deltamath students may keep trying problems indefinately.
   * When max_problems is set (for tests it will always be 1) have a limited number of problems which can be solved.
   * acutallyComplete therefore measure 'work' done for a sk rather then the grade.
   * For example if complete were low, and actuallyComplete were high it would serve as an indicator that a student
   * is trying problems but getting them wrong.
   */
  actuallyComplete: number;

  /**
   * An object whose keys are skillcodes (sk) and values are progress on that skill
   * Only applies to video type skills.
   * For a deltamath video, the key is `custom_${skillcode}`
   * For a youtube video, they key is `custom${timestamp}`
   */
  video_data?: Record<string, VideoData>;

  /**
   * Student's new test score if credit back is allowed -- test score increases as they complete the assignment
   * Only defined for test corrections
   */
  newtest?: number;
};

/**
 * Definition of the video data object stored in the Student Assignment Object.
 */
type VideoData = {
  /**
   * Grade received based on video completion
   */
  grade: number;

  /**
   * The tracker object used to keep track of a students progress on a video.
   * NB: Historically this of the type `DeprecatedTrackerFormat`, but as of Dec '24 this is expected to be of type `TrackerFormat`.
   * The VideoService.migrateTracker method is used to convert the old format to the new format, and is called when
   * an UpdateVideoProgress Request and the old format is in use.
   */
  tracker?: TrackerFormat;
};

/**
 * A partial type definition for the Teacher Assignment Object
 * Only including those props which are relevant to the video progress tracking.
 */
type PartialTA = {
  /**
   * The 'id' of the teacher assignment.
   */
  _id: number;

  /**
   * The 'skillcode' (index of skills) of the skill being solved.
   */
  skillName: string;

  /**
   * The skilldata of th skill being solved, (value at the skillName index).
   */
  currentSkill: {
    /** If it is a video skill, the skillcode which it is being solved for */
    skill?: string;
    /** If it is a youtube video or deltamath video */
    type?:
      | "youtube_video"
      | "dm_video"
      | "subtype_selected"
      | "particular_problem";
    /** If it is a youtube video, the url to that video */
    video?: string;
    /** At the very least this will be defined for when type is subtype_selected. */
    skillcode?: string;
    /** At the very least this will be defined for when type is subtype_selected. */
    type_selected?: boolean;
    /** At the very least this will be defined for when type is particular_problem (assign this problem). */
    single_problem?: boolean;
  };

  /**
   * The actuall typing for the skills object is extensive, and not fully documented (or required) here.
   */
  skills: Record<string, unknown>;

  /**
   * The time at which the assignment was last edited.
   */
  last_edit: number;
};

/**
 * The 'SolveSkill' object contains the assignment context for the current skill being solved.
 * The name a bit of a misnomer but is preserved here to maintain consistency with the existing codebase.
 */
type SolveSkill = {
  ta: PartialTA;
  sa: PartialSA;
};
