import { noop } from "lodash";
import { createContext, useContext, useState } from "react";
import { ProblemIds } from "../types";

interface ProblemSupportContextState {
  problemIds: ProblemIds | undefined;
  setProblemIds: (ids: ProblemIds | undefined) => void;
  courseId: string | undefined;
  setCourseId: (id: string | undefined) => void;
  unitId: string | undefined;
  setUnitId: (id: string | undefined) => void;
  subunitId: string | undefined;
  setSubunitId: (id: string | undefined) => void;
}

const ProblemSupportContext = createContext<ProblemSupportContextState>({
  problemIds: undefined,
  setProblemIds: noop,
  courseId: undefined,
  setCourseId: noop,
  unitId: undefined,
  setUnitId: noop,
  subunitId: undefined,
  setSubunitId: noop,
});

/**
 * Component that stores the ids for a specific problem
 */
export const ProblemSupportContextProvider: React.FC = ({ children }) => {
  const [problemIds, setProblemIds] = useState<ProblemIds | undefined>();
  const [courseId, setCourseId] = useState<string | undefined>();
  const [unitId, setUnitId] = useState<string | undefined>();
  const [subunitId, setSubunitId] = useState<string | undefined>();

  return (
    <ProblemSupportContext.Provider
      value={{
        problemIds,
        setProblemIds,
        courseId,
        setCourseId,
        unitId,
        setUnitId,
        subunitId,
        setSubunitId,
      }}
    >
      {children}
    </ProblemSupportContext.Provider>
  );
};

/** Hook to get problem support context */
export function useProblemSupportContext() {
  return useContext(ProblemSupportContext);
}
