import { NavLink } from "react-router-dom";
import { executeQuery, REACT_APP_MANAGER_LINK } from "../../../utils";
import { useQuery } from "react-query";
import { SupportAction } from "./types";
import { useDocumentTitle } from "usehooks-ts";

export const SupportActionsListPage = () => {
  useDocumentTitle("Support Actions | DeltaMath Manager");

  const { data } = useQuery<{ actions: SupportAction[] }>(
    "supportActions",
    () => executeQuery({ path: "/manager_new/support/actions/list" }),
    { refetchOnWindowFocus: false }
  );

  return (
    <div className="flex flex-col gap-6 p-6">
      <h1 className="mb-1 text-2xl font-bold">Support Actions</h1>
      <ul
        className="grid w-full justify-start gap-4"
        style={{ gridTemplateColumns: "repeat(auto-fill, 300px)" }}
      >
        {data?.actions.map((action: any) => (
          <li key={action.key}>
            <NavLink
              className="flex h-full w-full flex-col gap-1 rounded-md border-2 border-dm-charcoal-200 bg-white p-4 hover:border-dm-brand-blue-500"
              to={`${REACT_APP_MANAGER_LINK}/support/actions/${action.key}`}
            >
              <h3 className="font-bold">{action.name}</h3>
              <p className="text-sm text-dm-charcoal-500">
                {action.description}
              </p>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
