import { useEffect, useRef, useState } from "react";
import { CheckAnswerResponse, LogData, Problem } from "../../types";
import clsx from "clsx";
import RenderLines from "./RenderLines";
import {
  eventCleanUp,
  generateMQ,
  getRenderMathSettings,
  processlines,
  resizeKatex,
} from "../../../student/utils";
import { cloneDeep, debounce, isEqual } from "lodash";
import renderMathInElement from "../../../student/utils/auto-render";
import Answer from "../Answer/Answer";
import { useFocusContext } from "../../contexts/FocusContext";
import "katex/dist/katex.min.css";
import { PAGE_TYPE_ID_MAP } from "../../../shared/constants";
import { EventMap, PageType } from "../../../shared/types";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { viewedAssessmentProblemLogEvent } from "../../analytics/events";
import { useProblemSolvingContext } from "../../contexts/ProblemSolvingContext";
import { useParams } from "react-router-dom";

type Props = {
  problem: Problem;
  checkAnswer: (body: string) => void;
  isCheckAnswerLoading: boolean;
  elapsedTime?: number;
  elapsedTestTime?: number;
  checkAnswerResponse?: CheckAnswerResponse;
  unsubmitAllowed: boolean;
  setUpdatedProblem: (problem: Problem) => void;
  externalFileData?: any[];
  showSolutions: boolean;
  skippedProblem?: boolean;
  unsubmit?: () => void;
  hideAnswer?: boolean;
  inModal?: boolean;
  attemptText?: string;
  header?: React.ReactNode;
  renderKey?: number;
  noBorder?: boolean;
  logData?: LogData;
  noAnalytics?: boolean;
};

const ProblemDisplay = (props: Props) => {
  const { indexOfSkill } = useParams();
  const focusContext = useFocusContext();
  const [isMathQuillLoaded, setIsMathQuillLoaded] = useState<boolean>(false);
  const [problemScriptState, setProblemScriptState] = useState<{
    questionCode: boolean;
    questionScript: boolean;
    solutionCode: boolean;
    solutionScript: boolean;
  }>({
    questionCode: false,
    questionScript: false,
    solutionCode: false,
    solutionScript: false,
  });
  const [katexResizingData, setKatexResizingData] = useState<any>({});
  const makeDisplayStyle = (window as any).makeDisplayStyle;
  const mathBlockContent = useRef<null | HTMLDivElement>(null);

  const { assignment } = useProblemSolvingContext();
  const { track, getAssignmentData } = useLearnerAnalytics();

  const prompt =
    typeof props.problem.prompt === "string" &&
    !props.problem.prompt.includes("displayStyle")
      ? makeDisplayStyle(props.problem.prompt)
      : props.problem.prompt;

  const pageType: PageType = props.inModal
    ? "modal"
    : props.showSolutions
    ? "solution"
    : "question";

  const pageID = PAGE_TYPE_ID_MAP[pageType];

  const solutionPageID = props.inModal
    ? PAGE_TYPE_ID_MAP["modal"]
    : PAGE_TYPE_ID_MAP["solution"];

  /* On browser resize, call resizeKatex() to readjust the KaTeX width */
  useEffect(() => {
    const handleResize = () => {
      setKatexResizingData((prevState: any) => ({
        ...prevState,
        [props.showSolutions ? "solution" : "question"]: resizeKatex(
          pageType,
          prevState
        ),
      }));
    };

    const debounce_resize = debounce(handleResize, 150);

    handleResize();
    window.addEventListener("resize", debounce_resize);
    return () => {
      window.removeEventListener("resize", debounce_resize);
    };
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: the order of this may need to be adjusted
  // THIS NEEDS TO BE DONE LAST
  useEffect(() => {
    if (renderMathInElement) {
      renderMathInElement(
        document.getElementById(`mathBlock${props.inModal ? "-modal" : ""}`),
        getRenderMathSettings()
      );
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.problem]);

  useEffect(() => {
    if (renderMathInElement) {
      if (
        (problemScriptState.questionCode &&
          problemScriptState.questionScript) ||
        (problemScriptState.solutionCode && problemScriptState.solutionScript)
      ) {
        renderMathInElement(
          document.getElementById(`mathBlock${props.inModal ? "-modal" : ""}`),
          getRenderMathSettings()
        );
      }
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderMathInElement, problemScriptState]);

  /* useEffect to create the MathQuill fields in custom problems */
  useEffect(() => {
    let focusEvents: EventMap = new Map();

    if (problemScriptState.questionCode && !isMathQuillLoaded) {
      const config = props.problem?.data?.binomialExponent
        ? { charsThatBreakOutOfSupSub: "" }
        : {};

      const focusFunc = (el: Element, mq: any, isTouchDevice: boolean) => {
        focusContext.setMQ(el);
        // automatically bring up keyboard for touch screens on mq focus
        if (isTouchDevice) {
          focusContext.openCalculator(false);
          focusContext.openKeyboard(true);
        }
      };

      focusEvents = generateMQ(focusFunc, config);

      setIsMathQuillLoaded(true);
    }
    return () => {
      if (isMathQuillLoaded) {
        eventCleanUp(focusEvents);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [problemScriptState.questionCode, isMathQuillLoaded]);

  useEffect(() => {
    // These are possibly used by inlineSolutionCode or inlineQuestionCode when eval()
    // Guarantees data & page are in scope.
    // !!DO NOT DELETE!!

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = props.problem.data?.data || {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const page = document.getElementById(pageID);

    const newProblemScriptState = cloneDeep(problemScriptState);

    if (props.problem.data.inlineFirst === undefined) {
      if (!props.showSolutions && !problemScriptState.questionCode) {
        // Eval is necessary for delta graphs
        // eslint-disable-next-line no-eval
        eval(`window.deltaGraphs2 = [];${props.problem.inlineQuestionCode}`);
        newProblemScriptState.questionCode = true;
      }

      if (
        !props.showSolutions &&
        props.problem.problemScripts?.questionScripts &&
        !problemScriptState.questionScript &&
        problemScriptState.questionCode
      ) {
        // TODO: Ref?
        props.problem.problemScripts.questionScripts(
          document.getElementById(PAGE_TYPE_ID_MAP["question"])
        );
        newProblemScriptState.questionScript = true;
        // We are weirdly updating the problem after we run the problem scripts.
        // This is to make sure we have the CORRECT and final reference to problem object
        // that will then live in state in the CustomFileWrapper.tsx component and will get
        // passed into any subsiquent components so everything has the exact same and correct
        // object
        props.setUpdatedProblem(props.problem);
      }

      if (props.showSolutions && !problemScriptState.solutionCode) {
        // Eval is necessary for delta graphs
        // eslint-disable-next-line no-eval
        eval(`window.deltaGraphs = [];${props.problem.inlineSolutionCode};`);
        newProblemScriptState.solutionCode = true;
      }

      if (
        props.problem.problemScripts?.solutionScripts &&
        props.showSolutions &&
        !problemScriptState.solutionScript &&
        props.problem.lines
      ) {
        props.problem.problemScripts.solutionScripts(
          document.getElementById(solutionPageID)
        );
        newProblemScriptState.solutionScript = true;
      }
    } else if (props.problem.data.inlineFirst === false) {
      if (
        !props.showSolutions &&
        props.problem.problemScripts?.questionScripts &&
        !problemScriptState.questionScript
      ) {
        // TODO: Ref?
        props.problem.problemScripts.questionScripts(
          document.getElementById(PAGE_TYPE_ID_MAP["question"])
        );
        newProblemScriptState.questionScript = true;
      }

      if (
        !props.showSolutions &&
        !problemScriptState.questionCode &&
        problemScriptState.questionScript
      ) {
        // Eval is necessary for delta graphs
        // eslint-disable-next-line no-eval
        eval(`window.deltaGraphs2 = [];${props.problem.inlineQuestionCode}`);
        newProblemScriptState.questionCode = true;
      }

      if (
        props.problem.problemScripts?.solutionScripts &&
        props.showSolutions &&
        !problemScriptState.solutionScript &&
        props.problem.lines
      ) {
        props.problem.problemScripts.solutionScripts(
          document.getElementById(solutionPageID)
        );
        newProblemScriptState.solutionScript = true;
      }

      if (
        props.showSolutions &&
        !problemScriptState.solutionCode &&
        problemScriptState.solutionScript
      ) {
        // Eval is necessary for delta graphs
        // eslint-disable-next-line no-eval
        eval(`window.deltaGraphs = [];${props.problem.inlineSolutionCode};`);
        newProblemScriptState.solutionCode = true;
      }
    }

    // increase input width as more data is typed in
    if (
      document.querySelector(
        'script[src*="/assets/jquery.auto-grow-input.min.js"]'
      ) &&
      (window as any).$?.fn?.autoGrowInput
    ) {
      // Eval is necessary for delta graphs
      // eslint-disable-next-line no-eval
      eval(`$('.display-problem input:not(.no-auto-grow)').autoGrowInput();`);
    }

    // Only if we have changed the state do we need to set it
    // This will stop the use effect from re-running indefinitely
    if (!isEqual(problemScriptState, newProblemScriptState)) {
      setProblemScriptState(newProblemScriptState);
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [problemScriptState, props.showSolutions]);

  useEffect(() => {
    // the course context is used inside analytics context.
    if (
      props.showSolutions &&
      !props.noAnalytics &&
      assignment.type !== "practice"
    ) {
      track(
        viewedAssessmentProblemLogEvent({
          ...getAssignmentData(assignment.type),
          skillCode: props.problem.skillcode,
          skillNumber: indexOfSkill ? parseInt(indexOfSkill, 10) : -1,
        })
      );
    }
    // Only want this to run when first rendered. This component will remount when switching problems
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        focusContext.keyboardOpen && !props.inModal ? "mb-56 sm:mb-48" : ""
      }
    >
      <div
        id={`mathBlock${props.inModal ? "-modal" : ""}`}
        className={clsx(
          `relative sm:rounded-lg ${
            !props.noBorder
              ? "border border-dm-charcoal-100 p-4 sm:p-5 lg:p-8"
              : ""
          } bg-white text-left !font-serif`
        )}
        key={
          props.renderKey
            ? `problem-display-${props.problem._id}-${props.renderKey}`
            : ""
        }
      >
        {props.header}

        <div ref={mathBlockContent}>
          <div
            id="problemPrompt"
            role="group"
            className="text-base font-medium leading-7 focus:outline-none"
            dangerouslySetInnerHTML={{
              __html: prompt,
            }}
          />
          <div
            key={`questionpage:${props.problem._id}`}
            id={PAGE_TYPE_ID_MAP["question"]}
            className={clsx(
              "display-problem question-page",
              props.showSolutions ? "hidden" : "block"
            )}
          >
            <RenderLines
              displayData={processlines(props.problem.qlines)}
              problemData={props.problem}
              resizingData={katexResizingData["question"]}
              locString="question"
            />
          </div>
          <div
            key={`problempage:${props.problem?._id}`}
            id={solutionPageID}
            className={clsx(
              "display-problem problem-page", // TODO: double check these
              !props.showSolutions ? "hidden" : "block"
            )}
          >
            <RenderLines
              displayData={processlines(props.problem.lines)}
              problemData={props.problem}
              resizingData={katexResizingData["solution"]}
              locString="solution"
            />
          </div>
          <div
            id="answer_area"
            className={props.hideAnswer ? "hidden" : "block"}
          >
            <div className="relative mt-8 rounded bg-dm-background-blue-100 p-4 sm:p-6">
              <Answer
                problem={props.problem}
                problemData={props.problem.data}
                isMathQuillLoaded={isMathQuillLoaded}
                checkAnswer={props.checkAnswer}
                isCheckAnswerLoading={props.isCheckAnswerLoading}
                elapsedTime={props.elapsedTime}
                elapsedTestTime={props.elapsedTestTime}
                checkAnswerResponse={props.checkAnswerResponse}
                unsubmitAllowed={props.unsubmitAllowed && !props.showSolutions}
                externalFileData={props.externalFileData}
                skippedProblem={props.skippedProblem}
                unsubmit={props.unsubmit}
                attemptText={props.attemptText}
                logData={props.logData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProblemDisplay;
