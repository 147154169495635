import { Course } from "../../types";
import { CourseCard } from "./CourseCard";
import { CoursesHeader } from "./CoursesHeader";
import TourCardWrapper from "../Tour/TourCardWrapper";
import clsx from "clsx";
import { useState } from "react";

export const CoursesLanding: React.FC<{ courses: Course[] }> = ({
  courses,
}) => {
  const [isWiggling, setIsWiggling] = useState(false);

  return (
    <>
      <CoursesHeader />
      <TourCardWrapper
        step={5}
        tourType="learner"
        classNames={clsx(
          "relative grid grid-cols-1 content-stretch justify-stretch gap-4 md:grid-cols-2",
          isWiggling && "onboardingWiggle"
        )}
        allowClick
        forceTop
        onNext={() => {
          // Reset the wiggling animation each time someone clicks
          setIsWiggling(false);
          setTimeout(() => {
            setIsWiggling(true);
          }, 1);
        }}
      >
        {courses.map((course) => (
          <CourseCard key={course.id} course={course} />
        ))}
      </TourCardWrapper>
    </>
  );
};
