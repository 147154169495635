import { Dispatch } from "react";
import { SetStateAction } from "react";
import { FeatureImage } from "./FeatureImage";

export const Feature = ({
  title,
  description,
  image,
  plus,
  integral,
  videoSlug,
  videoTime,
  helpCenterURL,
  imageFirst,
  setVideoFile,
  setShowVideo,
}: {
  title: string;
  description: JSX.Element;
  image?: string;
  plus: boolean;
  integral: boolean;
  videoSlug?: string;
  videoTime?: string;
  helpCenterURL: string;
  imageFirst?: boolean;
  setVideoFile: Dispatch<SetStateAction<string>>;
  setShowVideo: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="flex flex-col items-start justify-start gap-6 pb-8 text-dm-charcoal-800">
      <div className="flex items-center justify-start gap-4">
        <h3 className="font-serif text-lg font-bold">{title}</h3>
        {plus && (
          <h4 className="rounded-sm bg-dm-brand-blue-200 px-2 font-sans text-sm leading-7">
            PLUS
          </h4>
        )}
        {integral && (
          <h4 className="rounded-sm bg-dm-earth-yellow-500 px-2 font-sans text-sm leading-7">
            {!plus ? "INTEGRAL ONLY" : "INTEGRAL"}
          </h4>
        )}
      </div>

      <div className="flex flex-col items-start justify-start gap-6 md:flex-row">
        {image ? (
          <FeatureImage image={image} title={title} imageFirst={imageFirst} />
        ) : null}
        <div className="flex flex-col items-start justify-start gap-4">
          <p className="font-sans text-base font-normal">{description}</p>
          <div className="flex flex-col justify-start gap-x-6 gap-y-2 sm:flex-row sm:items-center">
            {videoSlug && (
              <button
                className="group inline-flex items-center justify-start gap-2 font-sans text-base font-bold text-dm-brand-blue-500"
                onClick={() => {
                  setVideoFile(videoSlug);
                  setShowVideo(true);
                }}
              >
                <i className="fal fa-play-circle"></i>
                <span className="text-left leading-snug group-hover:underline">
                  See How It Works
                </span>
                {videoTime && (
                  <span className="text-dm-brand-blue-200">({videoTime})</span>
                )}
              </button>
            )}
            {videoSlug && helpCenterURL ? (
              <div className="h-8 border-r border-dm-charcoal-200 leading-8 max-sm:hidden"></div>
            ) : null}
            {helpCenterURL && (
              <a
                className="group inline-flex items-center justify-start gap-2 font-sans text-base font-bold text-dm-brand-blue-500"
                href={helpCenterURL}
                target="_blank"
                rel="noreferrer"
              >
                <i className="far fa-external-link"></i>
                <span className="text-left leading-snug group-hover:underline">
                  Learn More in our Help Center
                </span>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
