import { useEffect, useState } from "react";
import { useStep } from "usehooks-ts";
import { LockedTriangleBackground } from "../LockedTriangleBackground";
import { PascalsTriangleRow } from "../../../types";
import { NUM_ONBOARDING_STEPS, TRIANGLE_AUTOFILL_TIMING } from "./constants";
import { OnboardingPascalsTriangle } from "./OnboardingPascalsTriangle";
import { OnboardingPascalsStep } from "./OnboardingPascalsStep";
import { generatePascalsTriangle } from "../../../utils/generatePascalsTriangle";
import { usePascalsTriangleMutation } from "../../../utils/usePascalsTriangleMutation";
import { useNextPrize } from "../../../utils/useNextPrize";

function getNextRow(filledInRow: PascalsTriangleRow): PascalsTriangleRow {
  const unfilledIndex = filledInRow.findIndex((filled) => !filled);
  const nextRow =
    unfilledIndex === -1
      ? Array(filledInRow.length + 1).fill(false)
      : [...filledInRow];
  if (unfilledIndex === -1) {
    nextRow[0] = true;
  } else {
    nextRow[unfilledIndex] = true;
  }
  return nextRow;
}

export const PascalsOnboarding: React.FC<{
  onCellFilled: (points: number) => void;
  onDone: () => void;
}> = ({ onCellFilled, onDone }) => {
  // Add one for an invisible step at the end
  const [step, { goToNextStep }] = useStep(NUM_ONBOARDING_STEPS + 1);
  const [isPaused, setIsPaused] = useState(false);
  const [filledInRow, setFilledInRow] = useState<PascalsTriangleRow>([]);
  const { mutate } = usePascalsTriangleMutation();
  const nextPrize = useNextPrize();

  // Fill in the triangle one cell at a time, pausing at certain points
  useEffect(() => {
    if (step === 1) {
      return;
    }
    const nextRow = getNextRow(filledInRow);
    const [pauseRow, pauseCell] =
      step === 2 ? [4, 1] : step === 3 ? [5, 2] : [6, 0];
    if (nextRow.length === pauseRow && nextRow[pauseCell] === true) {
      setIsPaused(true);
    } else {
      setIsPaused(false);
      setTimeout(() => {
        setFilledInRow(nextRow);
        const lastRow = generatePascalsTriangle(nextRow).pop();
        const lastValues = lastRow
          ? lastRow.map(([value]) => value).filter((v) => v !== null)
          : [];
        onCellFilled(lastValues.pop() ?? 0);
      }, TRIANGLE_AUTOFILL_TIMING);
    }
  }, [filledInRow, onCellFilled, step]);

  // At the end of the last step, tell the parent that onboarding is done
  useEffect(() => {
    if (step === 4 && isPaused) {
      if (!nextPrize) {
        // this should never happen
        return;
      }
      mutate({
        newRow: Array(6).fill(false),
        nextPrize: nextPrize.key,
      }).then(() => {
        onDone();
      });
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaused, onDone, step]);

  return (
    <div className="flex w-full flex-col gap-6 md:w-auto md:flex-row">
      <div className="self-center">
        {step === 1 ? (
          <div className="relative flex h-[188px] w-[205px] items-center justify-center">
            <LockedTriangleBackground />
            <div className="absolute bottom-0 left-0 z-10 h-[80%] w-full bg-gradient-to-t from-white to-transparent"></div>
          </div>
        ) : (
          <OnboardingPascalsTriangle
            step={step}
            filledInRow={filledInRow}
            isPaused={isPaused}
          />
        )}
      </div>
      <div className="flex w-full translate-x-4 justify-end md:w-auto md:translate-x-0">
        <OnboardingPascalsStep
          step={step}
          goToNextStep={() => {
            setIsPaused(false);
            goToNextStep();
          }}
          isPaused={isPaused}
        />
      </div>
    </div>
  );
};
