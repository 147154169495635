import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useDMQuery, executeQuery } from "../../../utils";
import LicenseQuoteCard from "./LicenseQuoteCard";
import { getFilePath } from "../../../utils";
import {
  IconBrandFacebookFilled,
  IconBrandTwitterFilled,
} from "@tabler/icons-react";
import UpgradeLicenseQuoteForm from "./LicenseQuoteForm";
import {
  IRenewalData,
  TLicenseOption,
  rangeToFullType,
  getGradeScope,
} from "../../utils/quoteUtils";
import { useMutation } from "react-query";

const UpgradeLicenseQuote = () => {
  const [showUpgradeForm, setShowUpgradeForm] = useState<boolean>(false);
  const [upgradeClicked, setUpgradeClicked] = useState<
    TLicenseOption | undefined
  >(undefined);
  const [currentLicense, setCurrentLicense] = useState<
    TLicenseOption | undefined
  >(undefined);
  const [recommendedUpgrade, setRecommendedUpgrade] = useState<
    TLicenseOption | undefined
  >(undefined);
  const [schoolToDistSameScope, setSchoolToDistSameScope] = useState<
    Array<TLicenseOption>
  >([]);
  const [entireDistrict, setEntireDistrict] = useState<Array<TLicenseOption>>(
    []
  );

  const [quoteStatus, setQuoteStatus] = useState<
    "" | "loading" | "pdf" | "json" | "error" | "renewingFromTrial"
  >("");

  const [entity, setEntity] = useState<
    "school" | "district" | "individual" | ""
  >("");

  const { renewalId } = useParams();

  const {
    data: renewalData,
    isError,
    isSuccess: showCards,
  } = useDMQuery<IRenewalData>({
    path: `/public/renewal/data/${encodeURIComponent(renewalId || "")}`,
    queryOptions: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
    },
  });

  const getPDF = async (requestBody: Record<string, unknown>) => {
    if (!requestBody.renewalToken) {
      requestBody.renewalToken = encodeURIComponent(renewalId || "");
    }
    if (!requestBody.currentLicenseType && renewalData?.type) {
      requestBody.currentLicenseType = rangeToFullType[renewalData.type];
    }
    if (typeof requestBody.currentHasIntegral === "undefined") {
      requestBody.currentHasIntegral = renewalData?.hasIntegral ? true : false;
    }
    const res = await executeQuery({
      path: "/quote_request",
      method: "post",
      requestBody,
      additionalHeaders: {
        Accept: "application/pdf",
      },
      responseType: "blob",
      returnHeaders: true,
    });

    const { responseData, responseHeaders } = res;

    if (responseHeaders["content-type"] === "application/pdf" && responseData) {
      return { responseData, responseHeaders };
    } else {
      const jsonResponse = await jsonifyBlob(responseData);
      return { responseData: jsonResponse, responseHeaders };
    }
  };

  const setDownload = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const jsonifyBlob = async (blob: Blob) => {
    try {
      return await new Response(blob).json();
    } catch (e) {
      return { success: false, message: `Error: ${e}` };
    }
  };

  const requestQuote = useMutation(getPDF, {
    onSuccess: (response) => {
      const { responseData, responseHeaders } = response;

      if (
        responseHeaders["content-type"] === "application/pdf" &&
        responseData
      ) {
        setShowUpgradeForm(false);
        let fileName: any =
          responseHeaders["content-disposition"].match(/filename="([^"]+)"/);
        if (Array.isArray(fileName) && fileName.length > 1) {
          fileName = fileName[1];
        } else {
          fileName = `DeltaMath Quote ${new Date().toLocaleDateString()}.pdf`;
        }
        setQuoteStatus("");
        setDownload(responseData as Blob, fileName);
      } else if (responseData) {
        if ((responseData as any).success) {
          setQuoteStatus("json");
        } else {
          setQuoteStatus("error");
        }
      }
    },
    onError: (error) => {
      console.error("Error downloading PDF:", error);
      setQuoteStatus("error");
    },
  });

  const closeDialog = () => {
    setShowUpgradeForm(false);
    if (quoteStatus !== "loading") {
      setQuoteStatus("");
    }
  };

  //[MB] The API has gone through a couple iterations, below I am going to format the data to more easily work with the component props as defined. This isn't entirely out of laziness - I think this will be a little more declatatiive and will give us one place to make changes if the API needs to change again (hopefully)

  useEffect(() => {
    if (renewalData && renewalData.licenseOptions.length > 0) {
      switch (renewalData.type) {
        case "school":
        case "school_custom":
          setEntity("school");
          break;
        case "individual":
          setEntity("individual");
          break;
        case "6-12":
        case "7-12":
        case "9-12":
        case "6-8":
        case "7-8":
          setEntity("district");
          break;
        default:
          setEntity("");
          break;
      }
      //[MB] A note re: 'currentLicense' state variable: generally that name is accurate. In the case of individual licenses, we aren't contemplating allowing for a "renewal" of individual codes from this landing page. The individual logic is a bit added on and should be more thoroughly incorporated in a re-factor but for rendering purposes it is smoother to count the first returned option as "current" even if all of the options presented are upgrades. The copy will changed appropriately in the components.
      if (!Array.isArray(renewalData.licenseOptions[0])) {
        const licenseOptions = renewalData.licenseOptions as TLicenseOption[];
        setCurrentLicense(licenseOptions[0]);
        if (licenseOptions.length > 2) {
          setSchoolToDistSameScope([licenseOptions[1]]);
          setEntireDistrict([licenseOptions[2]]);
        } else if (licenseOptions.length > 1) {
          setEntireDistrict([licenseOptions[1]]);
        }
      } else {
        const licenseOptions = renewalData.licenseOptions as Array<
          [TLicenseOption, TLicenseOption]
        >;
        setCurrentLicense(licenseOptions[0][0]);
        setUpgradeClicked(licenseOptions[0][0]);
        setRecommendedUpgrade(licenseOptions[0][1]);
        if (licenseOptions.length > 2) {
          setSchoolToDistSameScope(licenseOptions[1]);
          setEntireDistrict(licenseOptions[2]);
        } else if (licenseOptions.length > 1) {
          setEntireDistrict(licenseOptions[1]);
        }
      }
    }
  }, [renewalData]);

  return (
    <Fragment>
      {showCards && (
        <UpgradeLicenseQuoteForm
          showUpgradeForm={showUpgradeForm}
          currentLicense={currentLicense}
          selectedUpgrade={upgradeClicked}
          currentIntegration={renewalData?.integrations}
          downloadPDF={requestQuote.mutate}
          quoteStatus={quoteStatus}
          setQuoteStatus={setQuoteStatus}
          closeDialog={closeDialog}
        />
      )}
      <div className="w-full">
        <div className="m-4">
          <a href="https://www.deltamath.com/">
            <img
              className="block h-8 w-auto"
              src={getFilePath("/images/DeltaMath-Logo_Blue.png")}
              alt="DeltaMath"
            />
          </a>
        </div>
      </div>
      <div className="flex min-h-screen flex-col justify-between bg-dm-background-blue-100">
        {isError && (
          <div className="space-y-12">
            <h1 className="m-4 text-center font-serif text-3xl text-dm-gray-800">
              <strong>Please Reach Out!</strong>
            </h1>
            <h3 className="mx-8 text-center text-xl text-dm-gray-500">
              We are unable to automatically show you all of your renewal
              options here, possibly because your district has a custom license.
              Please feel free to reach out to DeltaMath support or to use one
              of the{" "}
              <a
                className="text-decoration-line text-dm-brand-blue-500"
                href="https://www.deltamath.com/teachers-schools"
              >
                the quote request forms from our homepage.
              </a>
            </h3>
          </div>
        )}
        {showCards && (
          <div className="m-1 sm:m-8">
            <div className="m-1 grid grid-cols-1 gap-6 sm:m-4 md:grid-cols-2">
              <div className="col-span-1 justify-center p-4 md:col-span-2">
                <h1 className="m-4 text-center font-serif text-lg text-dm-gray-800 sm:text-3xl">
                  <strong>
                    Find Your Perfect License - {renewalData?.licenseName}
                  </strong>
                </h1>
                <h3 className="text-md text-center text-dm-gray-500 sm:text-lg">
                  Request a Quote to
                  {recommendedUpgrade
                    ? entity !== "individual"
                      ? " Upgrade or Renew"
                      : " Upgrade"
                    : " Renew"}{" "}
                  Your Current Plan.
                </h3>
              </div>
              {currentLicense && (
                <LicenseQuoteCard
                  license={currentLicense}
                  entity={entity}
                  upgradeStatus="current"
                  setShowUpgradeForm={setShowUpgradeForm}
                  span={recommendedUpgrade ? 1 : 2}
                  setSelectedUpgrade={setUpgradeClicked}
                  downloadPDF={requestQuote.mutate}
                  setQuoteStatus={setQuoteStatus}
                  quoteStatus={quoteStatus}
                  integralTrial={renewalData?.integral_trial || false}
                />
              )}
              {recommendedUpgrade && (
                <LicenseQuoteCard
                  license={recommendedUpgrade}
                  upgradeStatus="upgrade"
                  recommended={true}
                  entity={entity}
                  setShowUpgradeForm={setShowUpgradeForm}
                  span={1}
                  setSelectedUpgrade={setUpgradeClicked}
                  downloadPDF={requestQuote.mutate}
                  setQuoteStatus={setQuoteStatus}
                  quoteStatus={quoteStatus}
                  integralTrial={renewalData?.integral_trial || false}
                />
              )}
            </div>
            {schoolToDistSameScope.length > 0 && (
              <div className="m-4 grid grid-cols-1 gap-6 md:grid-cols-2">
                <div className="col-span-1 justify-center p-4 md:col-span-2">
                  <h1 className="m-4 text-center font-serif text-3xl text-dm-gray-800">
                    <strong>
                      For Your Entire District
                      {renewalData?.licenseType
                        ?.toLowerCase()
                        .indexOf("school") !== -1 &&
                        ` (${getGradeScope(schoolToDistSameScope[0].type)})`}
                    </strong>
                  </h1>
                  <h3 className="text-md text-center text-dm-gray-500 sm:text-lg">
                    Explore More License Options for Your Entire District.
                  </h3>
                </div>
                {schoolToDistSameScope.map(
                  (license: TLicenseOption, i: number) => {
                    return (
                      <LicenseQuoteCard
                        key={`Same-level-${i}`}
                        entity={entity}
                        license={license}
                        upgradeStatus="upgrade"
                        recommended={false}
                        setShowUpgradeForm={setShowUpgradeForm}
                        span={currentLicense?.tier === "Plus" ? 1 : 2}
                        setSelectedUpgrade={setUpgradeClicked}
                        downloadPDF={requestQuote.mutate}
                        setQuoteStatus={setQuoteStatus}
                        quoteStatus={quoteStatus}
                        integralTrial={renewalData?.integral_trial || false}
                      />
                    );
                  }
                )}
              </div>
            )}
            {entireDistrict.length > 0 && (
              <div className="m-4 grid grid-cols-1 gap-6 md:grid-cols-2">
                <div className="col-span-1 justify-center p-4 md:col-span-2">
                  <h1 className="m-4 text-center font-serif text-3xl text-dm-gray-800">
                    <strong>
                      For Your Entire District
                      {renewalData?.licenseType
                        ?.toLowerCase()
                        .indexOf("school") !== -1 &&
                        ` (${getGradeScope(entireDistrict[0].type)})`}
                    </strong>
                  </h1>
                  <h3 className="text-md text-center text-dm-gray-500 sm:text-lg">
                    Explore More License Options for Your Entire District.
                  </h3>
                </div>
                {entireDistrict.map((license: TLicenseOption, i: number) => {
                  return (
                    <LicenseQuoteCard
                      key={`entire-dist-${i}`}
                      entity={entity}
                      license={license}
                      upgradeStatus="upgrade"
                      recommended={false}
                      setShowUpgradeForm={setShowUpgradeForm}
                      span={currentLicense?.tier === "Plus" ? 1 : 2}
                      setSelectedUpgrade={setUpgradeClicked}
                      downloadPDF={requestQuote.mutate}
                      setQuoteStatus={setQuoteStatus}
                      quoteStatus={quoteStatus}
                      integralTrial={renewalData?.integral_trial || false}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div className="footer__container flex-center-space-evenly-center-column">
          <div className="footer__logo flex-center-center-center-row">
            <a href="https://www.deltamath.com/">
              <img
                className="footer__logo-img"
                src={getFilePath("/images/DeltaMath-Logo_Blue.png")}
                alt="DeltaMath Footer logo"
              />
            </a>
          </div>
          <div className="footer__navbar flex-center-center-center-row">
            <ul className="footer__nav-links flex-center-space-evenly-center-row">
              <li className="footer__nav-link" id="teachersFLink">
                <a href="https://www.deltamath.com/teachers-schools">
                  Teachers&#x200a;/&#x200a;Schools
                </a>
              </li>
              <li className="footer__nav-link" id="studentsFLink">
                <a href="https://www.deltamath.com/students">
                  Parents/Students
                </a>
              </li>
              <li className="footer__nav-link" id="aboutFLink">
                <a href="https://www.deltamath.com/about">About</a>
              </li>
              <li className="footer__nav-link" id="faqsFLink">
                <a href="https://www.deltamath.com/faqs">FAQs/Videos</a>
              </li>
              <li className="footer__nav-link" id="contactFLink">
                <a href="https://www.deltamath.com/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="footer__bottom flex-center-space-between-center-row">
            <div className="footer__bottom-copyright md:text-md p-4 text-xs">
              <span>
                &#169; {new Date().getFullYear()} DeltaMath Solutions Inc.
              </span>
              <a
                href="https://www.deltamath.com/terms-policies"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Policies
              </a>
              <a href="https://status.deltamath.com">Site Status</a>
            </div>
            <div className="footer__bottom-social flex-center-space-between-center-row">
              <a
                href="https://www.facebook.com/DeltaMathWebsite/"
                id="facebook-social"
              >
                <IconBrandFacebookFilled aria-label="Facebook" />
              </a>
              <a href="https://twitter.com/MrDeltaMath" id="twitter-social">
                <IconBrandTwitterFilled aria-label="Twitter" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpgradeLicenseQuote;
