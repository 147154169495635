import { AnalyticsPlugin } from "analytics";
import { AnalyticsEvent } from "../types";
import Cookies from "js-cookie";

declare global {
  interface Window {
    sib: { equeue: any[]; client_key: string; [key: string]: any };
    sendinblue: any;
  }
}

/** Remove any cookies that start with "sib_" or contain "brevo" **/
function clearBrevoCookie() {
  const allCookies = Cookies.get();
  Object.keys(allCookies).forEach((cookieName) => {
    if (
      cookieName.startsWith("sib_") ||
      cookieName.toLowerCase().includes("brevo")
    ) {
      Cookies.remove(cookieName);
    }
  });
}

/**
 * Brevo sets a UUID cookie when you identify a user and then uses that UUID to
 * send events to the user. Once there's a UUID, it'll continue sending events
 * to that contact ~forever. And Brevo doesn't reset the UUID when identifying
 * a different user. So if you log in as one user, logout, then login as
 * another user, the new user's details get saved on the previous user's
 * contact. This puts the user's email in localstorage (obfuscated) and
 * compares that to the user it's currently trying to identify. If there's a
 * mismatch, it clears the Brevo cookie.
 */
function verifyEmailConsistency(email: string): {
  expectedCheckValue: string;
  actualCheckValue: string;
  didReset: boolean;
} {
  const localStorageKey = "dm_brevo_check";
  const expectedCheckValue = window.btoa(email);
  const actualCheckValue = localStorage.getItem(localStorageKey) || "";
  let didReset = false;
  if (actualCheckValue !== expectedCheckValue) {
    clearBrevoCookie();
    localStorage.setItem(localStorageKey, expectedCheckValue);
    didReset = true;
  }
  return { expectedCheckValue, actualCheckValue, didReset };
}

/**
 * This is an Analytics plugin that implements an interface for sending events to Brevo
 * @see https://getanalytics.io/
 * @see https://getanalytics.io/plugins/writing-plugins/
 */
export const BREVO_PLUGIN: AnalyticsPlugin = {
  name: "brevo",

  initialize: () => {
    window.sib = {
      equeue: [],
      client_key: process.env.REACT_APP_BREVO_CLIENT_KEY || "",
    };
    window.sendinblue = {};
    for (
      let j = ["track", "identify", "trackLink", "page"], i = 0;
      i < j.length;
      i++
    ) {
      (function (k) {
        window.sendinblue[k] = function (...args: any[]) {
          const arg = Array.prototype.slice.call(args);
          (
            window.sib[k] ||
            function () {
              const t: Record<string, any> = {};
              t[k] = arg;
              window.sib.equeue.push(t);
            }
          )(arg[0], arg[1], arg[2], arg[3]);
        };
      })(j[i]);
    }
    const n = document.createElement("script");
    const i = document.getElementsByTagName("script")[0];
    n.type = "text/javascript";
    n.id = "sendinblue-js";
    n.async = !0;
    n.src = `https://sibautomation.com/sa.js?key=${window.sib.client_key}`;
    i.parentNode?.insertBefore(n, i);
    window.sendinblue.page();
  },

  page: () => {
    window.sendinblue.page();
  },

  track: ({ payload }: { payload: AnalyticsEvent<any> }) => {
    window.sendinblue.track(payload.event, null, {
      data: payload.properties,
    });
  },

  identify: ({
    payload,
  }: {
    payload: { userId: string; traits: Record<string, unknown> };
  }) => {
    if (!payload.traits.email) {
      throw new Error("Email required for Brevo");
    }
    if (
      typeof payload.traits.email !== "string" ||
      payload.traits.email.trim().length === 0
    ) {
      throw new Error("Email is wrong type or blank");
    }

    const emailConsistencyResult = verifyEmailConsistency(payload.traits.email);

    window.sendinblue.identify(payload.traits.email, {
      id: payload.userId,
      ...payload.traits,
    });

    window.sendinblue.track("identify", null, {
      data: emailConsistencyResult,
    });
  },

  loaded: () => {
    return !!window.sendinblue;
  },

  reset: () => {
    clearBrevoCookie();
  },
};
