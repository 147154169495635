import { Fragment, useEffect } from "react";
import { useCancellationContext } from "./CancellationContext";
import { CancelStepHeader } from "./CancelStepHeader";
import {
  CancelReasonKey,
  CANCEL_REASON_CHOICES,
  CancelFeedbackFormData,
} from "./types";
import { oxfordCommaJoin } from "../../../utils/oxfordCommaJoin";
import { useLearnerContext } from "../../../contexts/LearnerContext";

const ConditionalTextarea: React.FC<{ fieldKey: CancelReasonKey }> = ({
  fieldKey,
}) => {
  const { reasonFormData, setReasonFormData } = useCancellationContext();
  const fieldId = `${fieldKey}-explanation`;

  const config: { label: string; explKey: keyof CancelFeedbackFormData } =
    fieldKey === "missingCourse"
      ? {
          label: "What course were you looking for?",
          explKey: "missingCourseExplanation",
        }
      : fieldKey === "differentService"
      ? {
          label: "What service will you be using instead?",
          explKey: "differentServiceExplanation",
        }
      : { label: "Please explain", explKey: "otherExplanation" };

  if (
    !["missingCourse", "differentService", "other"].includes(fieldKey) ||
    !reasonFormData.reasons.includes(fieldKey)
  ) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1 pl-6">
      <label
        htmlFor={fieldId}
        className="flex flex-col gap-2 text-sm font-bold"
      >
        {config.label}
      </label>
      <textarea
        id={fieldId}
        className="h-14 w-72 max-w-full rounded-md border border-gray-300 px-2 py-1 text-sm focus:ring-transparent"
        value={reasonFormData[config.explKey]}
        onChange={(e) =>
          setReasonFormData({
            ...reasonFormData,
            [config.explKey]: e.target.value,
          })
        }
      />
    </div>
  );
};

export const CancelFeedback: React.FC = () => {
  const { learner: accountOwner } = useLearnerContext();
  const {
    reasonFormData,
    setReasonFormData,
    setConfirmDisabled,
    selectedLearners,
    selectedLearnerNames,
    totalSeats,
  } = useCancellationContext();
  const toggleKey = (key: CancelReasonKey) => {
    if (reasonFormData.reasons.includes(key)) {
      setReasonFormData({
        reasons: reasonFormData.reasons.filter((k) => k !== key),
        otherExplanation:
          key === "other" ? undefined : reasonFormData.otherExplanation,
      });
    } else {
      setReasonFormData({
        ...reasonFormData,
        reasons: [...reasonFormData.reasons, key],
      });
    }
  };

  const selectedLearnerNamesStr = oxfordCommaJoin(selectedLearnerNames);

  useEffect(() => {
    setConfirmDisabled(
      reasonFormData.reasons.length === 0 ||
        (reasonFormData.reasons.includes("missingCourse") &&
          (reasonFormData.missingCourseExplanation ?? "").length === 0) ||
        (reasonFormData.reasons.includes("differentService") &&
          (reasonFormData.differentServiceExplanation ?? "").length === 0) ||
        (reasonFormData.reasons.includes("other") &&
          (reasonFormData.otherExplanation ?? "").length === 0)
    );
  }, [reasonFormData, setConfirmDisabled]);

  return (
    <div className="flex flex-col gap-8">
      <CancelStepHeader
        title={
          !accountOwner?.entitlements.includes("parent") ||
          totalSeats === 1 ||
          selectedLearners.length === 0
            ? "We're Sad to See You Go!"
            : `We're Sad to See ${selectedLearnerNamesStr} Go!`
        }
        subtitle="Can you tell us why you're canceling? Your feedback helps us improve."
      />
      <form
        className="flex flex-col gap-[10px]"
        onSubmit={(e) => e.preventDefault()}
      >
        {Object.entries(CANCEL_REASON_CHOICES).map(([key, label]) => (
          <Fragment key={key}>
            <label className="flex items-center gap-2">
              <span className="flex h-4 w-4">
                <input
                  className="focus:ring-transparent"
                  type="checkbox"
                  checked={reasonFormData.reasons.includes(
                    key as CancelReasonKey
                  )}
                  onChange={() => toggleKey(key as CancelReasonKey)}
                />
              </span>
              {label}
            </label>
            <ConditionalTextarea fieldKey={key as CancelReasonKey} />
          </Fragment>
        ))}
      </form>
    </div>
  );
};
