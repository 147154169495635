import {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { deltamathAPI } from "../../manager/utils";
import { useMutation } from "react-query";
import { debounce, cloneDeep } from "lodash";
import clsx from "clsx";
import {
  skillToSolve,
  skillDataDisplay,
  obfuscate,
  scrollToView,
  getAssignmentDueDateType,
  processlines,
  checkForCustomFiles,
  generateProblemScripts,
  fakeLatexRender,
  resizeKatex,
  updateFullAssignmentData,
  setLSPasscodeToken,
  getRenderMathSettings,
  findNearestUpcoming,
  generateMQ,
} from "../utils";
import { useUserContext } from "../../shared/contexts/UserContext";
import renderMathInElement from "../utils/auto-render";
import devtools from "../utils/devtools";
import {
  REACT_APP_STUDENT_LINK,
  getSelectedChoice,
  useDMQuery,
} from "../../utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import SkillCompletedWarningModal from "./SkillCompletedWarningModal";
import Answer from "./answerForm/Answer";
import { formatAns } from "./answerForm/FormattedAnswer";
import Problem from "./standard-problems/Problem";
import MaxProblemsMessage from "./MaxProblemsMessage";
import AdditionalPractice from "./AdditionalPractice";
import StandardSkillHeader from "./standard-problems/StandardSkillHeader";
import DmWarningModal from "./DmWarningModal";
import "katex/dist/katex.min.css";
import * as Sentry from "@sentry/react";
// import { useWindowContext } from "../../shared/contexts/WindowContext";
import { getCustomPromptA11y } from "../../shared/accessibility/a11yFunctions";
import { useScriptContext } from "../../shared/contexts/DMScriptsContext";
import { PAGE_TYPE_ID_MAP } from "../../shared/constants";
import { EventMap } from "../../shared/types";
import Button from "./generic/button";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DragonDrop = require("drag-on-drop");

let customPromptA11y: undefined | JSX.Element = undefined;

type Props = {
  currentProblem: any;
  setCurrentProblem: any;
  showSolution: boolean;
  setShowSolution: any;
  showExample: any;
  setShowExample: any;
  setShowVideo: any;
  showNextProblem: any;
  setShowNextProblem: any;
  showVideo: boolean;
  timedAssignment: any;
};

const MQ = (window as any).MQ;

export default function StandardSkills({
  currentProblem,
  setCurrentProblem,
  showSolution,
  setShowSolution,
  setShowExample,
  showExample,
  setShowVideo,
  showNextProblem,
  setShowNextProblem,
  showVideo,
  timedAssignment,
}: Props): JSX.Element {
  const {
    activeSection,
    setLoadingData,
    dmAssignmentData,
    dmSectionsData,
    assignmentsRefresh,
    setDmAssignmentData,
    setIsMfeLoaded,
    studentNextProblems,
    setStudentNextProblems,
    customExternalFiles,
    setGlobalInputsMap,
    globalInputsMap,
    handleGlobalFocus,
    currentProblemData,
    setCurrentProblemData,
    showKeyboard,
    setShowKeyboard,
    showCalculator,
    setShowCalculator,
  } = useContext(StudentSectionsContext);

  const scriptContext = useScriptContext();
  const userContext = useUserContext();

  const { skillCode, teacherId } = useParams();
  const solveSkill: any = skillToSolve();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [answer, setAnswer] = useState<Array<string> | object>([""]);
  const [formattedAnswer, setFormattedAnswer] = useState<string | undefined>(
    undefined
  );
  const [customAnswerData, setCustomAnswerData] = useState<
    Record<string, unknown>
  >({});
  const [customMqFocus, setCustomMqFocus] = useState<string | null>(null);
  const [prevMqFocus, setPrevMqFocus] = useState<string | null>(null);

  const [justCompleted, setJustCompleted] = useState<boolean>(false);
  const [justCompletedWarning, setJustCompletedWarning] =
    useState<boolean>(false);
  const [showJustCompletedWarning, setShowJustCompletedWarning] =
    useState<boolean>(false);
  const [showStuckWarning, setShowStuckWarning] = useState<boolean>(false);
  const [studentIsStuck, setStudentIsStuck] = useState(false);

  const mathBlockContent = useRef<null | HTMLDivElement>(null);

  // stores the focus event handlers added to MathQuill nodes
  const focusEvents = useRef<EventMap>(new Map());
  const initialProblemRender = useRef(true);

  const [katexResizingData, setKatexResizingData] = useState<any>({});

  const maxProblemsOneDone =
    solveSkill?.sa?.currentSkill?.maxProblemsOneDone || false;

  const isLocked = userContext.getIsLocked();
  const isLti = !!localStorage.getItem("lti_assignment_payload");

  const questionPageID = PAGE_TYPE_ID_MAP["question"];
  const solutionPageID = PAGE_TYPE_ID_MAP["solution"];

  // WIP:
  // const problemDisplay = useRef<any>(<></>);

  // useEffect(() => {
  //   console.log("maxProblemsOneDone:", maxProblemsOneDone);
  // }, [maxProblemsOneDone]);

  // useEffect(() => {
  //   console.log("studentNextProblems:", studentNextProblems);
  // }, [studentNextProblems]);

  // useEffect(() => {
  //   console.log("currentProblem:", currentProblem);
  // }, [currentProblem]);

  // useEffect(() => {
  //   console.log("currentProblemData:", currentProblemData);
  // }, [currentProblemData]);

  // useEffect(() => {
  //   console.log("customMqFocus:", customMqFocus);
  // }, [customMqFocus]);

  // useEffect(() => {
  //   console.log("solveSkill:", solveSkill);
  // }, [solveSkill]);

  // useEffect(() => {
  //   console.log("studentIsStuck:", studentIsStuck);
  // }, [studentIsStuck]);

  // useEffect(() => {
  //   console.log("answer:", answer);
  // }, [answer]);

  const problemRef = useRef<Record<string, any>>({
    questionCode: false,
    questionScript: false,
    solutionCode: false,
    solutionScript: false,
  });

  // useEffect(() => {
  //   console.log("problemRef?.current", problemRef?.current);
  // }, [problemRef?.current]);

  const resetCurrentProblem = () => {
    setStudentIsStuck(false);
    setShowSolution(false);
    setShowExample(false);
    setShowVideo(false);
    setAnswer([""]);
    setFormattedAnswer(undefined);
    setIsMfeLoaded(false);
    initialProblemRender.current = true;
    setCustomMqFocus(null);
  };

  const navigate = useNavigate();
  const backButtonUrl = () =>
    `${REACT_APP_STUDENT_LINK}/${activeSection}/${getAssignmentDueDateType(
      solveSkill?.sa?.status
    )}`;

  /* SET and DEFINE GLOBALS */
  window.renderMathInElement = renderMathInElement;
  const makeDisplayStyle = (window as any).makeDisplayStyle;
  if (!window.DragonDrop && DragonDrop) {
    window.DragonDrop = DragonDrop;
  }

  const skillData = skillDataDisplay(solveSkill.ta.skillName, solveSkill);

  const inlineQuestionCode = () => {
    return `
      window.deltaGraphs2 = [];
      ${currentProblemData?.inlineQuestionCode};`;
  };

  const inlineSolutionCode = () => {
    return `
      window.deltaGraphs = [];
      ${currentProblemData?.inlineSolutionCode};`;
  };

  //TODO: temporary while in development
  const isForcedSkill =
    urlParams.get("skillcode") || urlParams.get("problemid");
  // isForcedSkill uses prod version of problemByAssignment,
  // which has obfuscation always on
  const [isToggleObfuscation, setIsToggleObfuscation] = useState<boolean>(
    isForcedSkill ? false : true
  );

  fakeLatexRender();

  /* ******************* */
  /* problemByAssignment */
  /* ******************* */

  const { data: problemByAssignmentData, refetch: problemRefetch } = useDMQuery(
    {
      path: `/student/${isForcedSkill ? "dev/" : ""}problemByAssignment/${
        solveSkill?.ta?._id
      }`,
      cacheKey: [
        "problemByAssignment",
        solveSkill?.ta?._id,
        solveSkill?.ta?.skillName,
        solveSkill.ta.last_edit,
      ],
      params: {
        sk: solveSkill.ta.skillName,
        last_edit: solveSkill.ta.last_edit,
        toggleObfuscation: isToggleObfuscation,
        force: devtools().getForceNew(),
        force_sk: urlParams.get("skillcode"),
        force_pid: urlParams.get("problemid"),
        ...setLSPasscodeToken(solveSkill?.ta?._id),
      },
      queryOptions: {
        staleTime: 0, //10 * (60 * 1000), // 10 mins
        cacheTime: 0, //15 * (60 * 1000), // 15 mins
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        enabled: false,
        onError: (error: any) => {
          if ([409, 422].indexOf(error.code) !== -1 && !isLocked && !isLti) {
            assignmentsRefresh();
            navigate(
              `${REACT_APP_STUDENT_LINK}/${
                findNearestUpcoming(dmAssignmentData) ?? dmSectionsData[0]?._id
              }/upcoming`
            );
            return;
          }
          setTimeout(() => {
            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: true,
              error: true,
              title: "Error",
              message: `${
                error?.message ||
                error?.error ||
                error?.response?.data?.message ||
                ""
              }`,
            }));
          }, 100);

          // if passcode_token is incorrect, remove from localStorage
          if (error?.code === 423) {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const pcKeyName = "pc_" + solveSkill?.ta?._id + "_" + user?._id;
            localStorage.removeItem(pcKeyName);
            navigate(backButtonUrl());
          }
        },
      },
    }
  );

  useLayoutEffect(() => {
    if (problemByAssignmentData === undefined) return;

    if (problemByAssignmentData.assignment) {
      updateFullAssignmentData(
        problemByAssignmentData.assignment,
        problemByAssignmentData.assignment.ta._id,
        activeSection,
        dmAssignmentData,
        setDmAssignmentData
      );
    }

    // TEMPORARY if(), data will always be obfuscated on prod
    if (
      isToggleObfuscation == true &&
      problemByAssignmentData?.problem?.data &&
      typeof problemByAssignmentData.problem.data === "string"
    ) {
      problemByAssignmentData.problem.data = obfuscate(
        problemByAssignmentData.problem._id
      ).reveal(problemByAssignmentData.problem.data);
    }

    if (solveSkill?.sa?._id && solveSkill?.ta?.skillName) {
      setStudentNextProblems((prevState: any) => ({
        ...prevState,
        [solveSkill.sa._id]: {
          ...prevState[solveSkill.sa._id],
          [solveSkill.ta.skillName]:
            problemByAssignmentData?.problem || undefined,
        },
      }));

      updateProblem(cloneDeep(problemByAssignmentData?.problem) || undefined);
    }
  }, [problemByAssignmentData]);

  const updateProblem = async (problem: any) => {
    if (problem === undefined) {
      setCurrentProblem({
        correct: undefined,
        solution: undefined,
        complete: false,
        nextProblem: undefined,
        maxProblemsOneDone: undefined,
      });
      setCurrentProblemData(undefined);
      return;
    }

    mathBlockContent?.current?.classList.add("invisible");
    setKatexResizingData({});

    const hasExternalFiles =
      problem.ansType === "custom" ||
      problem.data?.externalUrlExists ||
      problem.data?.sharedExternalFile;

    /* TODO: reinstate cache!  
      NOTE: Originally cached custom files by skillcode, 
      but each individual problem can potentially require a different custom file.
      Attempted to cache by file url, 
      but it led to a race condition when calling the inlineSolutionScripts.
      Needs another attempt!
    */
    // fetch the files and set the ref, if the correct files are not already there
    // if (
    //   hasExternalFiles &&
    //   !customExternalFiles.current.has(problem.skillcode)
    // ) {
    //   const files = await checkForCustomFiles(problem);
    //   customExternalFiles.current.set(problem.skillcode, files);
    // }

    const problemData = hasExternalFiles
      ? generateProblemScripts(
          await checkForCustomFiles(problem), // customExternalFiles.current.get(problem.skillcode),
          problem,
          problem?.log_data?.data
        )
      : problem;

    // obscureMode will be used in the module code to indicate to the module coder not to generate a graph with any extra data (besides the student answer)
    if (hasExternalFiles && skillData.isTest && showQuestionPage) {
      problemData.data.data.obscureMode = true;
    }
    // set teacher data for special custom modules
    if (hasExternalFiles) {
      problemData.data.data.assignmentTeacherID = solveSkill.ta._id;
      problemData.data.data.assignmentTeachercode = solveSkill.ta.teachercode;
      problemData.data.data.psuedoId = Math.round(Math.random() * 100000000); // logged later in the module code for the answerData issue!
    }

    setCurrentProblemData(problemData);
    const skillsToCheckAnswerData = [
      "graphParabolaOnly",
      "_kphillips__162drawingAngles",
      "rotateRadianEvaluateFunc",
      "slopeGraphically",
    ];
    if (skillsToCheckAnswerData.includes(problemData?.skillcode)) {
      console.log("current problem data has been updated");
    }
    //TODO: update w/ setStudentNextProblems() here too? Seems to be set correctly, but why
    resetProblemViewed();
    setCurrentProblem({
      ...currentProblem,
      correct: problemData?.log_data?.correct,
      solution: problemData?.lines,
      nextProblem: undefined,
      complete: skillData?.isCompleted, //TODO: Check if this is being updated immediately when skill completes, no refresh
      maxProblemsOneDone: problemData?.log_data?.maxProblemsOneDone,
    });

    // WIP:
    // problemDisplay.current = displayProblem(
    //   processlines(currentProblemData?.qlines),
    //   currentProblemData,
    //   katexResizingData["question"],
    //   "question"
    // );

    const timer = setTimeout(() => {
      mathBlockContent?.current?.classList.remove("invisible");
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
      }));
      clearTimeout(timer);
    }, 100);
  };

  // const windowContext = useWindowContext();

  /* ************ */
  /* check_answer */
  /* ************ */

  const checkAnswer = useMutation(
    (body: string) => {
      return axios.post(
        deltamathAPI() + `/student/${isForcedSkill ? "dev/" : ""}check_answer`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            "x-pingback": obfuscate(currentProblemData?._id).hide(
              userContext.answerSubmittedString || "https://deltamath.com"
            ),
          },
        }
      );
    },
    {
      onSuccess: async (data: any, variables: any, context: any) => {
        if (userContext.answerSubmittedString) {
          userContext.answerSubmitted();
        }

        const responseData = data.data;
        // wrong answer, but attempts are left
        if (responseData?.attempts) {
          setCurrentProblemData((prevState: any) => ({
            ...prevState,
            attempts: responseData?.attempts,
          }));

          if (solveSkill?.sa?._id && solveSkill?.ta?.skillName) {
            setStudentNextProblems((prevState: any) => ({
              ...prevState,
              [solveSkill.sa._id]: {
                ...prevState[solveSkill.sa._id],
                [solveSkill.ta.skillName]: {
                  ...prevState[solveSkill.sa._id]?.[solveSkill.ta.skillName],
                  attempts: responseData?.attempts,
                },
              },
            }));
          }

          if (responseData?.assignment) {
            updateFullAssignmentData(
              responseData.assignment,
              responseData.assignment.ta._id,
              activeSection,
              dmAssignmentData,
              setDmAssignmentData
            );
          }

          const remainingAttempts =
            responseData?.attempts?.max - responseData?.attempts?.used;

          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: true,
            error: true,
            title: "Notice",
            message: `Your answer is not correct. Try to find your mistake. You have ${remainingAttempts} attempt${
              remainingAttempts > 1 ? "s" : ""
            } remaining.`,
          }));
        }
        // all attempts exhausted, answer is final
        else {
          // reveal data.problem.data before setting the current problem
          if (
            responseData?.problem?.data &&
            typeof responseData.problem.data === "string"
          ) {
            responseData.problem.data = obfuscate(
              responseData?.problem._id
            ).reveal(responseData?.problem?.data);
          }

          const nextProblemData = responseData?.problem;

          setCurrentProblem({
            ...currentProblem,
            correct: responseData?.solutionData?.log_data?.correct,
            solution: responseData?.solutionData?.lines,
            nextProblem: nextProblemData,
            complete: true,
            maxProblemsOneDone:
              responseData?.solutionData?.log_data?.maxProblemsOneDone,
          });

          setCurrentProblemData((prevState: any) => ({
            ...prevState,
            ...(responseData?.solutionData?.lines
              ? { lines: cloneDeep(responseData?.solutionData?.lines) }
              : {}),
            ...(responseData?.solutionData?.log_data
              ? {
                  log_data: {
                    ...cloneDeep(responseData?.solutionData?.log_data),
                  },
                }
              : {}),
            ...(isForcedSkill && responseData?.solutionData?.answerLines
              ? {
                  answerLines: cloneDeep(
                    responseData?.solutionData?.answerLines
                  ),
                }
              : {}),
          }));

          if (nextProblemData !== undefined) {
            setStudentNextProblems((prevState: any) => ({
              ...prevState,
              [solveSkill.sa._id]: {
                ...prevState[solveSkill.sa._id],
                [solveSkill.ta.skillName]: nextProblemData,
              },
            }));
            // data.skillComplete?
          } else if (
            responseData?.solutionData?.log_data?.maxProblemsOneDone !== true &&
            nextProblemData === undefined
          ) {
            const tempClone = { ...studentNextProblems };
            delete tempClone[solveSkill.sa._id]?.[solveSkill.ta.skillName];
            setStudentNextProblems(tempClone);
          }

          if (
            responseData?.solutionData?.log_data?.maxProblemsOneDone === true
          ) {
            setStudentNextProblems((prevState: any) => ({
              ...prevState,
              [solveSkill.sa._id]: {
                ...prevState[solveSkill.sa._id],
                [solveSkill.ta.skillName]: {
                  ...prevState[solveSkill.sa._id][solveSkill.ta.skillName],
                  ...(responseData?.solutionData?.lines
                    ? { lines: cloneDeep(responseData?.solutionData?.lines) }
                    : {}),
                  ...(responseData?.solutionData?.log_data
                    ? {
                        log_data: {
                          ...cloneDeep(responseData?.solutionData?.log_data),
                        },
                      }
                    : {}),
                },
              },
            }));
          }
          if (responseData?.assignment) {
            updateFullAssignmentData(
              responseData.assignment,
              responseData.assignment.ta._id,
              activeSection,
              dmAssignmentData,
              setDmAssignmentData
            );
          } else {
            updateAssignmentData(
              responseData?.submissionResponse,
              responseData?.ta
            );
          }

          setShowSolution(true);

          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: false,
          }));
          if (showKeyboard && !showCalculator) {
            setShowKeyboard(false);
          }

          setTimeout(() => {
            scrollToView(null, "instant");
          }, 100);
        }
      },
      onError: (error: any, variables: any, context: any) => {
        console.group("check_answer onError");
        console.log(
          "error",
          error || error?.response?.data?.message || error?.error
        );
        console.log("variables", variables);
        console.log("context", context);
        console.groupEnd();

        if (userContext.answerSubmittedString) {
          userContext.answerSubmitted();
        }

        if ([409, 422].indexOf(error.code) !== -1 && !isLocked && !isLti) {
          assignmentsRefresh();
          navigate(
            `${REACT_APP_STUDENT_LINK}/${
              findNearestUpcoming(dmAssignmentData) ?? dmSectionsData[0]?._id
            }/upcoming`
          );
          return;
        }
        setTimeout(() => {
          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: true,
            error: true,
            title: "Error",
            message: `${
              error?.response?.data?.message ||
              error?.message ||
              error?.error ||
              ""
            }`,
          }));
        }, 100);
      },
    }
  );

  const handleSubmit = () => {
    setLoadingData((prevState: any) => ({
      ...prevState,
      isShowing: true,
    }));

    /* Generate the solution (or "sol") for the problem logs */
    const sol = formatAns({
      ansType: currentProblemData?.ansType,
      answer,
      leftLatex: currentProblemData?.data?.leftLatex,
      rightLatex: currentProblemData?.data?.rightLatex,
      noSolutionText: currentProblemData?.data?.multipleSolutionsPossible
        ? currentProblemData?.data?.multipleSolutionsBtns
          ? currentProblemData?.data?.multipleSolutionsBtns[0]
          : "No solution"
        : "Answer left blank",
      setNotation: currentProblemData?.data?.setNotation,
      customMsg: formattedAnswer,
      studentIsStuck,
      noAnswerSubmitted: false,
      studentSolution: getSelectedChoice(answer, currentProblemData.choices),
    }).formattedAnsStrArray.join("");

    const body = JSON.stringify({
      encryptedProblemId: currentProblemData?._id,
      taId: solveSkill.ta._id,
      sk: solveSkill.ta.skillName,
      last_edit: solveSkill.ta.last_edit,
      submitted: {
        stuck: studentIsStuck,
        solution: sol,
        actual_sk: solveSkill.ta.skillName, // TODO: add in mixed problem sk
        answers: answer,
        ...(currentProblemData?.ansType === "custom"
          ? {
              answerData: currentProblemData?.data?.data?.answerData,
              ...(currentProblemData?.data?.data?.answerData !== undefined
                ? {
                    encryptedAnswerData: obfuscate(
                      currentProblemData?._id
                    ).hide(currentProblemData?.data?.data?.answerData),
                  }
                : {}),
              ...(customAnswerData.correct !== undefined
                ? {
                    metaData: obfuscate(currentProblemData?._id).hide(
                      customAnswerData
                    ),
                  }
                : {}),
            }
          : {}),
      },
    });

    if (body === undefined || body === "{}" || body === "") {
      Sentry.captureException("Undefined body for check answer", {
        extra: {
          encryptedProblemId: currentProblemData?._id,
          taId: solveSkill.ta._id,
          sk: solveSkill.ta.skillName,
          last_edit: solveSkill.ta.last_edit,
          submitted: {
            stuck: studentIsStuck,
            solution: sol,
            actual_sk: solveSkill.ta.skillName, // TODO: add in mixed problem sk
            answers: answer,
            ...(currentProblemData?.ansType === "custom"
              ? {
                  answerData: currentProblemData?.data?.data?.answerData,
                  ...(customAnswerData.correct !== undefined
                    ? {
                        metaData: obfuscate(currentProblemData?._id).hide(
                          customAnswerData
                        ),
                      }
                    : {}),
                }
              : {}),
          },
        },
      });
    }

    checkAnswer.mutate(body);

    /*Very specific sentry capture to get insight on issue where student log is not capturing answer data for the module graphParabola only. This is a bug we have not been able to replicate. The issue only occurs rarely but is ongoing */
    const skillsToCheckAnswerData = [
      "graphParabolaOnly",
      "_kphillips__162drawingAngles",
      "rotateRadianEvaluateFunc",
      "slopeGraphically",
    ];
    if (skillsToCheckAnswerData.includes(solveSkill.ta.skillName)) {
      const reactAnswerData = currentProblemData?.data?.data?.answerData;
      const backupAnswerData = window.backupAnswerData;

      if (typeof reactAnswerData === "undefined" && !studentIsStuck) {
        const message = "answer data is missing or could not be stringified";
        const extra = {
          encryptedProblemId: currentProblemData?._id,
          taId: solveSkill.ta._id,
          last_edit: solveSkill.ta.last_edit,
          submitted: {
            stuck: studentIsStuck,
            solution: sol,
            actual_sk: solveSkill.ta.skillName,
            answers: answer,
            answerData: reactAnswerData,
            backupAnswerData,
          },
        };
        Sentry.captureException(message, { extra });
      }
    }
  };

  /* update sa assignmentData from check_answer for this assignment */
  const updateAssignmentData = (dataObj: any, taObj?: any) => {
    if (!dataObj) return;
    // TODO: Why does invalidating stop score from updating?
    // const queryClient = useQueryClient();
    // queryClient.invalidateQueries("/student/data/assignments");
    dmAssignmentData[activeSection].filter((assignment: any, index: number) => {
      if (String(assignment.ta._id) === teacherId) {
        const assignmentObj = { ...dmAssignmentData };

        // update solvedProblems, if necessary, in the ta object
        if (dataObj.solvedProblems !== undefined) {
          assignmentObj[activeSection][index].ta.skills[
            solveSkill?.ta?.skillName
          ].solvedProblems = dataObj.solvedProblems;
          delete dataObj.solvedProblems; // delete prop from dataObj (not included in sa obj update)
        }

        // 1. spread the dataObj data into the current assignment's object
        // 2. dataObj.data only returns current skill, so spread that data
        //    after applying the current sa.data
        assignmentObj[activeSection][index].sa = {
          ...assignmentObj[activeSection][index].sa,
          ...dataObj,
          data: {
            ...assignmentObj[activeSection][index].sa.data,
            ...dataObj.data,
          },
        };

        if (taObj !== undefined) {
          assignmentObj[activeSection][index].ta = {
            ...assignmentObj[activeSection][index].ta,
            ...taObj,
          };
        }

        setDmAssignmentData({ ...assignmentObj });
        return;
      }
    });
  };

  useEffect(() => {
    if (!showNextProblem) return;
    setShowNextProblem(false);

    if (justCompletedWarning && solveSkill?.sa?.actuallyComplete < 100) {
      setJustCompletedWarning(false);
      setShowJustCompletedWarning(true);
    } else {
      setNextProblem();
    }
  }, [showNextProblem]);

  const setNextProblem = () => {
    setShowJustCompletedWarning(false);
    resetProblemViewed();
    resetCurrentProblem();
    if (
      !skillData.isCompleted &&
      studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
    ) {
      updateProblem(
        cloneDeep(
          studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
        )
      );
    } else if (skillData.isStandardSkill) {
      // WIP: problemDisplay.current = <></>;
      updateProblem(undefined);
      problemRefetch();
    }
  };

  useEffect(() => {
    if (!skillData.isCompleted) return;
    if (!justCompleted) {
      setJustCompleted(true);
      setJustCompletedWarning(true);
    }
  }, [skillData.isCompleted]);

  useEffect(() => {
    setLoadingData((prevState: any) => ({
      ...prevState,
      error: false,
      isShowing: true,
    }));
    setShowExample(false);
    resetProblemViewed();
    resetCurrentProblem();
    if (
      skillData.isStandardSkill &&
      !studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
    ) {
      updateProblem(undefined);
      problemRefetch();
    } else if (
      studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
    ) {
      updateProblem(
        cloneDeep(
          studentNextProblems[solveSkill.sa._id][solveSkill.ta.skillName]
        )
      );
    }
    setShowSolution(maxProblemsOneDone);
    setJustCompleted(false);
    setJustCompletedWarning(false);
  }, [skillCode]);

  /* Create event listeners for common btns, based on the currently focused MathQuill field */
  useEffect(() => {
    const commonBtns = document.querySelectorAll("button.common-button");
    const commonBtnClick = (btnLatex: string, customMqFocus: string | null) => {
      const node =
        customMqFocus !== null
          ? MQ(document.getElementById(customMqFocus))
          : undefined;
      if (node) {
        // replace all parentheses and square brackets with correct latex
        btnLatex = btnLatex
          .replaceAll("(", "\\left(")
          .replaceAll(")", "\\right)")
          .replaceAll("[", "\\left[")
          .replaceAll("]", "\\right]");
        // account for special buttons
        if (btnLatex.includes(",")) {
          // such as [,] for interval notation
          node.write(btnLatex).keystroke("Left Left");
        } else if (btnLatex.includes("cuberoot")) {
          node
            .cmd("nthroot")
            .typedText("3")
            .keystroke("Tab")
            .keystroke("Left Right");
        } else if (btnLatex.includes("nthroot")) {
          node.cmd("nthroot").keystroke("Left Right");
        } else if (btnLatex.includes("logbase")) {
          node.write("log_{}").keystroke("Tab").keystroke("Left");
        } else if (btnLatex.includes("sqrt")) {
          if (btnLatex === "\\sqrt{}") btnLatex = "sqrt";
          node.cmd(btnLatex);
        } else {
          // all other latex
          node.write(btnLatex);
        }
        node.focus();
      }
    };
    if (customMqFocus) {
      const elm = MQ(document.getElementById(customMqFocus));

      if (globalInputsMap) {
        setGlobalInputsMap(globalInputsMap.set(String(customMqFocus), elm));
        handleGlobalFocus(customMqFocus);
      } else {
        const tempMap = new Map();
        tempMap.set(String(customMqFocus), elm);
        setGlobalInputsMap(tempMap);
        handleGlobalFocus(customMqFocus);
      }
    }
    const eventListenerMap = new Map();
    if (commonBtns.length) {
      commonBtns.forEach((btn: Element) => {
        const quill = btn.attributes.getNamedItem("quill")?.value;
        const btnLatex = quill ? quill : (btn as HTMLElement).innerText;
        const btnEventListener = () => commonBtnClick(btnLatex, customMqFocus);
        eventListenerMap.set(btn, btnEventListener);
        btn.addEventListener("click", btnEventListener);
      });
    }
    return () => {
      eventListenerMap.forEach(
        (evListenerFunc: () => void, element: Element) => {
          element.removeEventListener("click", evListenerFunc);
        }
      );
    };
  }, [customMqFocus, currentProblemData]);

  /**
   * Creates all MathQuill fields for custom problems
   */
  const createMQ = useCallback(() => {
    /* Remove all current event listeners from MathQuill boxes, if necessary */
    if (focusEvents.current.size) {
      focusEvents.current.forEach((listener, object) => {
        const node = object.element;
        if (document.contains(node || null))
          node.removeEventListener(object.type, listener);
      });
      focusEvents.current = new Map();
    }

    const config = currentProblemData?.data?.binomialExponent
      ? { charsThatBreakOutOfSupSub: "" }
      : {};

    /* function that will execute anytime a MathQuill field is focused */
    const focusFunc = (el: Element, mq: any, isTouchDevice: boolean) => {
      setCustomMqFocus(el.id);
      setGlobalInputsMap(globalInputsMap.set(el.id, mq));
      handleGlobalFocus(el.id);
      if (isTouchDevice) {
        setShowCalculator(false);
        setShowKeyboard(true);
      }
    };

    focusEvents.current = generateMQ(focusFunc, config);

    setIsMfeLoaded(true); // change state for loading mq

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProblemData]);

  const resetProblemViewed = () => {
    problemRef.current = {
      questionCode: false,
      questionScript: false,
      solutionCode: false,
      solutionScript: false,
    };
  };

  // Set a flag when a problem and it's solution has been viewed
  const checkProblemViewed = (options: any = {}) => {
    const questionCode = options.questionCode
      ? options.questionCode
      : undefined;
    const questionScript = options.questionScript
      ? options.questionScript
      : undefined;
    const solutionCode = options.solutionCode
      ? options.solutionCode
      : undefined;
    const solutionScript = options.solutionScript
      ? options.solutionScript
      : undefined;

    problemRef.current = {
      ...problemRef.current,
      ...(questionCode !== undefined
        ? { questionCode: questionCode }
        : {
            questionCode: problemRef.current.questionCode || false,
          }),
      ...(questionScript !== undefined
        ? { questionScript: questionScript }
        : {
            questionScript: problemRef.current.questionScript || false,
          }),
      ...(solutionCode !== undefined
        ? { solutionCode: solutionCode }
        : {
            solutionCode: problemRef.current.solutionCode || false,
          }),
      ...(solutionScript !== undefined
        ? { solutionScript: solutionScript }
        : {
            solutionScript: problemRef.current.solutionScript || false,
          }),
    };
  };

  const showTestSolution =
    skillData.isTest &&
    skillData.isCompleted &&
    !skillData.obscureResults &&
    skillData.solutionsAvailable &&
    skillData.showSolutionsSetting !== "never" &&
    ((skillData.showSolutionsSetting === "correct_only" &&
      currentProblem?.correct) ||
      (skillData.showSolutionsSetting === "incorrect_only" &&
        !currentProblem?.correct) ||
      skillData.showSolutionsSetting === "always");

  const showSolutionPage =
    (!skillData.isTest &&
      showSolution &&
      skillData.showSolutionsSetting !== "never" &&
      ((skillData.showSolutionsSetting === "correct_only" &&
        currentProblem?.correct) ||
        (skillData.showSolutionsSetting === "incorrect_only" &&
          !currentProblem?.correct) ||
        skillData.showSolutionsSetting === "always")) ||
    (skillData.isTest && showTestSolution && showSolution);
  const showQuestionPage =
    (!skillData.isTest &&
      (!showSolution ||
        skillData.showSolutionsSetting === "never" ||
        (skillData.showSolutionsSetting === "correct_only" &&
          !currentProblem.correct) ||
        (skillData.showSolutionsSetting === "incorrect_only" &&
          currentProblem.correct))) ||
    (skillData.isTest && !showTestSolution);

  //[MB - 1.4.2024] -  when showSolutionPage and currentProblemData.lines are false(y) b/c the showSolutionsSetting is either "never" or "correct/incorrect only" and the student gets the answer wrong/right, solutionScripts do not run. (The two are related, if showSolutionPage is false then solution lines are not sent back w/ problem data). We still need solutionScripts to run for modules where there is a student graph/answer data. The boolean below tracks such cases and has been added into the conditional determining whether solutionScripts is run on the correct page element.

  const runScriptsAnyway =
    showSolution &&
    !skillData.isTest &&
    !!(
      ((skillData.showSolutionsSetting === "correct_only" &&
        !currentProblem?.correct) ||
        (skillData.showSolutionsSetting === "incorrect_only" &&
          currentProblem?.correct) ||
        skillData.showSolutionsSetting === "never") &&
      currentProblemData?.data?.data?.answerData
    );

  // useEffect(() => {
  //   console.log("showSolutionPage:", showSolutionPage);
  // }, [showSolutionPage]);

  // useEffect(() => {
  //   console.log("showQuestionPage:", showQuestionPage);
  // }, [showQuestionPage]);

  // useEffect(() => {
  //   console.log("showSolution:", showSolution);
  // }, [showSolution]);

  //[MB 1.2.2024] function to track whether an issue w/ deltaGraph not being defined is caused by a race condition w/ the <script> tag loading dm graph

  const checkAndLogDmGraph = (where: any) => {
    const url = "/app/manager/global/dmGraph.js";
    const dmGraphScript = document.querySelector(`script[src="${url}"]`);
    if (dmGraphScript === null) {
      console.log("dmGraph is not loaded", where);
    } else {
      console.log("dmGraph loaded", where);
    }
  };

  const displayQuestion = () => {
    // hide .answerData on custom assignments when the data doesn't exist
    if (
      currentProblemData?.log_data?.data ||
      (currentProblemData?.data?.data?.answerData &&
        currentProblemData?.data?.data?.answerData?.show !== false)
    ) {
      document
        .querySelector(`#${solutionPageID} .answerData`)
        ?.classList.remove("hidden");
    } else {
      document
        .querySelector(`#${solutionPageID} .answerData`)
        ?.classList.add("hidden");
    }

    // These are possibly used by inlineSolutionCode or inlineQuestionCode when eval()
    // Guarantees data & page are in scope.
    // !!DO NOT DELETE!!

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = currentProblemData?.data?.data || {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const page = showSolutionPage
      ? document.getElementById(questionPageID)
      : document.getElementById(solutionPageID);

    if (currentProblemData?.data?.inlineFirst === undefined) {
      if (!problemRef?.current?.questionCode) {
        checkAndLogDmGraph(
          "inlineFirst und, not questionCode, inlineQuestionCode"
        );
        eval(inlineQuestionCode());
        checkProblemViewed({ questionCode: true });
        createMQ(); // after the question code is evaluated, create MathQuill boxes
      }

      if (
        currentProblemData?.problemScripts?.questionScripts &&
        !problemRef?.current?.questionScript &&
        problemRef?.current?.questionCode
      ) {
        currentProblemData.problemScripts.questionScripts(
          document.getElementById(questionPageID)
        );
        checkProblemViewed({ questionScript: true });
      }

      if (
        (showSolutionPage || runScriptsAnyway) &&
        !problemRef?.current?.solutionCode
      ) {
        checkAndLogDmGraph("showSolutionPage, solutionCode");
        eval(inlineSolutionCode());
        checkProblemViewed({ solutionCode: true });
      }

      if (
        currentProblemData?.problemScripts?.solutionScripts &&
        (showSolutionPage || runScriptsAnyway) && // see [MB 1.4.2024] for note on runScriptsAnyway
        !problemRef?.current?.solutionScript &&
        (currentProblemData?.lines || runScriptsAnyway) // see [MB 1.4.2024] for note on runScriptsAnyway
      ) {
        currentProblemData.problemScripts.solutionScripts(
          document.getElementById(solutionPageID)
        );
        checkProblemViewed({ solutionScript: true });
      }
    } else if (currentProblemData?.data?.inlineFirst === false) {
      if (
        currentProblemData?.problemScripts?.questionScripts &&
        !problemRef?.current?.questionScript
      ) {
        currentProblemData.problemScripts.questionScripts(
          document.getElementById(questionPageID)
        );
        checkProblemViewed({ questionScript: true });
      }

      if (
        !problemRef?.current?.questionCode &&
        problemRef?.current?.questionScript
      ) {
        checkAndLogDmGraph(
          "not question code, yes questionScript, inlineQuestionCode"
        );
        eval(inlineQuestionCode());
        checkProblemViewed({ questionCode: true });
        createMQ(); // after the question code is evaluated, create MathQuill boxes
      }

      if (
        currentProblemData?.problemScripts?.solutionScripts &&
        (showSolutionPage || runScriptsAnyway) && // see [MB 1.4.2024] for note on runScriptsAnyway
        !problemRef?.current?.solutionScript &&
        (currentProblemData?.lines || runScriptsAnyway) // see [MB 1.4.2024] for note on runScriptsAnyway
      ) {
        currentProblemData.problemScripts.solutionScripts(
          document.getElementById(solutionPageID)
        );
        checkProblemViewed({ solutionScript: true });
      }

      if (
        (showSolutionPage || runScriptsAnyway) &&
        !problemRef?.current?.solutionCode &&
        problemRef?.current?.solutionScript
      ) {
        checkAndLogDmGraph(
          "showSolution, not solutionCode, yes solution Script"
        );
        eval(inlineSolutionCode());
        checkProblemViewed({ solutionCode: true });
      }
    }

    // TODO: HM - Keep an eye on this conditional!
    if (
      skillData.isTest &&
      skillData.isCompleted &&
      showQuestionPage &&
      currentProblemData?.log_data?.data &&
      currentProblemData?.answerLines?.length &&
      !problemRef?.current?.solutionScript &&
      currentProblemData?.problemScripts?.solutionScripts
    ) {
      checkAndLogDmGraph("completed test solun page");
      eval(inlineSolutionCode());
      currentProblemData.problemScripts.solutionScripts(
        document.querySelector(".answerData")
      );
    }

    // increase input width as more data is typed in
    if (
      document.querySelector(
        'script[src*="/assets/jquery.auto-grow-input.min.js"]'
      ) &&
      (window as any).$?.fn?.autoGrowInput
    ) {
      eval(`$('.display-problem input:not(.no-auto-grow)').autoGrowInput();`);
    }

    //[MB - 1.26.2024] - allowing for module coder over-ride of accesibility text for the prompt
    if (
      typeof currentProblemData?.promptA11ySettings?.customString !==
      "undefined"
    ) {
      customPromptA11y = getCustomPromptA11y(
        currentProblemData?.prompt,
        currentProblemData?.promptA11ySettings
      );
    } else {
      customPromptA11y = undefined;
    }
  };

  useLayoutEffect(() => {
    if (
      (currentProblemData?.problemScripts !== undefined &&
        currentProblemData?.ansType === "custom") ||
      (currentProblemData?.ansType !== "custom" &&
        currentProblemData?.ansType !== undefined)
    ) {
      //check for whether any scripts in the context are not loaded before displaying the question
      if (Object.values(scriptContext.state).includes("false")) {
        scriptContext.loadFunc();
      }
      displayQuestion();
    }
    if (!skillData.isVideo)
      renderMathInElement(
        document.getElementById("mathBlock"),
        getRenderMathSettings(),
        currentProblemData?.promptA11ySettings || false
      );

    const currentPageType = showSolutionPage ? "solution" : "question";
    setKatexResizingData((prevState: any) => ({
      ...prevState,
      [currentPageType]: resizeKatex(currentPageType, prevState),
    }));
  }, [currentProblemData]);

  /* On browser resize, call resizeKatex() to readjust the KaTeX width */
  const handleResize = () => {
    const currentPageType = showSolutionPage ? "solution" : "question";
    setKatexResizingData((prevState: any) => ({
      ...prevState,
      [currentPageType]: resizeKatex(currentPageType, prevState),
    }));
  };

  const debounce_resize = debounce(handleResize, 150);

  useEffect(() => {
    window.addEventListener("resize", debounce_resize);
    return () => {
      window.removeEventListener("resize", debounce_resize);
    };
  });

  /* Focus control on initial problem render for custom problems, if not on mobile */
  useEffect(() => {
    /* Determine if user is on a touch device */
    const isTouchDevice: boolean = (window as any).is_touch_device();
    if (
      !isTouchDevice &&
      currentProblemData &&
      customMqFocus &&
      initialProblemRender.current === true
    ) {
      initialProblemRender.current = false;
      setTimeout(() => {
        const mq = MQ(document.getElementById(customMqFocus));
        mq?.focus();
      }, 100);
    }
  }, [customMqFocus, currentProblemData]);

  /* Logic to show max problems messaging */
  const maxProblems = solveSkill.ta?.currentSkill?.maxProblems;
  const solvedProblems = solveSkill.ta?.currentSkill?.solvedProblems;
  const requiredProblems = solveSkill.ta?.currentSkill?.required;
  const userId = JSON.parse(localStorage.getItem("user") || "{}")._id;
  // undefined: no message to show, true: message shows at top, false: message shows at bottom
  let showMaxMessageTop: undefined | boolean;
  if (
    !skillData.isTest &&
    maxProblems !== undefined &&
    solvedProblems !== undefined
  ) {
    if ([15244406, 18542611, 21959527, 21983720].includes(userId))
      showMaxMessageTop = undefined;
    // hard code a specific user (who is making videos) to not have the max problem message
    else if (
      showSolution &&
      (maxProblems === 1 || maxProblems - solvedProblems === 0)
    ) {
      showMaxMessageTop = true;
    } else if (!showSolution) {
      showMaxMessageTop =
        maxProblems - solvedProblems <= requiredProblems ? true : false;
    }
  }

  /* Logic for still stuck / stuck button */
  const hasMoreProblems =
    maxProblems !== undefined &&
    solvedProblems !== undefined &&
    maxProblems - solvedProblems <= 1
      ? false
      : true;
  const showStillStuck =
    currentProblemData?.attempts?.used > 0 || currentProblemData?.stillStuck;
  const showStuckButton =
    skillData?.type !== "special" &&
    hasMoreProblems &&
    !showSolution &&
    (showStillStuck || currentProblemData?.data?.guided);

  const handleStuck = () => {
    setShowStuckWarning(true);
    setStudentIsStuck(true);
    setAnswer(["\\text{Problem skipped}"]);
    setFormattedAnswer("\\text{Problem skipped}");
    if (currentProblemData?.ansType === "custom") {
      setCustomAnswerData({
        correct: 0,
      });
    }
  };

  /* Logic to show additional practice */
  const hasProblemsLeft = maxProblems - solvedProblems !== 0;
  const showAdditionalPractice =
    !skillData.isTest &&
    skillData.isCompleted &&
    showSolution &&
    hasProblemsLeft;

  /* Logic to handle showing videos and examples */
  const updateStillStuck = () => {
    setCurrentProblemData((prevState: any) => ({
      ...prevState,
      stillStuck: true,
    }));
    setStudentNextProblems((prevState: any) => ({
      ...prevState,
      [solveSkill.sa._id]: {
        ...prevState[solveSkill.sa._id],
        [solveSkill.ta.skillName]: {
          ...prevState[solveSkill.sa._id]?.[solveSkill.ta.skillName],
          stillStuck: true,
        },
      },
    }));
  };

  const handleShowVideo = () => {
    setShowVideo(!showVideo);
    setShowSolution(false);
    // if a student watches a video, the Still Stuck? button shows for that problem
    updateStillStuck();
  };

  const handleShowExample = () => {
    setShowExample(!showExample);
    setShowSolution(false);
    // if a student views an example, the Still Stuck? button shows for that problem
    updateStillStuck();
  };

  /* Logic for prompt display */
  let prompt = null;
  if (currentProblemData?.prompt) {
    prompt = currentProblemData?.prompt;
    if (typeof currentProblemData.prompt === "string") {
      // use katex display style for prompt
      if (!currentProblemData.prompt.includes("displaystyle"))
        prompt = makeDisplayStyle(prompt);
      // replace new lines for strings with html breaks for teacher created questions
      if (currentProblemData?.skillcode === "userCreatedProblem")
        prompt = prompt.replaceAll("\n", "<br>");
    }
  }

  /* Logic to hide the your solution area, if there is no data to display */
  const logData = currentProblemData?.log_data;
  const showYourAnswer = showSolution || timedAssignment?.isOver;
  const showStudentGraph =
    currentProblemData?.answerLines?.length > 0 && logData?.data !== undefined;
  const hideAnswerArea =
    !solveSkill?.ta?.is_test &&
    showYourAnswer &&
    !(logData?.attempts_data?.length > 0) &&
    !(logData?.messages?.length > 0) &&
    !showStudentGraph &&
    (logData?.sol === undefined || logData?.sol === "");

  return (
    <>
      {showMaxMessageTop === true && (
        <MaxProblemsMessage
          showSolution={showSolution}
          problemsLeft={maxProblems - solvedProblems}
          maxProblems={maxProblems}
          requiredProblems={requiredProblems}
        />
      )}
      <div
        id="mathBlock"
        className={clsx(
          "relative border border-[#E4E6EA] bg-white px-2 py-4 sm:p-5 lg:p-8",
          // !skillData.isVideo && !skillData.isTest && !showMaxMessageTop
          //   ? "mt-4"
          //   : null,
          !showMaxMessageTop && "sm:rounded-t-lg",
          showMaxMessageTop !== false && "sm:rounded-b-lg"
        )}
        aria-live="polite"
      >
        {/* Header */}
        <StandardSkillHeader
          showSolution={showSolution}
          currentProblem={currentProblem}
          currentProblemData={currentProblemData}
          skillData={skillData}
          solveSkill={solveSkill}
          handleShowExample={handleShowExample}
          handleShowVideo={handleShowVideo}
        />
        {/* Body */}
        <div ref={mathBlockContent}>
          <h2
            id="problemPrompt"
            role="group"
            tabIndex={0}
            aria-hidden={`${customPromptA11y !== undefined}`}
            className="text-base font-medium leading-7 focus:outline-none"
            dangerouslySetInnerHTML={{
              __html: prompt,
            }}
          ></h2>
          {customPromptA11y !== undefined && customPromptA11y}
          <div
            key={`questionpage:${currentProblemData?._id}`}
            id={questionPageID}
            className={clsx(
              "display-problem question-page",
              showSolutionPage ? "sr-only" : null
            )}
            aria-hidden={showSolutionPage}
          >
            <Problem
              displayData={processlines(currentProblemData?.qlines)}
              problemData={currentProblemData}
              resizingData={katexResizingData["question"]}
              locString="question"
            />
          </div>
          <div
            key={`problempage:${currentProblemData?._id}`}
            id={solutionPageID}
            className={clsx(
              "display-problem problem-page", // TODO: double check these
              showQuestionPage ? "sr-only" : null
            )}
            aria-hidden={showQuestionPage}
          >
            <Problem
              displayData={processlines(currentProblemData?.lines)}
              problemData={currentProblemData}
              resizingData={katexResizingData["solution"]}
              locString="solution"
            />
          </div>
        </div>
        {/* Answer Area */}
        {!hideAnswerArea && (
          <div className="relative mt-8 rounded bg-dm-background-blue-100 p-4 sm:p-6">
            <Answer
              key={"answer:" + currentProblemData?._id}
              correct={currentProblem?.correct}
              complete={currentProblem?.complete}
              showYourAnswer={showSolution || timedAssignment?.isOver}
              setShowSolution={setShowSolution}
              isCheckAnswerLoading={checkAnswer.isLoading}
              answer={answer}
              setAnswer={setAnswer}
              formattedAnswer={formattedAnswer}
              setFormattedAnswer={setFormattedAnswer}
              setCustomAnswerData={setCustomAnswerData}
              customMqFocus={customMqFocus}
              setCustomMqFocus={setCustomMqFocus}
              handleSubmit={handleSubmit}
              problem={currentProblemData}
              katexResizingData={katexResizingData}
            />
          </div>
        )}
      </div>
      {solveSkill.noPassbackFlag === true && (
        <div className="bg-dm-warning-600">
          <div className="px-8 py-4">
            <div>
              <p className="font-medium">
                <span>
                  You are a teacher viewing a student assignment configured to
                  pass grades back to an LMS. However, the student has not yet
                  launched into this assignment from the LMS, so if you do any
                  work on this page that would change the student&apos;s grade,
                  that grade will not be able to be passed back to the LMS. The
                  student must launch into this assignment at least one time for
                  DeltaMath to have the ability to pass a grade back.
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      {showMaxMessageTop === false && (
        <div>
          <MaxProblemsMessage
            showSolution={showSolution}
            problemsLeft={maxProblems - solvedProblems}
            maxProblems={maxProblems}
            requiredProblems={requiredProblems}
          />
        </div>
      )}
      {showStuckButton && (
        <div className="mt-4 flex justify-end max-sm:px-2">
          <Button type="link" className="text-sm" onClick={handleStuck}>
            {showStillStuck ? "Still Stuck?" : "Stuck?"}
          </Button>
        </div>
      )}
      {showAdditionalPractice && (
        <AdditionalPractice handleClick={() => setShowNextProblem(true)} />
      )}
      {/* {currentProblemData ? (
        <div className="mx-1 mt-14 h-[400px] overflow-scroll border-2 border-dashed border-slate-400 bg-slate-100 p-2 md:mx-24">
          <h3 className="mb-5 font-bold">/problemByAssignment JSON:</h3>
          <pre className="overflow-auto break-words font-mono text-xs">
            {JSON.stringify(currentProblemData, null, 2)}
          </pre>
        </div>
      ) : null} */}
      <SkillCompletedWarningModal
        isShowing={showJustCompletedWarning}
        setIsShowing={setShowJustCompletedWarning}
        setNextProblem={setNextProblem}
      />
      <DmWarningModal
        showModal={showStuckWarning}
        setShowModal={setShowStuckWarning}
        isConfirmDisabled={checkAnswer.isLoading}
        headline="Warning"
        message="If you proceed, you will be shown the solution to this problem and marked incorrect. You will then get a new question. Are you sure you would like to see the solution to this question?"
        confirmMessage="Yes, show the solution"
        modalConfirmAction={() => {
          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: true,
          }));
          //checkAnswerRefresh();
          handleSubmit();
        }}
        modalCancelAction={() => {
          setStudentIsStuck(false);
        }}
      />
    </>
  );
}
