import { Learner } from "../../../types";

export function getAddedLearnersType(
  learners: Learner[]
): "username" | "usernames" | "email" | "emails" | "mixed" {
  if (learners.length === 1) {
    if (learners[0].inviteCode) {
      return "email";
    }
    return "username";
  } else {
    if (learners.every((l) => l.inviteCode !== undefined)) {
      return "emails";
    } else if (learners.some((l) => l.inviteCode !== undefined)) {
      return "mixed";
    }
    return "usernames";
  }
}
