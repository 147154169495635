import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";

type Props = {
  updatePageState: (state: "resetSent") => void;
};

const ForgotPassword = (props: Props) => {
  const toastContext = useDeltaToastContext();
  const [email, setEmail] = useState("");

  const resetPassword = useMutation(
    (body: any) => {
      return axios.post(
        `${deltamathAPI()}/learner/default/reset_password`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess() {
        props.updatePageState("resetSent");
      },
      onError(err: any) {
        toastContext.addToast({
          status: "Error",
          message:
            err?.response?.data?.message ||
            "There was an error sending the reset password email, please contact support.",
        });
      },
    }
  );

  const sendEmail = () => {
    resetPassword.mutate({ email: email.trim() });
  };

  return (
    <>
      <h2 className="mb-1 mt-6 text-center font-[Lora] text-2xl font-bold text-dm-brand-blue-600">
        Forgot Password
      </h2>
      <div className="flex-grow sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 sm:rounded-lg sm:px-10">
          <form>
            <p className="mb-10 text-center text-sm text-dm-brand-blue-800">
              We need to confirm your email or username to send you instructions
              to reset your password.
            </p>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-bold text-dm-charcoal-800"
              >
                Email or Username
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type={"text"}
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <Button
              className="mt-6 w-full"
              onClick={sendEmail}
              disabled={email.trim() === ""}
              submit
            >
              Send Reset Password Email
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
