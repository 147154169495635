import { Fragment } from "react";
import { SubunitProgress } from "../../../types";
import SidebarWrapper from "../SidebarWrapper";
import { Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { useCourseContext } from "../../../contexts/CourseContext";
import ProgressBar from "../../../../shared/ProgressBar";
import SubunitSkillsNav from "./SubunitSkillsNav";
import CollapsedIcons from "./CollapsedIcons";
import Button from "../../../../student/components/generic/button";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { SkeletonCircle, SkeletonText } from "../../Skeleton";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";

type Props = {
  subunitProgress: SubunitProgress | undefined;
  isLoadingProgress?: boolean;
};

export default function Sidebar({
  subunitProgress,
  isLoadingProgress = false,
}: Props): JSX.Element {
  const { coursePath, unitPath, subunitPath } = useParams();
  const courseContext = useCourseContext();
  const { isSidebarMinimized } = useLearnerContext();

  const subunitData = courseContext.getSubunitData(
    subunitPath,
    unitPath,
    coursePath
  );
  const unitData = courseContext.getUnitData(unitPath, coursePath);
  const courseData = courseContext.getCourseData(coursePath);

  const unitOrderPosition =
    courseData &&
    unitData?.id &&
    courseData?.unitOrder?.indexOf(unitData.id) + 1;
  const subunitOrderPosition =
    unitData &&
    subunitData?.id &&
    unitData?.subunitOrder?.indexOf(subunitData.id) + 1;

  const totalProgress = Math.round((subunitProgress?.progress || 0) * 100);

  return (
    <SidebarWrapper
      showCalculator={true}
      upperSection={
        <>
          <Button
            type="link"
            href={`${REACT_APP_LEARNER_LINK}/course/${coursePath}`}
          >
            <h3
              className={clsx(
                "mb-1 flex justify-between pr-5 text-lg font-bold leading-5 text-dm-charcoal-800 transition-opacity duration-300 ease-out sm:justify-normal",
                isSidebarMinimized ? "opacity-0" : "opacity-100"
              )}
            >
              <SkeletonText loaded={!!courseData}>
                {courseData?.name ?? "Ex in Lorem elit amet"}
              </SkeletonText>
            </h3>
          </Button>
          {/* Unit Name */}
          <div className="flex items-center gap-x-2 border-b border-dm-charcoal-100 py-4 pl-2">
            <Button
              type="link"
              href={`${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}`}
              className="flex items-center gap-x-2"
            >
              {unitData ? (
                <span className="fa-stack fa-2x small w-[2em] min-w-[2em] text-[1em]">
                  <i className="fa-solid fal fa-circle fa-stack-2x text-dm-charcoal-100"></i>
                  <i className="fa-solid fas fa-th fa-stack-1x text-dm-brand-blue-500"></i>
                </span>
              ) : (
                <div className="h-[2em] w-[2em]">
                  <SkeletonCircle />
                </div>
              )}
              <h3
                className={clsx(
                  "text-sm text-dm-brand-blue-500 transition-opacity duration-300 ease-out",
                  isSidebarMinimized ? "opacity-0" : "opacity-100"
                )}
              >
                {unitData ? (
                  <>
                    <strong>Unit {unitOrderPosition}:</strong> {unitData?.name}
                  </>
                ) : (
                  <SkeletonText>Adipisicing labore amet</SkeletonText>
                )}
              </h3>
            </Button>
          </div>
        </>
      }
      midSection={
        <div className="pt-6">
          <h3
            className={clsx(
              "text-sm transition-opacity duration-300 ease-out",
              isSidebarMinimized ? "opacity-0" : "opacity-100"
            )}
          >
            {subunitData ? (
              <>
                <strong>Section {subunitOrderPosition}:</strong>{" "}
                {subunitData?.name}
              </>
            ) : (
              <SkeletonText>Adipisicing labore amet</SkeletonText>
            )}
          </h3>
          <ProgressBar
            percentCompleted={totalProgress}
            solvePage={false}
            tooltipEnabled={false}
            width={isSidebarMinimized ? "w-12" : "w-full"}
            height={isSidebarMinimized ? "h-6" : "h-5"}
            isMinimized={isSidebarMinimized}
            gradientBknd={true}
          />
          <h4
            className={clsx(
              "text-xs transition-opacity duration-300 ease-out",
              isSidebarMinimized ? "opacity-0" : "opacity-100"
            )}
          >
            <SkeletonText loaded={!isLoadingProgress}>
              <strong>{totalProgress}%</strong> complete
            </SkeletonText>
          </h4>
        </div>
      }
    >
      <Transition
        as={Fragment}
        show={!isSidebarMinimized}
        enter="transition-opacity ease-linear duration-100 delay-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-[50ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="sidebar-inner-scroll overflow-auto py-6">
          <SubunitSkillsNav title="Pre-Quiz" type="preQuiz" />
          <SubunitSkillsNav title="Practice" type="practice" />
          <SubunitSkillsNav title="Post-Quiz" type="postQuiz" />
        </div>
      </Transition>
      <Transition
        as={Fragment}
        show={isSidebarMinimized}
        enter="transition-opacity ease-linear duration-100 delay-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-[50ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="relative">
          <CollapsedIcons title="Pre-Quiz" type="preQuiz" />
          <br />
          <CollapsedIcons title="Practice" type="practice" />
          <br />
          <CollapsedIcons title="Post-Quiz" type="postQuiz" />
        </div>
      </Transition>
    </SidebarWrapper>
  );
}
