import { cloneDeep } from "lodash";
import { generateProblemScripts, obfuscate } from "../../../student/utils";
import { CheckAnswerResponse, LogData, Problem } from "../../types";
import ProblemDisplay from "./ProblemDisplay";
import { useQueries } from "react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { deltamathAPI } from "../../../manager/utils";
import AlertDialogs from "./AlertDialogs";
import renderMathInElement from "../../../student/utils/auto-render";
import { ProblemLoading } from "./ProblemLoading";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DragonDrop = require("drag-on-drop");

type Props = {
  skillId: string;
  problem: Problem;
  logData?: LogData;
  checkAnswer: (body: string) => void;
  isCheckAnswerLoading: boolean;
  elapsedTime?: number;
  elapsedTestTime?: number;
  checkAnswerResponse?: CheckAnswerResponse;
  unsubmitAllowed: boolean;
  showSolutions: boolean;
  skippedProblem?: boolean;
  hideAnswer?: boolean;
  unsubmit?: () => void;
  inModal?: boolean;
  attemptText?: string;
  header?: React.ReactNode;
  renderKey?: number;
  noBorder?: boolean;
  noAnalytics?: boolean;
};

const CustomFileWrapper = (props: Props) => {
  const problem = cloneDeep(props.problem) as Problem;
  const [newProblemData, setNewProblemData] = useState<Problem | undefined>(
    undefined
  );

  if (typeof props.problem.data === "string") {
    problem.data = obfuscate(`${problem._id}`).reveal(`${problem.data}`);
  }

  if (!window.renderMathInElement && renderMathInElement) {
    window.renderMathInElement = renderMathInElement;
  }

  if (!window.DragonDrop && DragonDrop) {
    window.DragonDrop = DragonDrop;
  }

  // async function checkForCustomFiles(problem: any) {
  let filesToGet: string[] = []; // rarely it could be two
  if (problem.data.sharedExternalFile) {
    filesToGet.push(
      problem.data.sharedExternalFile.indexOf("shared/") === -1
        ? "shared/" + problem.data.sharedExternalFile
        : problem.data.sharedExternalFile
    );
    if (
      problem.data.sharedExternalFileExtend &&
      problem.data.sharedExternalFile
    ) {
      filesToGet.push(problem.custom_file || problem.skillcode || "");
    }
  } else if (problem.data.externalUrlExists || problem.ansType === "custom") {
    filesToGet.push(problem.custom_file || problem.skillcode || "");
  }
  filesToGet = filesToGet.map((file) => {
    return `custom_files/${file.replace(
      "shared/",
      "shared-"
    )}.json?time=${Math.round(new Date().getTime() / 1800000)}`; //query param updates every 30 minutes
  });

  const result = useQueries(
    filesToGet.map((f) => {
      return {
        queryKey: [f],
        staleTime: 1000 * 60 * 30,
        queryFn: async () =>
          axios.get(`${deltamathAPI()}/${f}`).then((res) => {
            return res.data;
          }),
      };
    })
  );

  const hasExternalFiles =
    problem.ansType === "custom" ||
    problem.data?.externalUrlExists ||
    problem.data?.sharedExternalFile;

  useEffect(() => {
    if (
      hasExternalFiles &&
      result.every((r) => r.isSuccess) &&
      !newProblemData
    ) {
      const probData: Problem = generateProblemScripts(
        result.map((r) => r.data), // customExternalFiles.current.get(problem.skillcode),
        problem,
        props.logData?.data // use student's answerData from the log when generating problem scripts, if available
      );
      setNewProblemData(probData);
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, hasExternalFiles]);

  if (hasExternalFiles && !newProblemData) {
    return <ProblemLoading />;
  }

  return (
    <>
      <AlertDialogs />
      <ProblemDisplay
        problem={newProblemData ?? problem}
        setUpdatedProblem={(problem: Problem) => {
          setNewProblemData(problem);
        }}
        renderKey={props.renderKey}
        checkAnswer={props.checkAnswer}
        isCheckAnswerLoading={props.isCheckAnswerLoading}
        elapsedTime={props.elapsedTime}
        elapsedTestTime={props.elapsedTestTime}
        checkAnswerResponse={props.checkAnswerResponse}
        unsubmitAllowed={props.unsubmitAllowed}
        externalFileData={result.map((r) => r.data)}
        showSolutions={props.showSolutions}
        skippedProblem={props.skippedProblem}
        hideAnswer={props.hideAnswer}
        unsubmit={props.unsubmit}
        inModal={props.inModal}
        attemptText={props.attemptText}
        header={props.header}
        noBorder={props.noBorder}
        logData={props.logData}
        noAnalytics={props.noAnalytics}
      />
    </>
  );
};

export default CustomFileWrapper;
