import React from "react";
import { LearnerData } from "../../types";

const DownloadLearners: React.FC<{ allLearners: LearnerData[] }> = ({
  allLearners,
}) => {
  const formatLearnerForCsv = (learnerToFormat: LearnerData) => {
    const keyMap: Partial<Record<keyof LearnerData, string>> = {
      first: "First",
      last: "Last",
      email: "Username",
      status: "Status",
      login_count: "Login Count",
      last_login: "Last Login",
      last_action: "Last Action",
      mostRecentCourse: "Recent Course",
      pointsEarned: "Points Earned",
      pointsUsed: "Points Redeemed",
      sections_completed: "Sections Completed",
      units_completed: "Units Completed",
      subscriptionTrialStartedAt: "Start Date",
      endDate: "End Date",
      parent: "Parent",
      interval: "Billing",
      cost: "Cost",
    };

    //list of fields that do not need to show if the learner is a "parent" and actual learners are pending
    const learnerOnlyFields = [
      "first",
      "last",
      "email",
      "login_count",
      "last_login",
      "last_action",
      "mostRecentCourse",
      "pointsEarned",
      "pointsUsed",
      "sections_completed",
      "units_completed",
      "parent",
    ];

    const dateFields = [
      "last_login",
      "last_action",
      "subscriptionTrialStartedAt",
      "endDate",
    ];

    const formattedLearner: Partial<
      Record<keyof typeof keyMap, string | number | boolean | undefined | null>
    > = {};

    for (const key in keyMap) {
      const oldKey = key as keyof LearnerData;
      const newKey = keyMap[oldKey] as keyof typeof keyMap;

      if (
        learnerToFormat.account_type === "parent" &&
        learnerOnlyFields.includes(oldKey)
      ) {
        formattedLearner[newKey] =
          oldKey === "parent"
            ? learnerToFormat.email
            : oldKey === "first"
            ? "Pending"
            : "";
      } else if (dateFields.includes(oldKey)) {
        formattedLearner[newKey] = learnerToFormat[oldKey]
          ? new Date(learnerToFormat[oldKey] as string).toLocaleDateString()
          : "N/A";
      } else {
        formattedLearner[newKey] =
          oldKey === "parent" && !learnerToFormat.parent
            ? "Self"
            : learnerToFormat[oldKey];
      }
    }
    return formattedLearner;
  };

  const downloadCSV = () => {
    const processedLearners = allLearners.map((learner: LearnerData) =>
      formatLearnerForCsv(learner)
    );
    const headers = Object.keys(processedLearners[0]).join(",");
    const rows = processedLearners.map((learner) =>
      Object.values(learner)
        .map((value) => `"${value}"`)
        .join(",")
    );
    const csv = [headers, ...rows].join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "learners.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="text-lg text-dm-brand-blue-500">
      <button onClick={downloadCSV}>Download Learners</button>
    </div>
  );
};

export default DownloadLearners;
